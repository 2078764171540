const hasAthleteOffering = (personState) => {
  let athleteOfferingTypes = personState
    .offeringInstances
    .filter(x => x.offeringName.toLowerCase().includes('athlete'));

  const athleteOfferingTypeIds = athleteOfferingTypes.map(x => { return x.offeringInstanceId });
  let result = false;
  personState.person.offeringInstanceIds.forEach(id => {
    if (athleteOfferingTypeIds.indexOf(id) >= 0) {
      result = true;
    }
  });

  return result;
};

export default hasAthleteOffering;