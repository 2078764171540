import { BLANK_DATE_STRING } from '../../../common/utils/Constants';
import { isValidDate, isValidBirthDate, isValidName, isValidMemberId } from '../../../common/utils/validation';

const REQUIRED_FILTER_COUNT = 3;

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;


  if (formState.firstName.trim() !== '') {
    filterCount += 1;
  }
  if (formState.firstName.trim() !== '' && !isValidName(formState.firstName.trim())) {
    errors.firstName = 'A valid Legal First Name is required';
  }

  if (formState.lastName.trim() !== '') {
    filterCount += 1;
  }
  if (formState.lastName.trim() !== '' && !isValidName(formState.lastName.trim())) {
    errors.lastName = 'A valid Legal Last Name is required';
  }

  if (formState.birthDate !== BLANK_DATE_STRING) {
    filterCount += 1;
  }

  if (formState.birthDate !== BLANK_DATE_STRING && !isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date must be a valid date';
  } else
    if (!isValidBirthDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date cannot be in the future';
    }

  //Member Id
  if (formState.memberId.trim().length > 0 && isValidMemberId(formState.memberId.trim()) === false) {
    errors.memberId = 'A valid Member Id is required';
  }


  if (filterCount < REQUIRED_FILTER_COUNT && formState.memberId.trim().length === 0) {
    errors.filter = 'Must enter a valid Member Id or a valid Legal First Name, Legal Last Name, and Birth Date to search'
  }

  return errors;
}


const ExistingMemberSearchValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default ExistingMemberSearchValidation;