import React, { Fragment } from 'react';

import useMemberRegistrationHistory from './UseMemberRegistrationHistory';
import MemberRegistrationHistoryGrid from './grids/MemberRegistrationHistoryGrid';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import Constants from '../../../common/utils/Constants';

import style from '../Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const MemberRegistrationHistory = () => {
  const {
    personState,
    memberRegistrationHistoryState,
    onBackButtonClicked,
  } = useMemberRegistrationHistory();
  return (
    <div className='row'>
      <div className="col-xs-12">
        <Headings.H2 className={style.CenterAlign}>Member Registration History </Headings.H2>
      </div>
      <div className="col-xs-12">
        <button className={global.BackToSearchButton} onClick={onBackButtonClicked} type="button">
          <LeftArrowIcon />&nbsp; Back to Membership
        </button>
      </div>
      <div className='col-xs-12'>
        <Headings.H4 className={style.CenterAlign}>{personState?.person?.firstName} {personState?.person?.middleName} {personState?.person?.lastName} {personState?.person?.suffix !== '--' ? personState?.person?.suffix : <Fragment />} </Headings.H4>
      </div>
      <div className='col-xs-12 usas-extra-bottom-margin'>
        <MemberRegistrationHistoryGrid state={memberRegistrationHistoryState} />
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberRegistrationHistoryState?.isArrayLoading || memberRegistrationHistoryState?.isObjLoading} />
    </div>
  )
};

export default MemberRegistrationHistory;