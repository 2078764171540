import useSuffixDropdown from './UseSuffixDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const SuffixDropdown = ({ label, name, value, error, message, onChange }) => {
  const { suffixState } = useSuffixDropdown();

  return suffixState.message
    ? <span className={global.LoadingMsg}>{suffixState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={suffixState.arrayData}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={suffixState.isArrayLoading}
      />
    );
};

export default SuffixDropdown;