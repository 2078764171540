import { useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../NavLinks';
import useOmrData from '../../../state/omr/UseOmrData';

import useDidMountEffect from '../../../../common/utils/UseDidMountEffect';
import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  currentContactInfo: '',
  modalTitle: 'Delete Contact?',
  itemIndex: Constants.DEFAULT_ID
};

const useAddtionalContactO15Other = () => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { personState, savePersonState, isSaved } = useOmrData();

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.MEMBERSHIP_16_AND_UP);
  }

  const onAddAdditionalContact = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT, { replace: true });
  }

  const onNavigateToEmergencyContact = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.PRIMARY_CONTACT_INFO_O15_OTHER);
  }

  const onEdit = (e, i) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT, { state: { i: i } });
  };

  const onDeleteConfirmed = (e, i) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newPersonContacts = [];
    personState.person.personContacts.forEach((x, idx) => {
      if (idx !== i) {
        newPersonContacts.push(x);
      }
    });
    savePersonState({
      ...personState,
      person: {
        ...personState.person,
        personContacts: [...newPersonContacts]
      }
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onDeleteClicked = (e, index) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const contact = personState.person.personContacts[index];
    setModalState({
      ...modalState,
      displayPopUp: true,
      itemIndex: index,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      contactRelationshipName: contact?.contactRelationshipName,
      emailAddress: contact?.emailAddress,
      phone: contact?.phoneNumber
    });
  };

  // kludge to reset isSaved
  useDidMountEffect(() => {
    savePersonState({ ...personState }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      setModalState({ ...INITIAL_MODAL_STATE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    onEdit,
    onDeleteConfirmed,
    onNextButtonClicked,
    onAddAdditionalContact,
    modalState,
    onModalCanceled,
    onDeleteClicked,
    onNavigateToEmergencyContact
  };
};

export default useAddtionalContactO15Other;