import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import getPhoneTypes from './GetPhoneTypeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const PhoneTypeData = { INITIAL_STATE, getPhoneTypes };

export default PhoneTypeData;
