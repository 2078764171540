import React from 'react';

import useAddtionalContactO15OtherAddEditContact from './UseAdditionalContactO15OtherAddEditContact';
import AddtionalContactAddEditForm from '../AdditionalContactAddEditForm';
import Views from '../../../Views';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';
import AddressBook from '../../../components/AddressBook/AddressBook';
import NavLinks from '../../../NavLinks';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const AddtionalContactO15OtherAddEditContact = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    validateState,
    onBackButtonClicked
  } = useAddtionalContactO15OtherAddEditContact();

  return (
    <ViewTemplate
      viewName={Views.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT}
      onBackButtonClicked={onBackButtonClicked}
      onNextButtonClicked={onNextButtonClicked}>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <AddressBook navlink={NavLinks.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT} />
      <AddtionalContactAddEditForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged} 
        setFormData={setFormData} />
    </ViewTemplate>
  );
};

export default AddtionalContactO15OtherAddEditContact;