import getAboutMember from './GetAboutMember';
import getMemberContact from './GetMemberContact';
import getEthnicity from './GetEthnicity';
import getMembership from './GetMembership';
import getGender from './GetGender';
import getMemberSummary from './GetMemberSummary';

const getLeftNavigationState = (state, viewName, personState, securityState) => {

  const items = [
    getAboutMember(viewName, personState, securityState),
    getMemberContact(viewName, personState, securityState),
    getMembership(state, viewName, personState, securityState),
    getEthnicity(viewName, personState, securityState),
    getGender(viewName, personState, securityState),
    getMemberSummary(viewName, personState, securityState)
  ];

  return items;
};

export default getLeftNavigationState;
