import getInitialState from './GetInitialState';
import Views from '../../Views';
import Constants from '../../../common/utils/Constants';

const getAboutMember = (viewName, personState, securityState) => {
  const isCurrent = viewName === Views.ABOUT_MEMBER_NEW_MEMBER || viewName === Views.ABOUT_MEMBER_EXISTING_MEMBER;

  const isIneligible = (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
    personState?.isMembershipForLoggedInUser === false) ? false : true;

  const isComplete = personState?.person?.firstName && personState?.person?.lastName
    && personState?.person?.birthDate && personState?.person.birthDate !== Constants.BLANK_DATE_STRING
    && (personState?.person?.isUsCitizen === true || personState?.person?.isUsCitizen === false) && isIneligible === false ? true : false;

  const item = getInitialState(Views.ABOUT_MEMBER);

  return {
    ...item,
    isCurrent,
    isComplete
  };
};

export default getAboutMember;