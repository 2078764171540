import { useContext } from 'react';

import PhoneTypeData from './PhoneTypeData';
import { PhoneTypeStateContext } from './PhoneTypeContextProvider';

const usePhoneTypeData = () => {
  const [phoneTypeState, setPhoneTypeState] = useContext(PhoneTypeStateContext);

  const getPhoneTypes = () => {
    PhoneTypeData.getPhoneTypes(phoneTypeState, setPhoneTypeState)
  }
  return {
    phoneTypeState,
    setPhoneTypeState,
    getPhoneTypes
  }
}


export default usePhoneTypeData;