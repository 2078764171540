import { Fragment } from 'react';

import Input from '../../../common/components/inputs/Input';
import DatePicker from '../../../common/components/datepickers/DatePicker';
import SuffixDropdown from '../../../common/components/dropdowns/suffixDropdown/SuffixDropdown';
import TrueFalseRadioButtonList from '../../../common/components/radiobuttonlists/TrueFalseRadioButtonList';
import Checkbox from '../../../common/components/checkboxes/Checkbox';
import ToolTip from '../../../common/components/tooltips/ToolTip';

import style from '../Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const AboutMemberNewMemberForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className='row usas-extra-bottom-margin'>
      <div className="col-xs-12 col-sm-6 ">
        <Input
          label="Legal First Name*"
          name="firstName"
          value={formState.firstName}
          error={errorState.firstName}
          message={errorState.firstName}
          onChange={(value) => { onFormValueChanged('firstName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-4">
        <Input
          label="Legal Last Name*"
          name="lastName"
          value={formState.lastName}
          error={errorState.lastName}
          message={errorState.lastName}
          onChange={(value) => { onFormValueChanged('lastName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-2">
        <SuffixDropdown
          label="Suffix"
          name="suffixId"
          value={formState.suffixId}
          error={errorState.suffixId}
          message={errorState.suffixId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'suffixId', newValueLabel, 'suffix', e); }} />
      </div>
    </div>
    <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
      <div className="col-xs-12 col-sm-6">
        <Input
          className={formState.hasMiddleName === true ? global.HideComponent : global.DisplayComponent}
          label="Legal Middle Name*"
          name="middleName"
          value={formState.middleName}
          error={errorState.middleName}
          message={errorState.middleName}
          onChange={(value) => { onFormValueChanged('middleName', value) }} />
        <span className={style.FontSize12}>
          <Checkbox
            label={formState.hasMiddleName === true
              ? <Fragment>Member does not have a Middle Name <ToolTip label="Check this box if the member does not have a Middle Name. Otherwise, please provide that information." /></Fragment>
              : <Fragment>Member does not have a Middle Name <ToolTip label="Check this box if the member does not have a Middle Name. Otherwise, please provide that information." /></Fragment>}
            name="hasMiddleName"
            checked={formState.hasMiddleName || ''}
            error={errorState.hasMiddleName}
            message={errorState.hasMiddleName}
            onChange={(value) => { onFormValueChanged('hasMiddleName', value) }} />
        </span>
      </div>
      <div className="col-xs-12 col-sm-6">
        <DatePicker
          label="Birth Date*"
          name="birthDate"
          value={formState.birthDate}
          error={errorState.birthDate}
          message={errorState.birthDate}
          onChange={(value) => { onFormValueChanged('birthDate', value); }} />
      </div>
    </div>
    <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
      <div className="col-xs-12 col-sm-6">
        <TrueFalseRadioButtonList
          label="Member is a US Citizen?*"
          name="isUsCitizen"
          value={formState.isUsCitizen}
          error={errorState.isUsCitizen}
          message={errorState.isUsCitizen}
          onChange={(value) => { onFormValueChanged('isUsCitizen', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6">
        <Input
          label="Preferred Name"
          name="preferredName"
          value={formState.preferredName}
          error={errorState.preferredName}
          message={errorState.preferredName}
          onChange={(value) => { onFormValueChanged('preferredName', value) }} />
      </div>
    </div>
  </Fragment>
);

export default AboutMemberNewMemberForm;