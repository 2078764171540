import React, { Fragment } from 'react';

import LargeAddressBookGrid from './LargeAddressBookGrid';
import SmallAddressBookGrid from './SmallAddressBookGrid';

const AddressBookGrid = ({ state, onChanged }) => {
  return (
    <Fragment>
      <form name="addressBookGridLarge">
        <LargeAddressBookGrid state={state} onChanged={onChanged} />
      </form>
      <form name="addressBookGridSmall">
        <SmallAddressBookGrid state={state} onChanged={onChanged} />
      </form>
    </Fragment>
  )
};

export default AddressBookGrid;
