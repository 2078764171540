import { useContext, } from 'react';

import { MemberRegistrationHistoryStateContext } from './MemberRegistrationHistoryContextProvider';

import MembershipRegistrationHistoryData from './MemberRegistrationHistoryData';

const useMembershipRegistrationHistoryData = () => {
  const [memberRegistrationHistoryState, setMemberRegistrationHistoryState] = useContext(MemberRegistrationHistoryStateContext);


  const getMemberRegistrationHistory = (personId) =>
    MembershipRegistrationHistoryData.getMemberRegistrationHistory(personId, memberRegistrationHistoryState, setMemberRegistrationHistoryState);


  return {
    memberRegistrationHistoryState,
    setMemberRegistrationHistoryState,
    getMemberRegistrationHistory,
  };
};

export default useMembershipRegistrationHistoryData;