import React, { Fragment } from 'react';

import useEthnicity from './UseEthnicity';

import Views from '../../Views';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import RadiobuttonList from '../../../common/components/radiobuttonlists/RadiobuttonList';
import MultiSelectCheckboxes from '../../../common/components/checkboxes/MultiSelectCheckboxes';
import ToIntIfInt from '../../../common/utils/ToIntIfInt';

import style from '../Omr.module.css';

const Ethnicity = ({ navigateLink }) => {
  const {
    displayPopUp,
    showEthnicities,
    radiobuttonlistItems,
    checkBoxListItems,
    formState,
    errorState,
    onFormValueChanged,
    onCheckboxFormValueChanged,
    handleSubmit
  } = useEthnicity(navigateLink);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ViewTemplate
        viewName={Views.ETHNICITY}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <RadiobuttonList
              isVertical={true}
              items={radiobuttonlistItems}
              label={<Headings.H6>Hispanic/Latino Ethnicity</Headings.H6>}
              name="hispanicOrLatinoTypeId"
              value={formState.hispanicOrLatinoTypeId}
              error={errorState.hispanicOrLatinoTypeId}
              message={errorState.hispanicOrLatinoTypeId}
              onChange={(value) => onFormValueChanged('hispanicOrLatinoTypeId', ToIntIfInt(value))} />
          </div>
          <div className='visible-xs col-xs-12'> &nbsp; </div>
          <div className="col-xs-12 col-sm-6">
            {showEthnicities === true
              ? <Fragment>
                <Headings.H6>Other Ethnicity</Headings.H6>
                <MultiSelectCheckboxes
                  options={checkBoxListItems}
                  label={<span className={style.OtherEthnicity}>Select up to four options below</span>}
                  name="personEthnicity"
                  value={formState.personEthnicity}
                  error={errorState.ethnicityTypeId}
                  message={errorState.ethnicityTypeId}
                  onChange={(value) => onCheckboxFormValueChanged(value)} />
              </Fragment>
              : <Fragment />}
          </div>
        </div>
        <div className="row usas-extra-bottom-margin" />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title="Loading..."
          displayPopUp={displayPopUp} />
      </ViewTemplate>
    </form>
  );
};

export default Ethnicity;