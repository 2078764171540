import React, { Fragment } from 'react';

import { formatDate } from '../../../common/utils/DateFunctions';

import global from '../../../common/components/GlobalStyle.module.css';

const LargeExistingMemberGrid = ({ state, onSelectMember }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Member Name</th>
          <th>Birth Date</th>
          <th>Last Membership</th>
          <th>Membership Year</th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="4">Loading...</td>
                : <td colSpan="4">No Results</td>}
            </tr>)
          : Array.isArray(state.arrayData) === true && state.arrayData.length > 0
            ? state.arrayData.map((person, i) => (
              <tr key={i}>
                <td>
                  <button className={global.AnchorButton}
                    onClick={(e) => onSelectMember(e, person)}>
                    {person?.firstName} {person?.middleName} {person?.lastName} {person?.suffix}
                  </button></td>
                <td>{formatDate(person?.birthDate)}</td>
                <td>{`${person?.orgUnitName || 'Non-USA Swimming Membership'} - (${person?.orgUnitCode || 'Non-USAS'})`}</td>
                <td>{person?.periodName || ''}</td>
              </tr>
            ))
            : (
              <tr>
                <td colSpan="4">No Results</td>
              </tr>
            )}
      </tbody>
    </table>
  </Fragment>
);

export default LargeExistingMemberGrid;