import { useEffect, useState } from 'react';

import ToBoolIfBool from '../../../common/utils/ToBoolIfBool';

// By default the labels for true and false will be 'Yes' and 'No', but can be overridden
const useTrueFalseRadioButtonList = (trueLabel, falseLabel, onChange) => {
  const [items, setItems] = useState([]);

  const onChangeWithBool = (value) => {
    const newValue = ToBoolIfBool(value);
    onChange(newValue);
  };

  useEffect(() => {
    setItems([{
      value: true,
      label: trueLabel || 'Yes'
    }, {
      value: false,
      label: falseLabel || 'No'
    }]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return { items, onChangeWithBool };
};

export default useTrueFalseRadioButtonList;
