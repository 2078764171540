import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonParentChildByMemberIdData from './GetPersonParentChildByMemberIdData';
import getPersonParentChildByPersonIdData from './GetPersonParentChildByPersonIdData';
import postPersonParentChildByMemberIdData from './PostPersonParentChildByMemberIdData';
import postPersonParentChildByParentPersonIdAndChildMemberIdData from './PostPersonParentChildByParentPersonIdAndChildMemberIdData';
import deletePersonParentChildData from './DeletePersonParentChildData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const PersonParentChildData = {
  INITIAL_STATE,
  getPersonParentChildByMemberIdData,
  getPersonParentChildByPersonIdData,
  postPersonParentChildByMemberIdData,
  postPersonParentChildByParentPersonIdAndChildMemberIdData,
  deletePersonParentChildData,

};

export default PersonParentChildData;