import { isValidEmail, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, isValidAddressLine, isValid5DigitZip, isValid9DigitZip, validateAddress } from '../../../common/utils/validation';
import Constants from '../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  // Email Address
  if (formState.email.trim() === '') {
    errors.email = 'Email Address is required'
  } else if (!isValidEmail(formState.email.trim())) {
    errors.email = 'Must be a valid Email Address'
  } else if (formState.email.trim().length > 512) {
    errors.email = 'Email Address cannot exceed 512 characters'
  }

  //Phone Number
  if (formState.phoneNumber.trim() === '') {
    errors.phoneNumber = 'Phone Number is required'
  } else if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber.trim()) && !isValidMexicanPhoneNumber(formState.phoneNumber.trim())) {
    errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
  }

  let addressError = false;

  //Address 1
  if (formState.address1.trim() === '') {
    addressError = true;
    errors.address1 = 'Address Line 1 is required'
  } else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (formState.address2.trim() !== '' && !isValidAddressLine(formState.address2.trim())) {
    addressError = true;
    errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //  city
  if (formState.city.trim() === '') {
    addressError = true;
    errors.city = 'City is required'
  } else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //Postal Code
  if (formState.postalCode.trim() === '') {
    addressError = true;
    errors.postalCode = 'Zip Code is required'
  } else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  //Phone Type Id
  if (formState.phoneTypeId === Constants.DEFAULT_ID) {
    errors.phoneTypeId = 'Phone Type is required'
  }

  //State Code
  if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode === '') {
    addressError = true;
    errors.stateCode = 'A valid State is required'
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1 !== formState.validatedAddress.address1
        || formState.address2 !== formState.validatedAddress.address2
        || formState.city !== formState.validatedAddress.city
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode !== formState.validatedAddress.postalCode
      )
    ) {
      if (formState.address1 !== formState.validatedAddress.address1) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2 !== formState.validatedAddress.address2) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city !== formState.validatedAddress.city) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode !== formState.validatedAddress.postalCode) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  return errors;
};

const RegistrantContactInfoValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};
export default RegistrantContactInfoValidation;