import React, { Fragment } from 'react';
import useMembershipTypes from '../UseMembershipTypes';
import LargeOfferingsGrid from './LargeOfferingsGrid';
import SmallOfferingsGrid from './SmallOfferingsGrid';

const OfferingTypesGrid = ({ state, selectedOfferingTypeIds, restrictions, onChanged }) => {
  const { onDetailsToggle, expandedId } = useMembershipTypes();
  return (
    <Fragment>
      <LargeOfferingsGrid
        state={state}
        selectedOfferingTypeIds={selectedOfferingTypeIds}
        restrictions={restrictions}
        onChanged={onChanged}
        onDetailsToggle={onDetailsToggle}
        expandedId={expandedId} />
      <SmallOfferingsGrid
        state={state}
        selectedOfferingTypeIds={selectedOfferingTypeIds}
        restrictions={restrictions}
        onChanged={onChanged}
        onDetailsToggle={onDetailsToggle}
        expandedId={expandedId} />
    </Fragment>
  )
};

export default OfferingTypesGrid;