import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet, Navigate } from '../common/wrappers/ReactRouterDom';

import useRoutes from './UseRoutes';

import Welcome from './views/welcome/Welcome';
import Entry from './views/entry/Entry';
import ExistingMemberSearchSelf from './views/existingMemberSearch/ExistingMemberSearchSelf';
import ExistingMemberSearchOther from './views/existingMemberSearch/ExistingMemberSearchOther';
//import Disclaimer from './views/disclaimer/Disclaimer';
import AboutMember from './views/aboutMember/AboutMember';
import RegistrantContactInfoSelf from './views/registrantContactInfo/self/RegistrantContactInfoSelf';
import RegistrantContactInfoOther from './views/registrantContactInfo/other/RegistrantContactInfoOther';
import PrimaryContactU16 from './views/primaryContactInfo/u16/PrimaryContactU16';
import PrimaryContactO15Self from './views/primaryContactInfo/self/PrimaryContactO15Self';
import PrimaryContactO15Other from './views/primaryContactInfo/other/PrimaryContactO15Other';
import AddtionalContactU16 from './views/additionalContacts/u16/AdditionalContactU16';
import AdditionalContactO15Self from './views/additionalContacts/self/AdditionalContactO15Self';
import AddtionalContactO15Other from './views/additionalContacts/other/AdditionalContactO15Other';
import AddtionalContactU16AddEditContact from './views/additionalContacts/u16/AdditionalContactU16AddEditContact';
import AddtionalContactO15SelfAddEditContact from './views/additionalContacts/self/AdditionalContactO15SelfAddEditContact';
import AddtionalContactO15OtherAddEditContact from './views/additionalContacts/other/AdditionalContactO15OtherAddEditContact';
import EthnicityU16 from './views/ethnicity/EthnicityU16';
import EthnicityO15 from './views/ethnicity/EthnicityO15';
import MembershipTypesU16 from './views/membershipTypes/MembershipTypesU16';
import MembershipTypesO15 from './views/membershipTypes/MembershipTypesO15';
import MemberRegistrationHistory from './views/membershipTypes/MemberRegistrationHistory';
import CompetitionCategoryAthU16 from './views/gender/CompetitionCategoryAthU16';
import CompetitionCategoryAthO15 from './views/gender/CompetitionCategoryAthO15';
import GenderIdentityNonAthO15 from './views/gender/GenderIdentityNonAthO15';
// import DisabilityAthU16 from './views/disability/DisabilityAthU16';
// import DisabilityAthO15 from './views/disability/DisabilityAthO15';
// import DisabilityNonAthO15 from './views/disability/DisabilityNonAthO15'
// import MemberSummary from './views/summary/MemberSummary';
import MemberSummary from './views/completion/Summary';
// import AllSummary from './views/summary/Summary';
// import Confirmation from './views/confirmation/Confirmation';

import useNavRoutes from '../common/state/security/UseNavRoutes';
import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import Acknowledgements from './views/acknowledgements/Acknowledgements';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

const AppRoutes = () => {
  useRoutes();
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          {/* //TODO:to be updated */}
          <Route path={'omr'} element={<Navigate to={'/none'} replace />} />
          <Route path={'omr/welcome'} element={<Navigate to={'/none'} replace />} />
          <Route path={'none'} element={<Navigate to={'/omr/welcome/none'} replace />} />

          <Route path={navRoutes['WELCOME_OU']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['WELCOME_OU']} editElement={<Welcome />} />} />
          {/* <Route path={navRoutes['REGISTRATION_DISCLAIMER']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['REGISTRATION_DISCLAIMER']} editElement={<Disclaimer />} />} /> */}
          <Route path={navRoutes['ENTRY']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ENTRY']} editElement={<Entry />} />} />
          <Route path={navRoutes['ABOUT_MEMBER']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ABOUT_MEMBER']} editElement={<AboutMember />} />} />
          <Route path={navRoutes['SEARCH_SELF']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['SEARCH_SELF']} editElement={<ExistingMemberSearchSelf />} />} />
          <Route path={navRoutes['SEARCH_OTHER']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['SEARCH_OTHER']} editElement={<ExistingMemberSearchOther />} />} />
          <Route path={navRoutes['PRIMARY_CONTACT_INFO_U16']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['PRIMARY_CONTACT_INFO_U16']} editElement={<PrimaryContactU16 />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_U16']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_U16']} editElement={<AddtionalContactU16 />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT']} editElement={<AddtionalContactU16AddEditContact />} />} />
          <Route path={navRoutes['REGISTRANT_CONTACT_INFO_SELF']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['REGISTRANT_CONTACT_INFO_SELF']} editElement={<RegistrantContactInfoSelf />} />} />
          <Route path={navRoutes['PRIMARY_CONTACT_INFO_O15_SELF']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['PRIMARY_CONTACT_INFO_O15_SELF']} editElement={<PrimaryContactO15Self />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_O15_SELF']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_O15_SELF']} editElement={<AdditionalContactO15Self />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT']} editElement={<AddtionalContactO15SelfAddEditContact />} />} />
          <Route path={navRoutes['REGISTRANT_CONTACT_INFO_OTHER']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['REGISTRANT_CONTACT_INFO_OTHER']} editElement={<RegistrantContactInfoOther />} />} />
          <Route path={navRoutes['PRIMARY_CONTACT_INFO_O15_OTHER']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['PRIMARY_CONTACT_INFO_O15_OTHER']} editElement={<PrimaryContactO15Other />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_O15_OTHER']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_O15_OTHER']} editElement={<AddtionalContactO15Other />} />} />
          <Route path={navRoutes['ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT']} editElement={<AddtionalContactO15OtherAddEditContact />} />} />
          <Route path={navRoutes['ETHNICITY_U16']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ETHNICITY_U16']} editElement={<EthnicityU16 />} />} />
          <Route path={navRoutes['ETHNICITY_16_AND_UP']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ETHNICITY_16_AND_UP']} editElement={<EthnicityO15 />} />} />
          <Route path={navRoutes['MEMBERSHIP_TYPE_U16']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['MEMBERSHIP_TYPE_U16']} editElement={<MembershipTypesU16 />} />} />
          <Route path={navRoutes['MEMBERSHIP_TYPE_16_AND_UP']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['MEMBERSHIP_TYPE_16_AND_UP']} editElement={<MembershipTypesO15 />} />} />
          <Route path={navRoutes['MEMBERSHIP_REGISTRATION_HISTORY']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['MEMBERSHIP_REGISTRATION_HISTORY']} editElement={<MemberRegistrationHistory />} />} />
          <Route path={navRoutes['ATHLETE_COMP_GENDER_U16']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ATHLETE_COMP_GENDER_U16']} editElement={<CompetitionCategoryAthU16 />} />} />
          <Route path={navRoutes['ATHLETE_COMP_GENDER_16_AND_UP']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['ATHLETE_COMP_GENDER_16_AND_UP']} editElement={<CompetitionCategoryAthO15 />} />} />
          <Route path={navRoutes['GENDER_IDENTITY_16_AND_UP']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['GENDER_IDENTITY_16_AND_UP']} editElement={<GenderIdentityNonAthO15 />} />} />
          {/* <Route path={navRoutes['DISABILITIES_ATHLETE_U16']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['DISABILITIES_ATHLETE_U16']} editElement={<DisabilityAthU16 />} />} />
        <Route path={navRoutes['DISABILITIES_ATHLETE_16_AND_UP']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['DISABILITIES_ATHLETE_16_AND_UP']} editElement={<DisabilityAthO15 />} />} />
        <Route path={navRoutes['DISABILITIES_NON_ATHLETE_16_AND_UP']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['DISABILITIES_NON_ATHLETE_16_AND_UP']} editElement={<DisabilityNonAthO15 />} />} /> */}
          <Route path={navRoutes['REGISTRATION_ACKNOWLEDGEMENTS']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['REGISTRATION_ACKNOWLEDGEMENTS']} editElement={<Acknowledgements />} />} />
          <Route path={navRoutes['REGISTRATION_SUMMARY']?.path}
            element={<SecuredRouteElement navRoute={navRoutes['REGISTRATION_SUMMARY']} editElement={<MemberSummary />} />} />
          {/* <Route path={navRoutes['SUMMARY']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['SUMMARY']} editElement={<AllSummary />} />} /> */}
          {/*           
        <Route path={navRoutes['CONFIRMATION']?.path}
          element={<SecuredRouteElement navRoute={navRoutes['CONFIRMATION']} editElement={<Confirmation />} />} /> */}

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />

        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;