import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const getMemberRegistrationRenewalOptionsData = (personId, orgUnitId, offeringInstanceCouponId, isRenewalAvailable, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = encodeURIComponent(personId);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const offeringInstanceCouponIdForUrl = offeringInstanceCouponId ? encodeURIComponent(offeringInstanceCouponId) : '';
    const isRenewalAvailableForUrl = isRenewalAvailable ? encodeURIComponent(isRenewalAvailable) : '';
    const url = `/MemberRegistration/person/${personIdForUrl}/renewal?orgUnitId=${orgUnitIdForUrl}&offeringInstanceCouponId=${offeringInstanceCouponIdForUrl}&isRenewalAvailable=${isRenewalAvailableForUrl}`;
    OmrHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const postMemberRegistrationRenewalData = (personId, arrayData, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      arrayData: {},
      message: 'Saving...'
    };
    setState(newState);

    const personIdForUrl = encodeURIComponent(personId);
    const url = `/MemberRegistration/person/${personIdForUrl}/renewal`;
    OmrHttpHelper(url, 'POST', arrayData)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberRegistrationRenewalData = {
  INITIAL_STATE,
  getMemberRegistrationRenewalOptionsData,
  postMemberRegistrationRenewalData
};

export default MemberRegistrationRenewalData;