import { useState } from 'react';

import SuffixData from './SuffixData';

const useSuffixData = () => {
  const [suffixState,] = useState(SuffixData.INITIAL_STATE);
  
  return {
    suffixState
  }
}


export default useSuffixData;