import Constants from "../../../common/utils/Constants";

export const localValidate = (competitionGenderTypeSelectionModalFormState) => {
  let errors = {};

  if (competitionGenderTypeSelectionModalFormState.competitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.competitionGenderTypeId = 'A Competition Category selection is required';
  }

  return errors;
};

const RenewalCompetitionGenderTypeFormValidation = async (competitionGenderTypeSelectionModalFormState) => {
  let errors = localValidate(competitionGenderTypeSelectionModalFormState);

  return errors ? errors : {};
};

export default RenewalCompetitionGenderTypeFormValidation;