import React, { Fragment } from 'react';

import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../common/components/icons/EditIcon';
import { formatPhoneNumber } from '../../../common/utils/validation';

import global from '../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ index, contact, onEdit, onDeleteClicked, onNavigateToEmergencyContact }) => (
	<Fragment>
		{contact.isPrimaryContact === true
			? <button className={global.IconButton} type="button" onClick={(e) => onNavigateToEmergencyContact(e, index)}> <EditIcon /> </button>
			: <Fragment>
				<button className={global.IconButton} type="button" onClick={(e) => onDeleteClicked(e, index)}><DeleteIcon /></button>
				<button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, index)}> <EditIcon /> </button>
			</Fragment>
		}
	</Fragment>
);

const GridRowContent = ({ contact }) => (
	<div className={global.SmallTableRowInfo}>
		<div className='row'>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
				Contact Name
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
				{contact?.firstName || ''} {contact?.lastName || ''}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Relationship
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{contact?.contactRelationshipName || ''}
			</div>
			<div className={['col-xs-6 col-sm-3 ', global.SmallTableRowLabels].join(' ')}>
				Email Address
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{contact?.emailAddress || ''}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Phone Number
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatPhoneNumber(contact?.phoneNumber) || ''}
			</div>
		</div>
	</div>
);

const GridRow = ({ contact, onEdit, onDeleteClicked, state, onNavigateToEmergencyContact }) => {
	return (
		<Fragment>
			<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					<span className='hidden-xs'>{contact.firstName || ''} {contact.lastName || ''}</span>&nbsp;
					<GridRowIcons
						index={state.findIndex(x => x === contact)}
						contact={contact}
						onEdit={onEdit}
						onDeleteClicked={onDeleteClicked}
						onNavigateToEmergencyContact={onNavigateToEmergencyContact} />
				</div>
				<GridRowContent contact={contact} />
			</div>
		</Fragment>
	);
}

const SmallAdditionalContactsGrid = ({ state, onEdit, onDeleteClicked, onNavigateToEmergencyContact }) => (
	<div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
		{(Array.isArray(state) === false
			|| state.length === 0)
			? (<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					&nbsp;
				</div>
				<div className={global.SmallTableRowInfo}>
					<div className={global.SmallTableRowLabels}>Click 'Add Contact' to add additional contacts</div>
				</div>
			</div>)
			: state?.map((contact, i) =>
				<GridRow
					key={i}
					state={state}
					contact={contact}
					onEdit={onEdit}
					onDeleteClicked={onDeleteClicked}
					onNavigateToEmergencyContact={onNavigateToEmergencyContact} />
			)}
	</div>
);

export default SmallAdditionalContactsGrid;