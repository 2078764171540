import React from 'react';

import useRenewalOptionDropdown from './UseRenewalOptionDropdown';

import Dropdown from '../Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const RenewalOptionDropdown = ({ label, name, value, error, message, onChange, personId, offeringInstanceId, orgUnitId }) => {
  const { renewalOptionState } = useRenewalOptionDropdown(personId, offeringInstanceId, orgUnitId);

  return renewalOptionState.message
    ? <span className={global.LoadingMsg}>{renewalOptionState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={renewalOptionState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={renewalOptionState.isArrayLoading} />
    );
};

export default RenewalOptionDropdown;