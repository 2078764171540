import { PersonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const getOmrPersonDupes = (firstName, lastName, birthDate, memberId, orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const birthDateEncode = encodeURIComponent(birthDate);
    const memberIdEncode = encodeURIComponent(memberId);
    const url = `/Person/omr/query/dupcheck?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDateEncode}&memberId=${memberIdEncode}&orgUnitId=${orgUnitId}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OmrPersonDupCheckData = {
  INITIAL_STATE,
  getOmrPersonDupes,
};

export default OmrPersonDupCheckData;