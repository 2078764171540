import React, { Fragment } from 'react';

import useGenderForm from './UseGenderForm';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Headings from '../../../common/components/headings/Headings';
import CompetitionCategoryRadiobuttonList from '../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import RadiobuttonList from '../../../common/components/radiobuttonlists/RadiobuttonList';
import Constants from '../../../common/utils/Constants';
import ReadOnly from '../../../common/components/readOnly/ReadOnly';

import style from '../Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const GenderForm = ({
  formState,
  errorState,
  state,
  setState,
  handleSubmit,
  onFormValueChanged,
  isCompetitionCategoryLocked
}) => {
  const {
    competitionGenderState,
    uiState,
    onShowHideOptionalGenderIdentityToggle,
  } = useGenderForm(formState, state, setState)

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
          <span className={isCompetitionCategoryLocked === false ? global.DisplayComponent : global.HideComponent}>
            <CompetitionCategoryRadiobuttonList
              label={<Headings.H6>Select Competition Category</Headings.H6>}
              isVertical={true}
              name="competitionGenderTypeId"
              value={Number(formState.competitionGenderTypeId)}
              error={errorState.competitionGenderTypeId}
              message={errorState.competitionGenderTypeId}
              onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)) }} />
          </span>
          <span className={isCompetitionCategoryLocked === true ? global.DisplayComponent : global.HideComponent}>
            <ReadOnly
              label="Competition Category"
              value={formState?.competitionGenderTypeName}
              name='competitionGenderTypeName' />
          </span>
        </div>
        {uiState.showGenderIdentity === true && state?.radiobuttonlistGenderIdentity?.length > 0
          ? <div className="col-xs-12 col-sm-6">
            <RadiobuttonList
              label={<Headings.H6>Select Gender Identity</Headings.H6>}
              isVertical={true}
              items={state?.radiobuttonlistGenderIdentity}
              name="genderIdentityTypeId"
              value={Number(formState.genderIdentityTypeId)}
              error={errorState.genderIdentityTypeId}
              message={errorState.genderIdentityTypeId}
              onChange={(value) => { onFormValueChanged('genderIdentityTypeId', Number(value)) }} />
          </div>
          : <Fragment />
        }
        {uiState.showGenderIdentity === true
          ? formState.genderIdentityTypeId > Constants.DEFAULT_ID
            ? <Fragment />
            : <button
              className={style.ShowHideGenderIdentityToggle}
              type="button"
              onClick={onShowHideOptionalGenderIdentityToggle}>Hide Optional Gender Identity Selection
            </button>
          : <button
            className={style.ShowHideGenderIdentityToggle}
            type="button"
            onClick={onShowHideOptionalGenderIdentityToggle}>Show Optional Gender Identity Selection
          </button>}
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={competitionGenderState?.isArrayLoading} />
    </Fragment>);
}

export default GenderForm;