import { useContext } from 'react';
import { PersonParentChildStateContext } from './PersonParentChildContextProvider';
import PersonParentChildData from './PersonParentChildData';

const usePersonParentChildData = () => {
  const [personParentChildState, setPersonParentChildState] = useContext(PersonParentChildStateContext);

  const getPersonParentChildByMemberId = (memberId) => {
    PersonParentChildData.getPersonParentChildByMemberIdData(memberId, personParentChildState, setPersonParentChildState);
  };

  const getPersonParentChildByPersonId = (personId) => {
    PersonParentChildData.getPersonParentChildByPersonIdData(personId, personParentChildState, setPersonParentChildState);
  };

  const postPersonParentChildByMemberId = (memberId) => {
    PersonParentChildData.postPersonParentChildByMemberIdData(memberId, personParentChildState, setPersonParentChildState);
  };

  const postPersonParentChildByParentPersonIdAndChildMemberId = (personId, memberId) => {
    PersonParentChildData.postPersonParentChildByParentPersonIdAndChildMemberIdData(personId, memberId, personParentChildState, setPersonParentChildState);
  };

  const deletePersonParentChild = (personParentChildId) => {
    PersonParentChildData.deletePersonParentChildData(personParentChildId, personParentChildState, setPersonParentChildState);
  };

  const confirmSave = () => {
    setPersonParentChildState({
      ...personParentChildState,
      isSaved: false
    });
  };

  return {
    personParentChildState,
    getPersonParentChildByMemberId,
    getPersonParentChildByPersonId,
    postPersonParentChildByMemberId,
    deletePersonParentChild,
    confirmSave,
    postPersonParentChildByParentPersonIdAndChildMemberId
  };
};

export default usePersonParentChildData;