import { isValidBirthDate, isValidName, isValidDate } from '../../../common/utils/validation';
import Constants from '../../../common/utils/Constants';
import { getAge } from '../../../common/utils/DateFunctions';

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName?.trim() === '') {
    errors.firstName = 'Legal First Name is required'
  } else if (!isValidName(formState.firstName)) {
    errors.firstName = 'Legal First Name must be a valid name'
  }

  //Last Name
  if (formState.lastName?.trim() === '') {
    errors.lastName = 'Legal Last Name is required'
  } else if (!isValidName(formState.lastName)) {
    errors.lastName = 'Legal Last Name must be a valid name'
  }

  //Preferred Name
  if (formState.preferredName?.trim() !== '') {
    if (!isValidName(formState.preferredName)) {
      errors.preferredName = 'Preferred Name must be a valid name'
    }
  }

  //Middle Name
  if (formState.middleName?.trim() !== '') {
    if (!isValidName(formState.middleName)) {
      errors.middleName = 'Legal Middle Name must be a valid name'
    }
  }

  //Has middle Name
  if (formState.middleName?.trim() === '' && (formState.hasMiddleName === undefined || formState.hasMiddleName === false)) {
    errors.hasMiddleName = "Legal Middle Name is required if the 'Member does not have a Middle Name' box is not checked";
  }

  //Birth Date
  const isOver16 = getAge(formState.birthDate) > 15;
  if (formState.birthDate === Constants.BLANK_DATE_STRING) {
    errors.birthDate = 'Birth Date is required'
  } else if (!isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date must be a valid date';
  } else if (!isValidBirthDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date cannot be in the future'
  } else if (isOver16 === false && formState.isRegistrantMembershipForLoggedInUser) {
    //bithdate being less than 16 years old and logged in user
    errors.birthDate = 'If you are the logged-in user, you must be at least 16 years old to proceed'
  }

  //is US Citizen
  if (!(formState.isUsCitizen === true || formState.isUsCitizen === false)) {
    errors.isUsCitizen = "Please select 'Yes' or 'No'"
  }

  return errors;
};

const AboutMeValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default AboutMeValidation;