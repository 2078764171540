import React from 'react';

import useCompetitonCategoryAthU16 from './UseCompetitonCategoryAthU16';
import GenderCompetitionCategoryForm from './GenderCompetitionCategoryForm';
import Views from '../../Views';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

const CompetitonCategoryAthU16 = () => {
  const {
    personState,
    formState,
    errorState,
    setFormData,
    handleSubmit,
    onNextButtonClicked,
    onFormValueChanged,
    isCompetitionCategoryLocked
  } = useCompetitonCategoryAthU16();

  return (
    <ViewTemplate
      viewName={Views.COMPETITION_CATEGORY}
      onNextButtonClicked={onNextButtonClicked}>
      <GenderCompetitionCategoryForm
        handleSubmit={handleSubmit}
        personState={personState}
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        setFormData={setFormData}
        isCompetitionCategoryLocked={isCompetitionCategoryLocked} />
    </ViewTemplate>
  );
};

export default CompetitonCategoryAthU16;