import React from 'react';

import useAddtionalContactUnder16 from './UseAdditionalContactU16';
import AdditionalContactsGrid from '../AdditionalContactsGrid';
import DeleteContactModalInfo from '../DeleteContactModalInfo';
import Views from '../../../Views';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const AddtionalContactU16 = () => {
  const {
    onEdit,
    onDeleteConfirmed,
    onNextButtonClicked,
    onAddAditionalContact,
    modalState,
    onModalCanceled,
    onDeleteClicked,
    onNavigateToEmergencyContact
  } = useAddtionalContactUnder16();

  return (
    <ViewTemplate
      viewName={Views.ADDITIONAL_CONTACTS_U16}
      onNextButtonClicked={onNextButtonClicked}>
      <ActionIntraPageButton onClick={onAddAditionalContact}>Add Contact</ActionIntraPageButton>
      <AdditionalContactsGrid
        onEdit={onEdit}
        onDeleteClicked={onDeleteClicked}
        onNavigateToEmergencyContact={onNavigateToEmergencyContact} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <DeleteContactModalInfo modalState={modalState} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
            <PrimaryButton type="button" onClick={(e) => { onDeleteConfirmed(e, modalState.itemIndex); }}>Delete</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    </ViewTemplate>
  );
};

export default AddtionalContactU16;