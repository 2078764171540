import { useContext } from 'react';

import { OmrBackButtonStateContext } from './OmrBackButtonContextProvider';
import OmrBackButtonData from './OmrBackButtonData';

const useOmrBackButtonData = () => {
  const [omrBackButtonState, setOmrBackButtonState] = useContext(OmrBackButtonStateContext);

  const resetOmrBackButtonState = () => {
    setOmrBackButtonState(OmrBackButtonData.INITIAL_STATE);
  };

  return {
    resetOmrBackButtonState,
    omrBackButtonState,
    setOmrBackButtonState
  };
};

export default useOmrBackButtonData;