import React, { Fragment } from 'react';

import useNotLoggedInMessage from './UseNotLoggedInMessage';

import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import style from '../Omr.module.css';

const NotLoggedInMessage = () => {
  const { onLoginClicked } = useNotLoggedInMessage();

  return (
    <Fragment>
      <div className={['row', style.BottomMargin].join('')}>
        <div className={style.CenterAlign}>
          You are currently not logged in to the USA Swimming site. Click the 'Login' button below
          to be redirected to a screen that will allow you to login or create a login. After you have logged in,
          you will be redirected back to this page and allowed to continue the Online Member Registration process.
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'></div>
      <div className='row usas-extra-top-margin'>
        <div className={style.CenterAlign}>
          <PrimaryButton onClick={onLoginClicked}>Login</PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default NotLoggedInMessage;