import React, { Fragment } from 'react';

//import useMemberSummary from '../UseMemberSummary';
import useMemberSummaryMembershipGrid from './UseMemberSummaryMembershipGrid';

import { formatDate } from '../../../../common/utils/DateFunctions';
import{VisuallyHidden}from "@reach/visually-hidden";

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../../Omr.module.css';

const MemberSummaryMembershipGrid = ({state}) => {
	const { onDetailsToggle, expandedId } = useMemberSummaryMembershipGrid();
  
	return (
		<Fragment>
			<table>
				<thead></thead>
				<tbody>
					{Array.isArray(state) === true && state.length > 0
						? state.map((offering, i) => (
							<Fragment key={"info" + i}>
								<tr className={style.MemberSummaryRowHeight} key={i}>
									<td className={[style.MemberSummaryGridWidth, style.FontSize12].join(' ')}>{offering.membershipName}</td>
									<td className={[style.MemberSummaryGridWidth, style.FontSize12, global.Bold].join(' ')}>EXP: {formatDate(offering.expirationDate)}</td>
									<td className={style.MemberSummaryGridWidth}>
										{offering?.details &&
											<button className={global.IconButtonMargin}
												type="button"
												onClick={(e) => onDetailsToggle(e, offering.offeringInstanceId)}>
												<VisuallyHidden>{expandedId === offering.offeringInstanceId ? 'Hide' : 'Show'}Detail for{offering.offeringName}</VisuallyHidden>
												<span className={style.MembershipDetails}>Details</span>
											</button>}
									</td>
								</tr>
								{expandedId === offering.offeringInstanceId &&
									<tr key={"detail" + i}>
										<td className={style.ShowDetailsLargeMemberSummary}>
											{offering.details}
										</td>
									</tr>}
							</Fragment>
						))
						: (
							<Fragment />
						)}
				</tbody>
			</table>
		</Fragment>
	);
};

export default MemberSummaryMembershipGrid;