import React, { Fragment } from 'react';

import useGenderIdentityForm from './UseGenderIdentityForm';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import RadiobuttonList from '../../../common/components/radiobuttonlists/RadiobuttonList';
import Constants from '../../../common/utils/Constants';
import Headings from '../../../common/components/headings/Headings';

const GenderIdentityForm = ({
  formState,
  errorState,
  state,
  setState,
  handleSubmit,
  onFormValueChanged,
}) => {
  const {
    genderIdentityState
  } = useGenderIdentityForm( state, setState);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
          <RadiobuttonList
            label={<Headings.H6>Select Gender Identity</Headings.H6>}
            isVertical={true}
            items={state.radiobuttonlistGenderIdentity}
            name="genderIdentityTypeId"
            value={Number(formState.genderIdentityTypeId)}
            error={errorState.genderIdentityTypeId}
            message={errorState.genderIdentityTypeId}
            onChange={(value) => { onFormValueChanged('genderIdentityTypeId', Number(value)) }} />
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={genderIdentityState?.isArrayLoading} />
    </Fragment>);
}

export default GenderIdentityForm;