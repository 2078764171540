import { useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useLeftNavigation from '../leftNavigation/UseLeftNavigation';
import useAddressBookData from '../../state/omr/UseAddressBookData';

const INITIAL_STATE = {
  addressBookModalState: {
    modalTitle: 'Saved Contact List',
    displayPopUp: false,
  },
  personName: '',
  personContactObj: {},
  errorMessage: ''
};

const useAddressBook = (onOpenAddressBook) => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const { addressBookState } = useAddressBookData();
  const { personName } = useLeftNavigation();

  const handleAddressBookClicked = (e) => {
    if (onOpenAddressBook instanceof Function) {
      onOpenAddressBook(e);
    } else {
      if (e?.preventDefault) {
        e.preventDefault();
      }

      onAddressBookClicked();
    }
  };

  const onAddressBookClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      addressBookModalState: {
        ...state.addressBookModalState,
        displayPopUp: true,
        state: addressBookState
      }
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    setState({
      ...state,
      addressBookModalState: {
        ...state.addressBookModalState,
        displayPopUp: false,
      }
    });
  };


  const onSetContactOnRadioButtonClicked = (e, index, personContactObj) => {
    document.addressBookGridLarge.addressId.value = index;
    document.addressBookGridSmall.addressId.value = index;

    if (Object.keys(personContactObj).length > 0) {
      setState({ ...state, personContactObj: personContactObj });
    }
  };

  const validate = () => {
    if (Object.keys(state.personContactObj).length === 0) {

      return "Please select a contact to populate the form, otherwise click 'Cancel' to go back to the form without entering any saved contact information."
    } else {

      return ''
    }
  };

  const onSendPersonObjInfoToForm = (e, path) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const errorMessageRecieved = validate();
    if (errorMessageRecieved) {

      setState({ ...state, errorMessage: errorMessageRecieved });

    } else {
      if (Object.keys(state?.personContactObj).length > 0 && path !== '') {
        let personContactObj = {
          firstName: state?.personContactObj?.firstName,
          lastName: state?.personContactObj?.lastName,
          email: state?.personContactObj?.email,
          phoneNumber: state?.personContactObj?.phoneNumber,
          phoneTypeId: state?.personContactObj?.phoneTypeId,
          address1: state?.personContactObj?.address1,
          address2: state?.personContactObj?.address2,
          city: state?.personContactObj?.city,
          stateCode: state?.personContactObj?.stateCode,
          stateName: state?.personContactObj?.stateName,
          postalCode: state?.personContactObj?.postalCode
        }

        onModalCanceled();
        navigate(path, { state: { personContactObj } });
      }
    }
  };

  return {
    addressBookState,
    onAddressBookClicked,
    addressBookModalState: state.addressBookModalState,
    onModalCanceled,
    handleAddressBookClicked,
    personName,
    onSetContactOnRadioButtonClicked,
    onSendPersonObjInfoToForm,
    errorState: state.errorMessage
  };
};

export default useAddressBook;