import { useState } from 'react';

import OmrPersonDupCheckData from './OmrPersonDupCheckData';

const useOmrPersonDupCheckData = () => {
  const [omrPersonDupCheckState, setOmrPersonDupCheckState] = useState(OmrPersonDupCheckData.INITIAL_STATE);


  const getOmrPersonDupes = (firstName, lastName, birthDate, memberId, orgUnitId = '') => {
    OmrPersonDupCheckData.getOmrPersonDupes(firstName, lastName, birthDate, memberId, orgUnitId, omrPersonDupCheckState, setOmrPersonDupCheckState);
  };

  return {
    omrPersonDupCheckState,
    setOmrPersonDupCheckState,
    getOmrPersonDupes
  };
};

export default useOmrPersonDupCheckData;