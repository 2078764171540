import React, { createContext, useState } from 'react';

const INITIAL_STATE = {
  filterObject: {},
  tryRedirect: false,
  showFilters: true,
};

export const ExistingMemberSearchFilterStateContext = createContext();

const ExistingMemberSearchFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <ExistingMemberSearchFilterStateContext.Provider value={stateHook}>
      {children}
    </ExistingMemberSearchFilterStateContext.Provider>
  );
};

export default ExistingMemberSearchFilterContextProvider;