import React, { createContext, useState } from 'react';

import PersonData from '../../../../common/state/person/PersonData';

export const PersonStateContext = createContext();

const PersonContextProvider = ({ children }) => {
  const stateHook = useState(PersonData.INITIAL_STATE);

  return (
    <PersonStateContext.Provider value={stateHook}>
      {children}
    </PersonStateContext.Provider>
  );
};

export default PersonContextProvider;