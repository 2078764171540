
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import getRegisteredMembersByPersonIdData from "./GetRegisteredMembersByPersonIdData";
import postMemberRegistrationNewMembershipData from "./PostMemberRegistrationNewMembershipData";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RegisteredMembersData = { INITIAL_STATE, getRegisteredMembersByPersonIdData, postMemberRegistrationNewMembershipData };

export default RegisteredMembersData;