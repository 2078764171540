import React, { Fragment, } from 'react';

import { convertNumberToCurrencyString } from '../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeMemberRegistrationHistoryGrid = ({ state }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Membership Name</th>
          <th>LSC Code | Club Name</th>
          <th>Registration Date</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="8">Loading...</td>
                : <td colSpan="8">No Results</td>}
            </tr>)
          : Array.isArray(state.arrayData) === true && state.arrayData.length > 0
            ? state.arrayData.map((memberHistory, i) => (
              <tr key={i}>
                <td>{memberHistory.offeringName}</td>
                <td>{memberHistory.lscCode} | {memberHistory.clubName}-({memberHistory.clubCode})</td>
                <td>{formatDate(memberHistory.effectiveDate)}</td>
                <td>{convertNumberToCurrencyString(memberHistory.price)}</td>
              </tr>
            ))
            : (
              <tr>
                <td colSpan="6">No records match the entered information</td>
              </tr>
            )}
      </tbody>
    </table>
  </Fragment>
);

export default LargeMemberRegistrationHistoryGrid;