import React, { Fragment } from 'react';

import { formatPhoneNumber } from '../../../common/utils/validation';

import style from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ person, onChanged }) => (
  <Fragment>
    <span className='pull-right '>
      <input
        className={[style.RadioButton, global.BaseInputBox].join(' ')}
        type='radio'
        id={`addressId-${person.index}`}
        name="addressId"
        value={person.index}
        onChange={(e) => { onChanged(e, person.index, person) }}>
      </input>
    </span>
  </Fragment>
);

const GridRowContent = ({ person }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Contact Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {person?.firstName} {person?.middleName} {person?.lastName} {person?.suffix}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Email
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {person.email}
      </div>
      <div className={['col-xs-6 col-sm-3 ', global.SmallTableRowLabels].join(' ')}>
        Phone Number
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatPhoneNumber(person?.phoneNumber)}
      </div>
      <div className={['col-xs-6 col-sm-3 ', global.SmallTableRowLabels].join(' ')}>
        Address
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {person?.address1} {person?.address2} {person?.city} , {person?.stateCode} {person?.postalCode}
      </div>

    </div>
  </div>
);

const GridRow = ({ person, onChanged }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{person?.firstName} {person?.middleName} {person?.lastName}</span>&nbsp;
          <GridRowIcons
            index={person?.index}
            onChanged={onChanged}
            person={person} />
        </div>
        <GridRowContent person={person} />
      </div>
    </Fragment>
  );
}
const SmallExistingMemberGrid = ({ state, onChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.length === 0
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>No Results</div>
        </div>
      </div>)
      : Array.isArray(state) === true
        && state?.length > 0
        ? state?.map((person, i) =>
          <GridRow
            key={i}
            person={person}
            onChanged={onChanged} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallExistingMemberGrid;