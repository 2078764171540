import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from './GenderNonAthValidation';
import useOmrData from '../../state/omr/UseOmrData';
import NavLinks from '../../NavLinks';

import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import Constants from '../../../common/utils/Constants';
import useForm from '../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  genderIdentityTypeId: Constants.DEFAULT_ID
}

const INITIAL_VIEW_STATE = {
  radiobuttonlist: [],
  radiobuttonlistGenderIdentity: [],
}

const useGenderIdentityNonAthO15 = () => {
  const navigate = useNavigate();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const {
    formState,
    errorState,
    setFormData,
    handleSubmit,
    onFormValueChanged } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {

    const newPerson = {
      ...personState,
      person: {
        ...personState.person,
        genderIdentityTypeId: formState.genderIdentityTypeId
      }
    };

    savePersonState(newPerson);
  };


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.genderIdentityTypeId > 0) {
        if (formState.genderIdentityTypeId !== Constants.DEFAULT_ID && isSaved === false) {
          if (formState.genderIdentityTypeId !== personState.person.genderIdentityTypeId) {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });

          } else {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.genderIdentityTypeId === Constants.DEFAULT_ID) {
        if (formState.genderIdentityTypeId === Constants.DEFAULT_ID && isSaved === false) {

          setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);

  useEffect(() => {
    if (formState.genderIdentityTypeId && personState.person) {

      const genderTypeId = personState?.person?.genderIdentityTypeId > 0
        ? personState?.person?.genderIdentityTypeId
        : Constants.DEFAULT_ID

      const newFormState = {
        ...formState,
        genderIdentityTypeId: genderTypeId
      };

      setFormData(newFormState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  useDidMountEffect(() => {
    if (isSaved === true) {

      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
      //navigate(NavLinks.DISABILITIES_NON_ATHLETE_16_AND_UP);
      document.getElementById('omrHeadingId')?.scrollIntoView();
      navigate(NavLinks.REGISTRATION_ACKNOWLEDGEMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    formState,
    errorState,
    state,
    setState,
    handleSubmit,
    onNextButtonClicked: handleSubmit,
    onFormValueChanged
  };
};

export default useGenderIdentityNonAthO15;