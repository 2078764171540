import NavLinks from "../../NavLinks";
import Views from "../../Views";

const INITIAL_STATE = [{
  name: Views.ABOUT_MEMBER,
  displayName: 'About Member',
  path: NavLinks.ABOUT_MEMBER,
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
},
{
  name: Views.MEMBER_CONTACT,
  displayName: 'Member Contact',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, {
  name: Views.MEMBERSHIP,
  displayName: 'Membership',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
},
{
  name: Views.ETHNICITY,
  displayName: 'Ethnicity',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
},
{
  name: Views.GENDER,
  displayName: 'Gender',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
},
/* {
  name: Views.DISABILITIES,
  displayName: 'Disability',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}, */
{
  name: Views.MEMBER_SUMMARY,
  displayName: 'Member Summary',
  path: '',
  isCurrent: false,
  isComplete: false,
  isVisited: false,
  arePrerequisitesDone: false
}
];

const GetInitialState = (viewName) => {
  const state = INITIAL_STATE.find(x => x.name === viewName);
  return JSON.parse(JSON.stringify(state || {}));
};

export default GetInitialState;