import { useEffect, useState } from 'react';
import useCompetitionGenderTypeData from '../../../common/state/competitionGender/UseCompetitionGenderTypeData';
import useEthnicityTypeData from '../../../common/state/ethnicityType/UseEthnicityTypeData';
import useGenderIdentityTypeData from '../../../common/state/genderIdentity/UseGenderIdentityTypeData';
import useHispanicOrLatinoTypeData from '../../../common/state/hispanicOrLatinoType/UseHispanicOrLatinoTypeData';
import usePhoneTypeData from '../../../common/state/phoneType/UsePhoneTypeData';
import { formatPhoneNumber } from '../../../common/utils/validation';
import useOmrData from '../../state/omr/UseOmrData';

const INITIAL_STATE = {
  ethnicityInfo: '',
  competitionGender: '',
  genderIdentity: '',
  memberPhone: '',
  emergencyPhone: ''
};
const useFormattedStrings = () => {
  const { personState } = useOmrData();
  const { hispanicOrLatinoTypeState, } = useHispanicOrLatinoTypeData();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const { genderIdentityState } = useGenderIdentityTypeData();
  const { ethnicityTypeState, } = useEthnicityTypeData();
  const { phoneTypeState } = usePhoneTypeData();
  const [ state, setState ] = useState({...INITIAL_STATE});

  const getAllEthnicitiyInfo = (personState, hispanicOrLatinoTypeState, ethnicityTypeState) => {
    const hispanicOrLatinoType = (hispanicOrLatinoTypeState?.arrayData?.find(x => x.hispanicOrLatinoTypeId === personState?.person?.hispanicOrLatinoTypeId)?.typeName) || '';
    
    const ethnicityArray = [];
    if (hispanicOrLatinoType !== 'I prefer not to say') {
      personState.person.personEthnicity.forEach(id => {
        const individualEthnicityInfo = ethnicityTypeState?.arrayData?.find(x => x.ethnicityTypeId === id)?.typeName;
        if (individualEthnicityInfo) {
          ethnicityArray.push(individualEthnicityInfo);
        }
      });
    }
    const ethnicities = ethnicityArray.length > 0 ? ' : ' + ethnicityArray.join(', ') : '';

    return hispanicOrLatinoType + ethnicities;
  };

  const getCompetitionGenderTypeName = (personState, competitionGenderState) => {
    const competitionGenderTypeName = personState?.person?.competitionGenderTypeName || '';
    if (competitionGenderTypeName && competitionGenderTypeName !== 'Unknown') {
      return competitionGenderTypeName;
    }

    const competitionGenderType = competitionGenderState?.arrayData.find(x => x.competitionGenderTypeId === personState?.person?.competitionGenderTypeId);
    return competitionGenderType?.typeName || ' ';
  };

  const getGenderIdentityTypeName = (personState, genderIdentityState) => {
    const genderIdentityType = genderIdentityState?.arrayData?.find(x => x.genderIdentityTypeId === personState?.person?.genderIdentityTypeId);
    return genderIdentityType?.typeName || 'Not Submitted';
  };

  const getMemberPhoneType = (personState, phoneTypeState) => {
    if (personState?.is16orOlder === true) {
      const phoneType = phoneTypeState?.arrayData.find(x => x.phoneTypeId === personState?.person?.contactInfo?.phoneTypeId)?.typeName
      return formatPhoneNumber(personState?.person?.contactInfo?.phoneNumber) + '(' + phoneType.charAt(0) + ')';
    };

    return '';
  };

  const getEmergencyContactPhoneType = (personState, phoneTypeState) => {
    if (phoneTypeState?.isArrayLoaded === true) {
      const phoneType = phoneTypeState?.arrayData.find(x => x.phoneTypeId === personState?.person?.primaryContactInfo?.phoneTypeId)?.typeName
      const emergnecyContactPhoneInfo = formatPhoneNumber(personState?.person?.primaryContactInfo?.phoneNumber) + '(' + phoneType.charAt(0) + ')';
      return emergnecyContactPhoneInfo
    };
  };

  useEffect(() => {
    if (personState?.person?.firstName) {
      const newState = {...state};
      let changed = false;
      if (hispanicOrLatinoTypeState.isArrayLoaded === true
        && ethnicityTypeState.isArrayLoaded === true) {
          newState.ethnicityInfo = getAllEthnicitiyInfo(personState, hispanicOrLatinoTypeState, ethnicityTypeState);
          changed = changed || newState.ethnicityInfo !== state.ethnicityInfo;
      }

      if (competitionGenderState.isArrayLoaded === true) {
        newState.competitionGender = getCompetitionGenderTypeName(personState, competitionGenderState);
        changed = changed || newState.competitionGender !== state.competitionGender;
      }

      if (genderIdentityState.isArrayLoaded === true) {
        newState.genderIdentity = getGenderIdentityTypeName(personState, genderIdentityState);
        changed = changed || newState.genderIdentity !== state.genderIdentity;
      }

      if (phoneTypeState.isArrayLoaded === true) {
        newState.memberPhone = getMemberPhoneType(personState, phoneTypeState);
        newState.emergencyPhone = getEmergencyContactPhoneType(personState, phoneTypeState);
        changed = changed || newState.memberPhone !== state.memberPhone || newState.emergencyPhone !== state.emergencyPhone;
      }
      
      if (changed === true) {
        setState(newState);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, hispanicOrLatinoTypeState, ethnicityTypeState, competitionGenderState, genderIdentityState]);
  
  return {
    ...state
  };
};

export default useFormattedStrings;