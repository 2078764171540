import React, { Fragment } from 'react';

import useExistingMemberSearch from './UseExistingMemberSearch';
import ExistingMemberSearchGrid from './ExistingMemberSearchGrid';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import Input from '../../../common/components/inputs/Input';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import DatePicker from '../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

import style from '../Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const ExistingMemberSearch = () => {
  const {
    showFilters,
    showResults,
    formState,
    errorState,
    onFormValueChanged,
    clearForm,
    omrPersonDupCheckState,
    onSelectMember,
    onBackButtonClicked,
    onFiltersToggle,
    handleSubmit,
    modalState,
    onModalCanceled
  } = useExistingMemberSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>Existing Member Search</Headings.H2>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <button className={[style.BackToLinkWithChevron, global.UnderLine].join('')} onClick={onBackButtonClicked} type="button">
            <LeftArrowIcon />&nbsp; &nbsp;Back to Registration Entry
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p className={style.CenterAlign}>To search for a person who is an existing member of USA swimming, enter the member's member Id or first name, last name, and birth date.  If you are unable to locate the member, click 'Back to Registration Entry' and choose the 'Create New Membership' option for the registrant.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-4">
            <Input
              label="Legal First Name"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-4">
            <Input
              label="Legal Last Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-4">
            <DatePicker
              label="Birth Date"
              name="birthDate"
              value={formState.birthDate}
              error={errorState.birthDate}
              message={errorState.birthDate}
              onChange={(value) => { onFormValueChanged('birthDate', value) }} />
          </div>
          <div className="col-xs-12 col-sm-4">
            <Input
              label="Member Id"
              name="memberId"
              value={formState.memberId}
              error={errorState.memberId}
              message={errorState.memberId}
              onChange={(value) => { onFormValueChanged('memberId', value) }} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={clearForm}>Clear Form</SecondaryButton> &nbsp;
            <PrimaryButton type="submit">Search for Member</PrimaryButton>
          </div>
        </div>
      </form>
      <div className={showResults === true ? global.DisplayComponent : global.HideComponent}>
        <ExistingMemberSearchGrid
          state={omrPersonDupCheckState}
          onSelectMember={onSelectMember}
          onFiltersToggle={onFiltersToggle}
          showFilters={showFilters === true} />
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopup}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className="col-xs-12">
            <p className='text-center'><b>Selected Member:</b>&nbsp;{modalState.selectedPerson?.firstName} {modalState.selectedPerson?.middleName} {modalState.selectedPerson?.lastName} {modalState.selectedPerson?.suffix}{modalState.selectedPerson?.birthDate ? `(Birth Date: ${formatDate(modalState.selectedPerson?.birthDate)})` : ''}</p>
            <p className='text-center'><b>Last Membership:</b>&nbsp;{`${modalState.selectedPerson?.orgUnitName || 'Non-USA Swimming Membership'} - (${modalState.selectedPerson?.orgUnitCode || 'Non-USAS'})`}{modalState.selectedPerson?.periodName ? ', ' + modalState.selectedPerson?.periodName : ''}</p>
            <p className='text-center'>Your membership cannot be processed at this time, please contact USA Swimming for more information.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className={"col-xs-12 text-center"}>
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={omrPersonDupCheckState?.isArrayLoading} />
    </Fragment>
  );
};

export default ExistingMemberSearch;