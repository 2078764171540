import Constants from "../../../common/utils/Constants";

export const localValidate = (formState) => {

  const HISPANIC_OR_LATINO_TYPE_IDS = {
    //TODO: remove hard coded values 
    iPreferNotToSay: 3
  }
  const errors = {};
  //Hispanic/Latino Ethnicity
  if (!formState.hispanicOrLatinoTypeId || formState.hispanicOrLatinoTypeId === Constants.DEFAULT_ID) {
    errors.hispanicOrLatinoTypeId = 'A selection is required';
  }

  //Race validation, if hispanic or latino selection is "I prefer not to say" or blank and ethnicity type id length is equal to 0
  if ((formState.hispanicOrLatinoTypeId !== HISPANIC_OR_LATINO_TYPE_IDS.iPreferNotToSay && formState.hispanicOrLatinoTypeId !== Constants.DEFAULT_ID)
    && formState.personEthnicity.length === 0) {
    errors.ethnicityTypeId = 'At least one option must be selected';
  }

  //No more than 4 ethnicities can be selected
  if (
    (formState.hispanicOrLatinoTypeId !== HISPANIC_OR_LATINO_TYPE_IDS.iPreferNotToSay 
      && formState.hispanicOrLatinoTypeId !== Constants.DEFAULT_ID
    )
    && formState.personEthnicity.length > 4) {
    errors.ethnicityTypeId = 'No more than four options may be selected';
  }

  return errors;
};

const EthnicityValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default EthnicityValidation;