import React, { createContext, useState } from 'react';

import LandingPageData from './LandingPageData';

export const LandingPageStateContext = createContext();

const LandingPageContextProvider = ({ children }) => {
  const stateHook = useState(LandingPageData.INITIAL_STATE);

  return (
    <LandingPageStateContext.Provider value={stateHook}>
      {children}
    </LandingPageStateContext.Provider>
  );
};

export default LandingPageContextProvider;