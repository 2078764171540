import React, { createContext, useState } from 'react';

import RenewalOptionData from './RenewalOptionData';

export const RenewalOptionStateContext = createContext();

const RenewalOptionContextProvider = ({ children }) => {
  const stateHook = useState(RenewalOptionData.INITIAL_STATE);

  return (
    <RenewalOptionStateContext.Provider value={stateHook}>
      {children}
    </RenewalOptionStateContext.Provider>
  );
};

export default RenewalOptionContextProvider;