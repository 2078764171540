import React, { Fragment } from 'react';

import LargeMemberRegistrationHistoryGrid from './LargeMemberRegistrationHistoryGrid';
import SmallMemberRegistrationHistoryGrid from './SmallMemberRegistrationHistoryGrid';

const MemberRegistrationHistoryGrid = ({ state }) => {
  return (
    <Fragment>
      <LargeMemberRegistrationHistoryGrid state={state} />
      <SmallMemberRegistrationHistoryGrid state={state} />
    </Fragment>
  )
};

export default MemberRegistrationHistoryGrid;