import { Fragment } from 'react';

import useEntry from './UseEntry';

import SummaryButton from '../../components/summaryButton/SummaryButton';

import Headings from '../../../common/components/headings/Headings';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';

import style from '../Omr.module.css';

const ChildButton = ({ onChildButtonClicked }) => {
  return (
    <div className='col-xs-12 text-center'>
      <button
        className={style.ImgButton}
        onClick={onChildButtonClicked}>
        <img src={`${import.meta.env.VITE_CDN_URL}/dolphin.jpg`} alt="child membership" width="100%" />I Am Registering My Child
      </button>
    </div>
  );
};

const MyselfButton = ({ onMyselfButtonClicked }) => {
  return (
    <div className='col-xs-12 text-center'>
      <button
        className={style.ImgButton}
        onClick={onMyselfButtonClicked}>
        <img src={`${import.meta.env.VITE_CDN_URL}/phelps.jpg`} alt="my membership" />I Am Registering Myself
      </button>
    </div>
  );
};

const Entry = () => {
  const {
    onChildButtonClicked,
    onMyselfButtonClicked,
    isLoading,
    routeCodeValueState,
    onBackToWelcomePageClicked
  } = useEntry();

  return (
    <Fragment>
      <div className="col-xs-12" id="omrHeadingId">
        <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Online Member Registration{routeCodeValueState.offeringInstanceCouponId && ' - Outreach'}</Headings.H2>
      </div>
      <div className='col-xs-12'>
        <SummaryButton />
      </div>
      <div className="col-xs-12 usas-extra-bottom-margin">
        <hr className="usas-bar-turmeric" />
        <Headings.H3 excludeBar={true} className={style.CenterAlign}>Who is the membership for?</Headings.H3>
      </div>
      <div className='row'>
        <div className="col-xs-12">
          <ChildButton onChildButtonClicked={onChildButtonClicked} />
          <MyselfButton onMyselfButtonClicked={onMyselfButtonClicked} />
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12" style={{ textAlign: 'center' }}>
          <SecondaryButton onClick={(e) => onBackToWelcomePageClicked(e)}>Back to Welcome Page</SecondaryButton>
        </div>
      </div>
      {isLoading === true && <LoadingModal />}
    </Fragment>
  )
};

export default Entry;