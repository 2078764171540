import React, { Fragment } from 'react';

import useMyFamily from './UseMyFamily';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Acknowledgements from '../../../../common/components/acknowledgements/Acknowledgements';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

const AddFamilyMemberPopup = ({ uiState, isSubmitting, formState, errorState, onFormValueChanged, onSaveFamilyMemberClicked, onAddFamilyMemberModalCanceled, onContinueClicked }) => (
  <PopUpModal
    widthPct={90}
    maxWidth={400}
    title={'Add Family Member'}
    displayPopUp={uiState?.addClicked === true && isSubmitting === false}
    onModalCanceled={onAddFamilyMemberModalCanceled} >
    <div className="row">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <Headings.H5>Add an Existing USA Swimming Member to Your Family by Their Member Id</Headings.H5>
      </div>
    </div>
    <form>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label="Member Id*"
            name="memberId"
            value={formState.memberId}
            error={errorState.memberId}
            message={errorState.memberId}
            onChange={(value) => { onFormValueChanged('memberId', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveFamilyMemberClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onAddFamilyMemberModalCanceled}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
    <hr></hr>
    <div className="row">
      <div className="col-xs-12">
        <Headings.H5>Don't Have a Member Id Because Your Family Member is Not a USA Swimming Member, or You Don't Remember It?</Headings.H5>
        <p>Use the 'Register New Member' button below to begin their registration process. We'll find their membership if they already have one, and allow you to renew.</p>
        <ActionIntraPageButton onClick={onContinueClicked}>Register New Member</ActionIntraPageButton>
      </div>
    </div>
  </PopUpModal>)

const MyFamily = () => {
  const {
    isLoading,
    isSubmitting,
    isSaving,
    uiState,
    formState,
    errorState,
    acknowledgementsModalState,
    onFormValueChanged,
    onAddFamilyMemberClicked,
    onSaveFamilyMemberClicked,
    onAddFamilyMemberModalCanceled,
    onAcknowledgmentsModalCanceled,
    onContinueClicked } = useMyFamily();

  if (isLoading === true) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <ActionIntraPageButton onClick={onAddFamilyMemberClicked}>Add Family Member</ActionIntraPageButton>&nbsp;
        <ActionIntraPageButton onClick={onContinueClicked}>Register New Member</ActionIntraPageButton>
        <AddFamilyMemberPopup uiState={uiState} formState={formState} errorState={errorState} onFormValueChanged={onFormValueChanged}
          onSaveFamilyMemberClicked={onSaveFamilyMemberClicked} onAddFamilyMemberModalCanceled={onAddFamilyMemberModalCanceled} isSubmitting={isSubmitting} onContinueClicked={onContinueClicked} />
        {acknowledgementsModalState.displayPopUp === true &&
          <PopUpModal
            widthPct={90}
            maxWidth={1400}
            title={acknowledgementsModalState.modalTitle}
            showModal={acknowledgementsModalState.displayPopUp}
            onModalCanceled={onAcknowledgmentsModalCanceled}>
            <Acknowledgements memberId={formState.newMemberId ? formState.newMemberId : formState.memberId} addANewFamilyMemberView={true} />
          </PopUpModal>}
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isSubmitting} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={isSaving}
        />
      </Fragment>
    );
  }
};

export default MyFamily;