import React, { createContext, useState } from 'react';

import OmrBackButtonData from './OmrBackButtonData';

export const OmrBackButtonStateContext = createContext();

const OmrBackButtonContextProvider = ({ children }) => {
  const stateHook = useState(OmrBackButtonData.INITIAL_STATE);

  return (
    <OmrBackButtonStateContext.Provider value={stateHook}>
      {children}
    </OmrBackButtonStateContext.Provider>
  );
};

export default OmrBackButtonContextProvider;