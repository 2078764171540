import React, { Fragment } from 'react';
import { formatPhoneNumber } from '../../../common/utils/validation';

import global from '../../../common/components/GlobalStyle.module.css';

const DeleteContactModalInfo = ({ modalState }) => {
  return (
    <Fragment>
      <p className={global.Underline}>Contact Info:</p>
      <p><strong>Contact Name: &nbsp;</strong>{modalState?.firstName} {modalState?.lastName} </p>
      <p><strong>Contact Relationship to Member: &nbsp;</strong>{modalState?.contactRelationshipName}</p>
      <p><strong>Contact Email Address: &nbsp;</strong>{modalState?.emailAddress}</p>
      <p><strong>Contact Phone Number: &nbsp;</strong>{formatPhoneNumber(modalState?.phone)}</p>
    </Fragment>
  );
};

export default DeleteContactModalInfo;