import { useContext } from 'react';
import { AddressBookStateContext } from './AddressBookContextProvider';

const UseAddressBookData = () => {
  const [addressBookState, setAddressBookState] = useContext(AddressBookStateContext);

  return {
    addressBookState, 
    setAddressBookState
  };
};

export default UseAddressBookData;