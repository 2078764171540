import React, { Fragment } from 'react';

import useViewTemplate from './UseViewTemplate';

import LeftNavigation from '../leftNavigation/LeftNavigation';
// import SummaryButton from '../summaryButton/SummaryButton';

import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import Headings from '../../../common/components/headings/Headings';

import style from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';
import ActionButton from '../../../common/components/buttons/ActionButton';

const ABOUT_MEMBER_CONSTANTS = {
  newMember: 'ABOUT_MEMBER_NEW_MEMBER',
  existingMember: 'ABOUT_MEMBER_EXISTING_MEMBER'
}

const Buttons = ({
    isMobile,
    viewName,
    additionalActionButtonLabel,
    onAdditionalActionButtonClicked,
    NavigateToEntryView,
    handleBackButtonClicked,
    onNextButtonClicked,
    nextButtonText}) => {
  const rowClass = isMobile === true ? 'row usas-extra-top-margin visible-xs' : 'row usas-extra-top-margin pull-right hidden-xs';

  return (
    <div className={rowClass}>
      <div className='col-xs-12'>
        {viewName === ABOUT_MEMBER_CONSTANTS.newMember || viewName === ABOUT_MEMBER_CONSTANTS.existingMember
          ? <SecondaryButton onClick={NavigateToEntryView}>Back</SecondaryButton>
          : <SecondaryButton onClick={handleBackButtonClicked}>Back</SecondaryButton>} &nbsp;
        {additionalActionButtonLabel && <Fragment><ActionButton className={style.ActionButton} onClick={onAdditionalActionButtonClicked}>{additionalActionButtonLabel}</ActionButton> &nbsp;</Fragment>}
        {onNextButtonClicked
          ? <PrimaryButton onClick={onNextButtonClicked}>{nextButtonText}</PrimaryButton>
          : <PrimaryButton type="submit">{nextButtonText}</PrimaryButton>} &nbsp;
      </div>
    </div>
  );
};

const ViewTemplate = ({ 
    viewName, 
    errorMessage, 
    onBackButtonClicked, 
    onNextButtonClicked,
    additionalActionButtonLabel,
    onAdditionalActionButtonClicked, 
    children }) => {
  const {
    subHeaderText,
    subSubHeaderText,
    instructionText,
    mobileInstructionText,
    nextButtonText,
    handleBackButtonClicked,
    NavigateToEntryView,
    routeCodeValueState
  } = useViewTemplate(viewName, onBackButtonClicked);

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12" id="omrHeadingId">
          <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Online Member Registration{routeCodeValueState.offeringInstanceCouponId && ' - Outreach'}</Headings.H2>
        </div>
        {/* <div className='col-xs-12'>
          <SummaryButton />
        </div> */}
        <div className='col-xs-12'>
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <LeftNavigation viewName={viewName}>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            <Headings.H3 excludeBar={true}>{subHeaderText}</Headings.H3>
            {subSubHeaderText && <Headings.H6>{subSubHeaderText}</Headings.H6>}
          </div>
        </div>
        {instructionText && (
          <div className="row usas-row-extra-bottom-margin">
            {mobileInstructionText 
            ? (
              <Fragment>
                <div className='col-xs-12 hidden-xs hidden-sm hidden-md'
                  dangerouslySetInnerHTML={{__html: `<p>${instructionText}</p>`}} />
                <div className='col-xs-12 visible-xs visible-sm visible-md'
                  dangerouslySetInnerHTML={{__html: `<p>${mobileInstructionText}</p>`}} />
              </Fragment>
            ) : (
            <div className='col-xs-12'
              dangerouslySetInnerHTML={{__html: `<p>${instructionText}</p>`}} />
            )}
          </div>
        )}
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {children}
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            {errorMessage && <p className={global.ErrorMessage}>{errorMessage}</p>}
          </div>
        </div>
        <div className='row usas-extra-top-margin'></div>
        <div className='row usas-extra-top-margin'></div>
        <Buttons
          isMobile={false}
          viewName={viewName}
          additionalActionButtonLabel={additionalActionButtonLabel}
          onAdditionalActionButtonClicked={onAdditionalActionButtonClicked}
          NavigateToEntryView={NavigateToEntryView}
          handleBackButtonClicked={handleBackButtonClicked}
          onNextButtonClicked={onNextButtonClicked}
          nextButtonText={nextButtonText} />
        <Buttons
          isMobile={true}
          viewName={viewName}
          additionalActionButtonLabel={additionalActionButtonLabel}
          onAdditionalActionButtonClicked={onAdditionalActionButtonClicked}
          NavigateToEntryView={NavigateToEntryView}
          handleBackButtonClicked={handleBackButtonClicked}
          onNextButtonClicked={onNextButtonClicked}
          nextButtonText={nextButtonText} />
        {/* <div className='row usas-extra-top-margin pull-right hidden-xs'>
          <div className='col-xs-12'>
            {viewName === ABOUT_MEMBER_CONSTANTS.newMember || viewName === ABOUT_MEMBER_CONSTANTS.existingMember
              ? <SecondaryButton onClick={NavigateToEntryView}>Back</SecondaryButton>
              : <SecondaryButton onClick={handleBackButtonClicked}>Back</SecondaryButton>} &nbsp;
            {onNextButtonClicked
              ? <PrimaryButton onClick={onNextButtonClicked}>{nextButtonText}</PrimaryButton>
              : <PrimaryButton type="submit">{nextButtonText}</PrimaryButton>} &nbsp;
          </div>
        </div>
        <div className='row usas-extra-top-margin visible-xs'>
          <div className='col-xs-12'>
            {viewName === ABOUT_MEMBER_CONSTANTS.newMember || viewName === ABOUT_MEMBER_CONSTANTS.existingMember
              ? <SecondaryButton onClick={NavigateToEntryView}>Back</SecondaryButton>
              : <SecondaryButton onClick={handleBackButtonClicked}>Back</SecondaryButton>} &nbsp;
            {onNextButtonClicked
              ? <PrimaryButton onClick={onNextButtonClicked}>{nextButtonText}</PrimaryButton>
              : <PrimaryButton type="submit">{nextButtonText}</PrimaryButton>} &nbsp;
          </div>
        </div> */}
      </LeftNavigation>
    </Fragment>
  );
};

export default ViewTemplate;