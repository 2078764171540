import React from 'react';

import useExistingMemberSearchSelf from './UseExistingMemberSearchSelf';

import ExistingMemberSearch from './ExistingMemberSearch';

const ExistingMemberSearchSelf = () => {
  useExistingMemberSearchSelf();

  return (
    <ExistingMemberSearch />
  );
};

export default ExistingMemberSearchSelf;