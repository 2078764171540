import React, { Fragment } from 'react';

import EditIcon from '../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import { formatPhoneNumber } from '../../../common/utils/validation';

import global from '../../../common/components/GlobalStyle.module.css';

const LargeAdditionalContactsGrid = ({ state, onEdit, onDeleteClicked, onNavigateToEmergencyContact }) => (
    <Fragment>
        <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
            <thead>
                <tr>
                    <th>Contact Name</th>
                    <th>Relationship</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {(Array.isArray(state) === false
                    || state.length === 0)
                    ? (
                        <tr>
                            <td colSpan="6">Click 'Add Contact' to add additional contacts</td>
                        </tr>)
                    : state.map((contact, i) => (
                        <tr key={i}>
                            <td>{contact?.firstName || ''} {contact?.lastName || ''}</td>
                            <td>{contact?.contactRelationshipName || ''}</td>
                            <td>{contact?.emailAddress || ''}</td>
                            <td>{formatPhoneNumber(contact?.phoneNumber) || ''}</td>
                            <td>
                                {contact.isPrimaryContact === true
                                    ? <button className={global.IconButtonMargin} type="button" onClick={(e) => onNavigateToEmergencyContact(e, i)}> <EditIcon /> </button>
                                    : <Fragment>
                                        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, i)}> <EditIcon /> </button>
                                        <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, i)}><DeleteIcon /></button>
                                    </Fragment>
                                }
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </Fragment>
);

export default LargeAdditionalContactsGrid;