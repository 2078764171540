import { useEffect } from 'react';
import useOmrData from '../../state/omr/UseOmrData';
import Constants from '../../../common/utils/Constants';

const useAdditionalContactsGrid = () => {
  const { personState } = useOmrData();

  const personContactsAdd = () => {
    if (personState?.person?.personContacts.some(x => x.isPrimaryContact)) {
      const primaryIndex = personState?.person?.personContacts.findIndex(x => x.isPrimaryContact === true);
      if (primaryIndex > Constants.DEFAULT_ID) {
        personState?.person.personContacts.splice(primaryIndex, 1);
      }
      personState?.person.personContacts.push(personState?.person?.primaryContactInfo);
    } else {
      personState?.person.personContacts.push(personState?.person?.primaryContactInfo);
    }
  }

  useEffect(() => {
    if (Object.keys(personState?.person?.primaryContactInfo).length > 0) {
      personContactsAdd()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { personState };
};

export default useAdditionalContactsGrid;