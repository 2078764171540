import global from '../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../utils/DateFunctions';

const GridRow = ({ membership }) => {
  return (
    <tr key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
      <td>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : ''}</td>
      <td>{membership.competitionGenderTypeName || ''}</td>
      <td>{membership.birthDate ? formatDate(membership.birthDate) : ''}</td>
      <td>{membership.renewalOrgUnitName || ''} {membership.renewalOrgUnitName === 'Unattached' ? ' - ' + membership.renewalParentOrgUnitName : ''} {membership.renewalParentOrgUnitCode ? '(' + membership.renewalParentOrgUnitCode + ')' : ''}</td>
      <td>{membership.renewalOfferingName || ''}</td>
      <td>{membership.renewalExpirationDate ? `${formatDate(membership.renewalExpirationDate)}` : ''}</td>
    </tr>
  );
};

const RenewMembershipsSuccessLargeGrid = ({ gridData, isLoading, onCheckboxChange, onYesNoSwitchChange, allowRelayOnly }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        <th>Birth Date</th>
        <th>Team Name</th>
        <th>Renewed Membership</th>
        <th>Expires</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"6"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((membership, i) =>
            <GridRow
              key={i}
              membership={membership}
              onCheckboxChange={onCheckboxChange}
              onYesNoSwitchChange={onYesNoSwitchChange}
              allowRelayOnly={allowRelayOnly} />)
          :
          <tr>
            <td colSpan={"6"}>No Memberships were Renewed</td>
          </tr>
      }
    </tbody>
  </table>
);

export default RenewMembershipsSuccessLargeGrid;