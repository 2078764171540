import React, { Fragment } from 'react';

import useGenderCompetitionCategoryForm from './UseGenderCompetitionCategoryForm';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Headings from '../../../common/components/headings/Headings';
import CompetitionCategoryRadiobuttonList from '../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import ReadOnly from '../../../common/components/readOnly/ReadOnly';
import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const GenderCompetitionCategoryForm = ({
	personState,
	formState,
	errorState,
	setFormData,
	handleSubmit,
	onFormValueChanged,
	isCompetitionCategoryLocked
}) => {
	const {
		competitionGenderState
	} = useGenderCompetitionCategoryForm(personState, formState, setFormData);

	return (
		<Fragment>
			<form onSubmit={handleSubmit}>
				<div className="col-xs-12 col-sm-6 usas-extra-bottom-margin">
					<span className={isCompetitionCategoryLocked === false ? global.DisplayComponent : global.HideComponent}>
						<CompetitionCategoryRadiobuttonList
							label={<Headings.H6>Select Competition Category</Headings.H6>}
							isVertical={true}
							name="competitionGenderTypeId"
							value={Number(formState.competitionGenderTypeId)}
							error={errorState.competitionGenderTypeId}
							message={errorState.competitionGenderTypeId}
							onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)) }} />
					</span>
					<span className={isCompetitionCategoryLocked === true ? global.DisplayComponent : global.HideComponent}>
						<ReadOnly
							label="Competition Category"
							value={formState.competitionGenderTypeName}
							name='competitionGenderTypeName' />
					</span>
				</div>
			</form>
			<PopUpModal
				widthPct={50}
				maxWidth={250}
				title={Constants.LOADING_MSG}
				displayPopUp={competitionGenderState?.isArrayLoading} />
		</Fragment>);
}

export default GenderCompetitionCategoryForm;