import RadiobuttonList from './RadiobuttonList';

import useTrueFalseRadioButtonList from './UseTrueFalseRadioButtonList';

const TrueFalseRadioButtonList = ({trueLabel, falseLabel, label, name, value, error, onChange, isVertical = false}) => {
  const { items, onChangeWithBool } = useTrueFalseRadioButtonList(trueLabel, falseLabel, onChange);

  return (
    <RadiobuttonList
      isVertical={isVertical === true}
      label={label}
      name={name}
      value={value}
      items={items}
      error={error}
      message={error}
      onChange={onChangeWithBool} 
    />
  );
};

export default TrueFalseRadioButtonList;