import { useState, useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';
import useOmrData from '../../state/omr/UseOmrData';

import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  finalSummaryModalState: {
    modalTitle: 'Navigate To Summary Page',
    displayPopUp: false,
    firstName: '',
    lastName: '',
    birthDate: ''
  },
  isPersonReset: false
}

const useSummaryButton = (onNavigateToFinalSummary) => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const { omrState, personState, saveOmrState } = useOmrData();

  const handleSummaryClicked = (e) => {
    if (onNavigateToFinalSummary instanceof Function) {
      onNavigateToFinalSummary(e);
    } else {
      if (e?.preventDefault) {
        e.preventDefault();
      }

      deleteUnFinishedPersonInfo();
    }
  };

  const deleteUnFinishedPersonInfo = () => {
    const personInfo = personState.person;
    const personIndex = omrState.currentIndex;
    const newOmrState = JSON.parse(JSON.stringify(omrState));
    if (personInfo.isPersonObjCompleted !== true) {
      if (personIndex > Constants.DEFAULT_ID) {
        newOmrState.persons.splice(personIndex, 1);
        newOmrState.currentIndex = newOmrState.currentIndex - 1
      }

      saveOmrState(newOmrState, false);
    };

    setState({
      ...state,
      isPersonReset: true,
      finalSummaryModalState: {
        ...state.finalSummaryModalState,
        displayPopUp: false
      },
    });
  };


  const onNavigateToSummaryClicked = (e, index) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const contact = personState.person;
    setState({
      ...state,
      finalSummaryModalState: {
        ...state.finalSummaryModalState,
        displayPopUp: true,
        itemIndex: index,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        birthDate: contact?.birthDate
      }
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    setState({
      ...state,
      finalSummaryModalState: {
        ...state.finalSummaryModalState,
        displayPopUp: false,
        firstName: '',
        lastName: '',
        birthDate: ''
      }
    });
  };

  useEffect(() => {
    if (state?.isPersonReset === true && omrState?.persons?.length > 0) {
      if (omrState?.persons?.find(x => x.person.isPersonObjCompleted === true)) {

        navigate(NavLinks.ALL_SUMMARY);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrState?.persons?.length, state?.isPersonReset]);

  return {
    finalSummaryModalState: state.finalSummaryModalState,
    handleSummaryClicked,
    onModalCanceled,
    onNavigateToSummaryClicked,
  };
};

export default useSummaryButton;