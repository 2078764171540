import React, { Fragment } from 'react';

import SelectMemberLargeGrid from './SelectMemberLargeGrid';
import SelectMemberSmallGrid from './SelectMemberSmallGrid';

const SelectMemberGrid = ({ gridData, isLoading, onSelectClicked }) => {
  return (
    <Fragment>
      <SelectMemberLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onSelectClicked={onSelectClicked}
      />
      <SelectMemberSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onSelectClicked={onSelectClicked}
      />
    </Fragment>
  )
};

export default SelectMemberGrid;