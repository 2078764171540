import { useEffect } from 'react';

import useAddressTypeData from '../common/state/addressType/UseAddressTypeData';
import useContactRelationshipData from '../common/state/contactRelationshipType/UseContactRelationshipTypesData';
import usePhoneTypeData from '../common/state/phoneType/UsePhoneTypeData';
import useSecurityData from '../common/state/security/UseSecurityData';
import useTempPersonData from './state/tempPersonData/UseTempPersonData';

const useRoutes = () => {
  const { securityState } = useSecurityData();
  const { contactRelationshipTypeState, getContactRelationshipTypes } = useContactRelationshipData();
  const { addressTypeState, getAddressTypes } = useAddressTypeData();
  const { phoneTypeState, getPhoneTypes } = usePhoneTypeData();
  //TODO: Remove temp person state once we get guid pattern and security solidified
  const { getPersonByMemberId } = useTempPersonData();

  useEffect(() => {
    if (contactRelationshipTypeState.isArrayLoaded !== true
      && contactRelationshipTypeState.isArrayLoading !== true) {
      getContactRelationshipTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRelationshipTypeState]);

  useEffect(() => {
    if (phoneTypeState.isArrayLoaded !== true
      && phoneTypeState.isArrayLoading !== true) {
      getPhoneTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneTypeState]);

  useEffect(() => {
    if (addressTypeState.isArrayLoaded !== true
      && addressTypeState.isArrayLoading !== true) {
      getAddressTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressTypeState]);

  useEffect(() => {
    if (securityState.isObjLoaded === true && securityState?.userInfo?.memberId) {
      const tempMemberId = securityState?.userInfo?.memberId;
      getPersonByMemberId(tempMemberId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);


  return {};
};

export default useRoutes;
