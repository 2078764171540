import { useState, useEffect } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';
import getLeftNavigationState from './GetLeftNavigationState';

import useOmrData from '../../state/omr/UseOmrData';
import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useSecurityData from '../../../common/state/security/UseSecurityData';

const INITIAL_STATE = { items: [], personName: '' };

const useLeftNavigation = (viewName) => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const { personState } = useOmrData();
  const { securityState } = useSecurityData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();

  const getPersonName = () => {
    if (personState?.person?.firstName) {
      return `${personState?.person?.firstName}`
        + `${personState?.person?.middleName ? ' ' + personState?.person.middleName : ''}`
        + `${personState?.person?.lastName ? ' ' + personState?.person.lastName : ''}`
        + `${personState?.person?.suffix ? ' ' + personState?.person.suffix : ''}`;
    } else {
      return null;
    }
  };

  const onNavigateToPath = (e, path) => {
    if (e?.preventDefault) {
      e.preventDefault();
    };
    document.getElementById('omrHeadingId')?.scrollIntoView();
    setLeftNavModalState({
      ...leftNavModalState,
      displayPopUp: false,
      formStateSaved: true,
      path: ''
    });

    navigate(path);
  };

  useEffect(() => {
    const items = getLeftNavigationState(state, viewName, personState, securityState);
    const personName = getPersonName();

    setState({
      ...state,
      items,
      personName
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, viewName, securityState]);

  return {
    ...state,
    onNavigateToPath
  };
};

export default useLeftNavigation;