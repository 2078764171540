import React, { createContext, useState } from 'react';

import PersonParentChildData from './PersonParentChildData';

export const PersonParentChildStateContext = createContext();

const PersonParentChildContextProvider = ({ children }) => {
  const stateHook = useState(PersonParentChildData.INITIAL_STATE);

  return (
    <PersonParentChildStateContext.Provider value={stateHook}>
      {children}
    </PersonParentChildStateContext.Provider>
  );
};

export default PersonParentChildContextProvider;