import React, { Fragment } from 'react';
import Headings from '../../../common/components/headings/Headings';
import EditIcon from '../../../common/components/icons/EditIcon';
import OmrMemberSummaryReadOnly from '../../components/input/OmrMemberSummaryReadOnly';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';
import Views from '../../Views';
import MemberSummaryMembershipGrid from './summaryGrids/MemberSummaryMembershipGrid';
import useSummary from './UseSummary';

import style from '../Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';

const Summary = () => {
  const {
    personState,
    formattedStrings,
    membershipArray,
    savingMessage,
    errorMessage,
    isSaving,
    onNavigateToAboutMember,
    onNavigateToMemberContact,
    onNavigateToEmergencyContact,
    onNavigateToMembership,
    onCheckOutClicked,
    onAddAnotherButtonClicked,
    onCloseError } = useSummary();

  if (savingMessage === 'Saved') {
    return <Fragment>Save Complete</Fragment>;
  }

  return (
    <ViewTemplate
      viewName={Views.MEMBER_SUMMARY}
      onNextButtonClicked={onCheckOutClicked}
      additionalActionButtonLabel="Add Another Member"
      onAdditionalActionButtonClicked={onAddAnotherButtonClicked}>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <Headings.H6 className={global.Underline}>{personState?.person?.hasAthleteOffering === true ? "Athlete" : "Non Athlete"}
            <span>
              <button className={global.IconButtonMargin} type="button" onClick={onNavigateToAboutMember}> <EditIcon /></button>
            </span>
          </Headings.H6>
        </div>
        {personState?.person?.memberId && (
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <OmrMemberSummaryReadOnly
              label="Member Id"
              name="memberId"
              value={personState?.person?.memberId} />
          </div>
        )}
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="Full Name"
            name="fullName"
            value={`${personState?.person?.firstName || ''} ${personState?.person?.middleName || ''} ${personState?.person?.lastName || ''} ${personState?.person?.suffix || ''}`} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="DOB"
            name="dob"
            value={personState?.person?.birthDate} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="US Citizen"
            name="citizen"
            value={personState?.person?.isUsCitizen === true ? "True" : "False"} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            className={style.SmallLineHeight}
            label="Ethnicity"
            name="ethnicity"
            value={formattedStrings.ethnicityInfo} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <span className={personState?.person?.competitionGenderTypeId > 0 ? global.DisplayComponent : global.HideComponent}>
            <OmrMemberSummaryReadOnly
              label="Competition Category"
              name="competitionGender"
              value={formattedStrings.competitionGender} />
          </span>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <span className={personState?.person?.genderIdentityTypeId > 0 ? global.DisplayComponent : global.HideComponent}>
            <OmrMemberSummaryReadOnly
              label="Gender Identity"
              name="genderIdentity"
              value={formattedStrings.genderIdentity} />
          </span>
        </div>
      </div>
      <div className='row'>
        <span className={personState?.is16orOlder === false ? global.HideComponent : global.DisplayComponent}>
          <div className='col-xs-12'>
            <Headings.H6 className={global.Underline}>Member Contact
              <span>
                <button className={global.IconButtonMargin} type="button" onClick={onNavigateToMemberContact}> <EditIcon /> </button>
              </span>
            </Headings.H6>
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <OmrMemberSummaryReadOnly
              label="Email"
              name="memberEmail"
              value={personState?.person?.contactInfo?.emailAddress} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-3'>
            <OmrMemberSummaryReadOnly
              label="Phone"
              name="memberPhone"
              value={formattedStrings.memberPhone} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <OmrMemberSummaryReadOnly
              label="Address"
              name="memberAddress"
              value={personState?.person?.contactInfo?.address?.address1 + ' ' + personState?.person?.contactInfo?.address?.address2 + ' ' + personState?.person?.contactInfo?.address?.city + ' ' + personState?.person?.contactInfo?.address?.stateCode + ' ' + personState?.person?.contactInfo?.address?.postalCode} />
          </div>
        </span>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <Headings.H6 className={global.Underline}>Emergency Contact
            <span>
              <button className={global.IconButtonMargin} type="button" onClick={onNavigateToEmergencyContact}> <EditIcon /> </button>
            </span>
          </Headings.H6>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="Full Name"
            name="emergencyContactFullName"
            value={personState?.person?.primaryContactInfo?.firstName + ' ' + personState?.person?.primaryContactInfo?.lastName} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="Email"
            name="emergencyContactEmail"
            value={personState?.person?.primaryContactInfo?.emailAddress} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="Phone"
            name="emergencyContactPhone"
            value={formattedStrings.emergencyPhone} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-3'>
          <OmrMemberSummaryReadOnly
            label="Address"
            name="emergencycontactAddress"
            value={personState?.person?.primaryContactInfo?.address?.address1 + ' ' + personState?.person?.primaryContactInfo?.address?.address2 + ' ' + personState?.person?.primaryContactInfo?.address?.city + ' ' + personState?.person?.primaryContactInfo?.address?.stateCode + ' ' + personState?.person?.primaryContactInfo?.address?.postalCode} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <Headings.H6 className={global.Underline}>Membership
            <span>
              <button className={global.IconButtonMargin} type="button" onClick={(e) => onNavigateToMembership(e)}> <EditIcon /> </button>
            </span>
          </Headings.H6>
        </div>
        <div className='col-xs-12'>
          <MemberSummaryMembershipGrid state={membershipArray} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving === true} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={errorMessage}
        displayPopUp={errorMessage ? true : false}
        onModalCanceled={onCloseError} />
    </ViewTemplate>
  );
};

export default Summary;