import Constants from '../../../common/utils/Constants';
import NavLinks from '../../NavLinks';
import Views from '../../Views';
import getInitialState from './GetInitialState';

const getEthnicity = (viewName, personState, securityState) => {
  const person = personState?.person || {};

  const path = personState?.is16orOlder === true
    ? NavLinks.ETHNICITY_16_AND_UP
    : NavLinks.ETHNICITY_U16;

  const isIneligible = (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
    personState?.isMembershipForLoggedInUser === false) ? false : true;

  const isCurrent = viewName === Views.ETHNICITY;
  const isComplete = isIneligible ? false : person?.hispanicOrLatinoTypeId > 0;
  const item = getInitialState(Views.ETHNICITY);

  const isAboutMemberComplete = person?.firstName && person?.lastName
    && person?.birthDate && person?.birthDate !== Constants.BLANK_DATE_STRING
    && (person?.isUsCitizen === true || person?.isUsCitizen === false) && isIneligible === false ? true : false;

  const isMemberContactComplete = personState?.is16orOlder
    ? (person?.contactInfo?.emailAddress
      && person?.contactInfo?.phoneNumber
      && person?.contactInfo?.address?.address1
      && person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    )
    : (person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    );

  const isMembershipComplete =
    person?.offeringInstanceIds?.length > 0;

  const arePrerequisitesDone = isIneligible === false && isAboutMemberComplete === true && isMemberContactComplete === true
    && isMembershipComplete === true;

  return {
    ...item,
    path,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getEthnicity;