import { useEffect } from 'react';

import useRenewalOptionData from '../../../state/renewalOption/UseRenewalOptionData';

const useRenewalOptionDropdown = (personId, offeringInstanceId, orgUnitId) => {
  const { renewalOptionState, getRenewalOptions } = useRenewalOptionData();

  useEffect(() => {
    if (renewalOptionState.isArrayLoading !== true && (renewalOptionState.isArrayLoaded !== true
      || renewalOptionState.orgUnitId !== orgUnitId || renewalOptionState.offeringInstanceId !== offeringInstanceId
      || renewalOptionState.personId !== personId)) {
      getRenewalOptions(personId, offeringInstanceId, orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewalOptionState, personId, offeringInstanceId, orgUnitId]);

  return {
    renewalOptionState
  };
};

export default useRenewalOptionDropdown;