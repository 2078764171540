import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCrossUINavigation from '../../../common/state/crossUINavigation/UseCrossUINavigation';
import useOfferingTypeData from '../../../common/state/offeringTypes/UseOfferingTypeData';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';
import useGlobalRoutesData from '../../../common/state/security/UseGlobalRoutesData';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';
import NavLinks from '../../NavLinks';
import useOmrData from '../../state/omr/UseOmrData';
// import usePersonAcknowledgementData from '../../state/personAcknowledgement/UsePersonAcknowledgementData';
import useFormattedStrings from './UseFormattedStrings';

const STEPS = {
  SavingRegistration: 'SavingRegistration',
  // SavingAcknowledgements: 'SavingAcknowledgements',
  Navigating: 'Navigating'
};

const DESTINATIONS = {
  registerAnother: 'registerAnother',
  checkOut: 'checkOut'
};

const useSummary = () => {
  const navigate = useNavigate();
  const { navigateToLandingPage } = useCrossUINavigation();
  const { bustSecurityCache } = useSecurityData();
  const { personState, postDataState, postOmrData, resetForPost } = useOmrData();
  const { routeCodeValueState } = useRouteCodeValueData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { offeringTypeState } = useOfferingTypeData();
  const formattedStrings = useFormattedStrings();
  // const { postPersonAcknowledgements } = usePersonAcknowledgementData();
  const [membershipArray, setMembershipArray] = useState([]);
  const [messages, setMessages] = useState({ savingMessage: '', errorMessage: '' });
  const submissionRef = useRef({ destination: '', step: '' });
  const timeoutRef = useRef(undefined);

  const showTimeout = () => {
    setMessages({
      savingMessage: '',
      errorMessage: 'Unfortunately, the system is busy right now and your registration failed to save.  Please wait a minute and click the button again.'
    });
  };

  const doNavigation = () => {
    bustSecurityCache();
    setTimeout(() => {
      //give bustSecurityCache time to execute
      if (submissionRef.current.destination === DESTINATIONS.registerAnother) {
        if (routeCodeValueState.objData?.routeCodeValue) {
          document.getElementById('omrHeadingId')?.scrollIntoView();
          const href = `${window.location.origin}${NavLinks.WELCOME}/${routeCodeValueState.objData.routeCodeValue}`;
          window.location.href = href;
        } else {
          navigateToLandingPage();
        }
      } else {
        const grandTotal = 10;
        if (grandTotal > 0) {
          const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

          if (paymentsRoute && paymentsRoute.route) {
            navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
          }
        } else {
          navigateToLandingPage();
        }
      }
    }, 100);
  };

  const onEditIconClicked = (e, route) => {
    e?.preventDefault();
    navigate(route);
  };

  const onClick = (e, destination) => {
    e?.preventDefault();

    submissionRef.current = { destination, step: STEPS.SavingRegistration };
    postOmrData();
    //CYA - in case of a long running POST
    timeoutRef.current = setTimeout(showTimeout, 25 * 1000);
  };

  const getMembershipAray = (personState, offeringTypeState) => {
    const allMembershipsArray = [];
    if (offeringTypeState.isArrayLoaded === true) {
      personState.person.offeringInstanceIds.forEach(id => {
        const membership = personState.offeringInstances.find(i => i.offeringInstanceId === id)
        allMembershipsArray.push({
          offeringInstanceId: membership.offeringInstanceId,
          membershipName: membership.offeringName,
          expirationDate: membership.expirationDate,
          details: membership.offeringDescription
        });
      });
    }

    return allMembershipsArray;
  };

  // const saveAcknowledgements = () => {
  //   submissionRef.current = {...submissionRef.current, step: STEPS.saveAcknowledgements};
  //   if (Array.isArray(postDataState.newArrayData?.personRegistration) && postDataState.newArrayData.personRegistration.length > 0) {
  //     const esignature = personState.person?.esignature;
  //     const memberId = postDataState.newArrayData.personRegistration[0].memberId;
  //     if (esignature && memberId && Array.isArray(personState.person.personAcknowledgement) && personState.person.personAcknowledgement.length > 0) {
  //       const acknowledgements = personState.person.personAcknowledgement.filter(x => x.isAcknowledged === true)?.map(x => {
  //         return { acknowledgementFormId: x.acknowledgementFormId, esignature };
  //       });

  //       if (Array.isArray(acknowledgements) && acknowledgements.length > 0) {
  //         postPersonAcknowledgements(memberId, acknowledgements);
  //         setTimeout(doNavigation, 100);
  //       }
  //     }
  //   }
  // };

  const onCloseError = (e) => {
    e?.preventDefault();

    setMessages({ savingMessage: '', errorMessage: '' });
    submissionRef.current = { ...submissionRef.current, step: '' };
  };

  useEffect(() => {
    if ((personState?.person?.offeringInstanceIds || []).length > 0
      && (personState?.offeringInstances || []).length > 0
      && offeringTypeState.isArrayLoaded === true) {
      const newMembershipArray = getMembershipAray(personState, offeringTypeState);
      setMembershipArray(newMembershipArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, offeringTypeState]);

  useEffect(() => {
    if (submissionRef.current.step === STEPS.SavingRegistration) {
      if (postDataState.isSaved === true && postDataState.objData.omrRegistrationSuccess === true) {
        submissionRef.current = { ...submissionRef.current, step: STEPS.Navigating };
        setMessages({ savingMessage: 'Saved', errorMessage: '' });
        doNavigation();
      } else {
        if (postDataState.message && postDataState.message !== 'Saving...') {
          clearTimeout(timeoutRef.current);
          //show an error message
          setMessages({ savingMessage: '', errorMessage: postDataState.message });
          resetForPost();
        } else {
          setMessages({ savingMessage: 'Saving...', errorMessage: '' });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDataState]);

  return {
    personState,
    formattedStrings,
    membershipArray,
    ...messages,
    isSaving: submissionRef.current.step ? true : false,
    onNavigateToAboutMember: (e) => onEditIconClicked(e, NavLinks.ABOUT_MEMBER),
    onNavigateToMemberContact: (e) => onEditIconClicked(e,
      personState.isMembershipForLoggedInUser === true ? NavLinks.REGISTRANT_CONTACT_INFO_SELF : NavLinks.REGISTRANT_CONTACT_INFO_OTHER),
    onNavigateToEmergencyContact: (e) => onEditIconClicked(e,
      personState.is16orOlder !== true ? NavLinks.PRIMARY_CONTACT_INFO_U16
        : personState.isMembershipForLoggedInUser === true ? NavLinks.PRIMARY_CONTACT_INFO_O15_SELF : NavLinks.PRIMARY_CONTACT_INFO_O15_OTHER),
    onNavigateToMembership: (e) => onEditIconClicked(e,
      personState.is16orOlder === true ? NavLinks.MEMBERSHIP_16_AND_UP : NavLinks.MEMBERSHIP_U16),
    onCheckOutClicked: (e) => onClick(e, DESTINATIONS.checkOut),
    onAddAnotherButtonClicked: (e) => onClick(e, DESTINATIONS.registerAnother),
    onCloseError
  };
};

export default useSummary;