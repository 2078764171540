import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from '../RegistrantContactInfoValidation';
import NavLinks from '../../../NavLinks';
import useOmrData from '../../../state/omr/UseOmrData';
import useTempPersonData from '../../../state/tempPersonData/UseTempPersonData';

import usePhoneTypeData from '../../../../common/state/phoneType/UsePhoneTypeData';
import useLeftNavModalData from '../../../../common/state/leftNavModal/UseLeftNavModalData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import useDidMountEffect from '../../../../common/utils/UseDidMountEffect';

const INITIAL_FORM_STATE = {
  email: '',
  phoneNumber: '',
  phoneTypeId: Constants.DEFAULT_ID,
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  stateName: '',
  postalCode: '',
  validatedAddress: null,
  showedAddressSuggestion: false
};

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useRegistrantContactInfoSelf = () => {
  const navigate = useNavigate();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { phoneTypeState } = usePhoneTypeData();
  const { omrTempPersonState } = useTempPersonData();
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    handleSubmit,
    updateFormState
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  function submitFormCallback(formState) {
    const newContactInfo = {
      emailAddress: formState.email,
      phoneNumber: formState.phoneNumber,
      phoneTypeId: formState.phoneTypeId,
      address: {
        address1: formState.address1,
        address2: formState.address2,
        city: formState.city,
        stateCode: formState.stateCode,
        stateName: formState.stateName,
        postalCode: formState.postalCode,
        countryCode: formState.countryCode || 'USA'
      }
    };

    savePersonState({
      ...personState,
      person: {
        ...personState.person,
        contactInfo: newContactInfo
      }
    });
  };

  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person?.contactInfo?.email !== '' && personState.person?.contactInfo?.phoneNumber !== '') {
        let formStateCopy = [];
        if (formState.email !== '' && isSaved === false) {
          formStateCopy = JSON.parse(JSON.stringify(formState));
          if ((formStateCopy.email !== personState.person?.contactInfo?.emailAddress) ||
            (formStateCopy.phoneNumber !== personState.person?.contactInfo?.phoneNumber) ||
            (formStateCopy.phoneTypeId !== personState.person?.contactInfo?.phoneTypeId) ||
            (formStateCopy.address1 !== personState.person?.contactInfo?.address?.address1) ||
            (formStateCopy.address2 !== personState.person?.contactInfo?.address?.address2) ||
            (formStateCopy.city !== personState.person?.contactInfo?.address?.city) ||
            (formStateCopy.stateCode !== personState.person?.contactInfo?.address?.stateCode) ||
            (formStateCopy.stateName !== personState.person?.contactInfo?.address?.stateName) ||
            (formStateCopy.postalCode !== personState.person?.contactInfo?.address?.postalCode)) {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    let formStateCopy = [];
    if (isSaved === false
      && (personState.person?.contactInfo.emailAddress === '' && personState.person?.contactInfo.phoneNumber === '')) {
      formStateCopy = JSON.parse(JSON.stringify(formState));
      if ((formStateCopy.email === '') ||
        (formStateCopy.phoneNumber === '') ||
        (formStateCopy.phoneTypeId === Constants.DEFAULT_ID) ||
        (formStateCopy.address1 === '') ||
        (formStateCopy.address2 === '') ||
        (formStateCopy.city === '') ||
        (formStateCopy.stateCode === Constants.DEFAULT_ID) ||
        (formStateCopy.stateName === '') ||
        (formStateCopy.postalCode === '')) {

        setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState.person, isSaved]);



  useEffect(() => {
    if (!formState.email && personState.person && phoneTypeState.isArrayLoaded === true) {
      const contactInfo = personState.person?.contactInfo || {};

      const firstPhoneTypeId = Array.isArray(phoneTypeState.arrayData) && phoneTypeState.arrayData.length > 0
        ? phoneTypeState.arrayData[0].phoneTypeId
        : Constants.DEFAULT_ID;

      const phoneTypeId = contactInfo?.phoneTypeId > 0 ? contactInfo.phoneTypeId : firstPhoneTypeId;

      const selfPhoneNumber = contactInfo?.phoneNumber === '' ? omrTempPersonState?.objData?.phoneNumber : contactInfo?.phoneNumber;

      const selfEmail = contactInfo?.emailAddress === '' ? omrTempPersonState?.objData?.email : contactInfo?.emailAddress;

      setFormData({
        ...formState,
        email: selfEmail || '',
        phoneNumber: selfPhoneNumber || '',
        phoneTypeId: phoneTypeId,
        address1: contactInfo.address?.address1 || '',
        address2: contactInfo.address?.address2 || '',
        city: contactInfo.address?.city || '',
        stateCode: contactInfo.address?.stateCode || '',
        stateName: contactInfo.address?.stateName || '',
        postalCode: contactInfo.address?.postalCode || ''
      }, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, phoneTypeState]);


  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      navigate(NavLinks.PRIMARY_CONTACT_INFO_O15_SELF);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked: handleSubmitProxy,
    validateState
  };
};

export default useRegistrantContactInfoSelf;
