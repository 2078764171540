import Constants from "../../utils/Constants";

export const localValidate = (memberTypeSelectionModalFormState) => {
  let errors = {};

  if (memberTypeSelectionModalFormState.renewalOfferingInstanceId === Constants.DEFAULT_ID) {
    errors.renewalOfferingInstanceId = 'A Renewal Member Type selection is required';
  }

  return errors;
};

const RenewalMemberTypeFormValidation = async (memberTypeSelectionModalFormState) => {
  let errors = localValidate(memberTypeSelectionModalFormState);

  return errors ? errors : {};
};

export default RenewalMemberTypeFormValidation;