import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from './EthnicityValidation';
import useOmrData from '../../state/omr/UseOmrData';

import useEthnicityTypeData from '../../../common/state/ethnicityType/UseEthnicityTypeData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import Constants from '../../../common/utils/Constants';
import useForm from '../../../common/utils/UseForm';
import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useHispanicOrLatinoTypeData from '../../../common/state/hispanicOrLatinoType/UseHispanicOrLatinoTypeData';

const I_PREFER_NOT_TO_SAY_LABEL = 'I prefer not to say';

const INITIAL_VIEW_STATE = {
  radiobuttonlistItems: [],
  checkBoxListItems: []
};

const INITIAL_FORM_STATE = {
  hispanicOrLatinoTypeId: Constants.DEFAULT_ID,
  personEthnicity: []
};

const useEthnicity = (navigateLink) => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { hispanicOrLatinoTypeState, getHispanicOrLatinoTypes } = useHispanicOrLatinoTypeData();
  const { ethnicityTypeState, getEthnicityTypes } = useEthnicityTypeData();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const iPreferNotToSayId = state.radiobuttonlistItems.find(x => x.label === I_PREFER_NOT_TO_SAY_LABEL)?.value;
  const iPreferNotToSayCheckboxId = state.checkBoxListItems.find(x => x.name === I_PREFER_NOT_TO_SAY_LABEL)?.id;
  const {
    formState,
    errorState,
    onFormValueChanged,
    setFormData,
    handleSubmit
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {
    const newPerson = {
      ...personState,
      person: {
        ...personState?.person,
        hispanicOrLatinoTypeId: formState.hispanicOrLatinoTypeId,
        personEthnicity: formState.hispanicOrLatinoTypeId === iPreferNotToSayId
          ? []
          : formState.personEthnicity.map(x => x.id)
      }
    };

    savePersonState(newPerson);
  }

  const onCheckboxFormValueChanged = (value) => {
    if (value.some(x => x.id === iPreferNotToSayCheckboxId) === true) {
      const personEthnicityUpdated = state.checkBoxListItems.find(x => x.id === iPreferNotToSayCheckboxId);
      onFormValueChanged('personEthnicity', [personEthnicityUpdated]);
    }
    else {
      onFormValueChanged('personEthnicity', value);
    }
  }

  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.hispanicOrLatinoTypeId > 0) {
        let formStateCopy = [];
        formStateCopy = JSON.parse(JSON.stringify(formState));

        if (formState.hispanicOrLatinoTypeId !== Constants.DEFAULT_ID && isSaved === false) {
          let ethnicitySavedStatus;
          if (Array.isArray(formStateCopy.personEthnicity) === true && formStateCopy.personEthnicity.length > 0) {
            formStateCopy.personEthnicity.forEach(ethnicity => {
              if (!personState.person.personEthnicity.some(x => x === ethnicity.id)) {
                ethnicitySavedStatus = true;
              }
              return ethnicitySavedStatus
            });
          }

          if ((formStateCopy.hispanicOrLatinoTypeId !== personState.person.hispanicOrLatinoTypeId) ||
            (ethnicitySavedStatus === true)) {
            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
          } else {
            setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.hispanicOrLatinoTypeId === Constants.DEFAULT_ID) {
        if (formState.hispanicOrLatinoTypeId === Constants.DEFAULT_ID && isSaved === false) {
          // let ethnicitySavedStatus;
          // if (Array.isArray(formStateCopy.personEthnicity) === true && formStateCopy.personEthnicity.length > 0) {
          //   formStateCopy.personEthnicity.forEach(ethnicity => {
          //     if (!personState.person.personEthnicity.some(x => x === ethnicity.id)) {
          //       ethnicitySavedStatus = true;
          //     }
          //     return ethnicitySavedStatus
          //   });
          // }
          setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    if (hispanicOrLatinoTypeState.isArrayLoading !== true
      && hispanicOrLatinoTypeState.isArrayLoaded !== true) {
      getHispanicOrLatinoTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hispanicOrLatinoTypeState]);

  useEffect(() => {
    if (ethnicityTypeState.isArrayLoading !== true
      && ethnicityTypeState.isArrayLoaded !== true) {
      getEthnicityTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethnicityTypeState]);

  useEffect(() => {
    if (hispanicOrLatinoTypeState.isArrayLoaded === true
      && state.radiobuttonlistItems.length === 0
      && ethnicityTypeState.isArrayLoaded === true
      && state.checkBoxListItems.length === 0) {
      setState({
        ...state,
        radiobuttonlistItems: hispanicOrLatinoTypeState.arrayData.map(x => {
          return {
            value: x.hispanicOrLatinoTypeId,
            label: x.typeName
          };
        }),
        checkBoxListItems: ethnicityTypeState.arrayData.map(x => {
          return {
            id: x.ethnicityTypeId,
            name: x.typeName
          };
        })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hispanicOrLatinoTypeState, ethnicityTypeState]);

  useEffect(() => {
    if ((formState.hispanicOrLatinoTypeId > 0) === false) {
      const personEthnicity = ethnicityTypeState.arrayData.filter(
        x => personState.person.personEthnicity.some(s => x.ethnicityTypeId === s)
      ).map(x => {
        return {
          id: x.ethnicityTypeId,
          name: x.typeName
        };
      })

      const newFormState = {
        ...formState,
        hispanicOrLatinoTypeId: personState.person.hispanicOrLatinoTypeId,
        personEthnicity
      };
      setFormData(newFormState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
      navigate(navigateLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    ...state,
    displayPopUp: state.checkBoxListItems.length === 0 || state.radiobuttonlistItems.length === 0,
    showEthnicities: formState.hispanicOrLatinoTypeId > 0 && formState.hispanicOrLatinoTypeId !== iPreferNotToSayId,
    formState,
    errorState,
    onFormValueChanged,
    onCheckboxFormValueChanged,
    handleSubmit
  };
};

export default useEthnicity;