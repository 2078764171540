const Views = {
  // DISCLAIMER: 'DISCLAIMER',
  ABOUT_MEMBER: 'ABOUT_MEMBER',
  ABOUT_MEMBER_NEW_MEMBER: 'ABOUT_MEMBER_NEW_MEMBER',
  ABOUT_MEMBER_EXISTING_MEMBER: 'ABOUT_MEMBER_EXISTING_MEMBER',
  MEMBER_CONTACT: 'MEMBER_CONTACT',
  REGISTRANT_CONTACT_INFO_SELF: 'REGISTRANT_CONTACT_INFO_SELF',
  REGISTRANT_CONTACT_INFO_OTHER: 'REGISTRANT_CONTACT_INFO_OTHER',
  PRIMARY_CONTACT_U16: 'PRIMARY_CONTACT_U16',
  PRIMARY_CONTACT_O15_SELF: 'PRIMARY_CONTACT_O15_SELF',
  PRIMARY_CONTACT_O15_OTHER: 'PRIMARY_CONTACT_O15_OTHER',
  ADDITIONAL_CONTACTS_U16: 'ADDITIONAL_CONTACTS_U16',
  ADDITIONAL_CONTACTS_O15_SELF: 'ADDITIONAL_CONTACTS_O15_SELF',
  ADDITIONAL_CONTACTS_O15_OTHER: 'ADDITIONAL_CONTACTS_O15_OTHER',
  ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT: 'ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT',
  ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT: 'ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT',
  ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT: 'ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT',
  ETHNICITY: 'ETHNICITY',
  MEMBERSHIP: 'MEMBERSHIP',
  MEMBERSHIP_HISTORY: 'MEMBERSHIP_HISTORY',
  COMPETITION_CATEGORY: 'COMPETITION_CATEGORY',
  GENDER: 'GENDER',
  //DISABILITIES: 'DISABILITIES',
  ACKNOWLEDGEMENTS: 'ACKNOWLEDGEMENTS',
  MEMBER_SUMMARY: 'MEMBER_SUMMARY',
  ALL_SUMMARY: 'ALL_SUMMARY'
};

const VIEW_DATA = [/* {
  name: Views.DISCLAIMER,
  subHeaderText: '',
  instructionText: '',
  nextButtonText: 'Continue'
}, */ {
  name: Views.ABOUT_MEMBER,
  subHeaderText: 'About Member',
  instructionText: '',
  nextButtonText: ''
}, {
  name: Views.ABOUT_MEMBER_NEW_MEMBER,
  subHeaderText: 'About Member',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.ABOUT_MEMBER_EXISTING_MEMBER,
  subHeaderText: 'About Member',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.MEMBER_CONTACT,
  subHeaderText: '',
  instructionText: '',
  nextButtonText: ''
},
{
  name: Views.REGISTRANT_CONTACT_INFO_SELF,
  subHeaderText: 'Member Contact Info',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.REGISTRANT_CONTACT_INFO_OTHER,
  subHeaderText: 'Member Contact Info',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.PRIMARY_CONTACT_U16,
  subHeaderText: 'Emergency Contact',
  instructionText: '',
  nextButtonText: 'Continue',
  actionIntraPageButtonText: 'Add More Contacts'
},
{
  name: Views.PRIMARY_CONTACT_O15_SELF,
  subHeaderText: 'Emergency Contact',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.PRIMARY_CONTACT_O15_OTHER,
  subHeaderText: 'Emergency Contact',
  instructionText: '',
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_U16,
  subHeaderText: 'Additional Contacts',
  instructionText: "Adding additional contacts is optional. If contact information changes or you don't have it all right now, this step can be done at a later time.",
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_O15_SELF,
  subHeaderText: 'Additional Contacts (Optional)',
  instructionText: "Adding additional contacts is optional. If contact information changes or you don't have it all right now, this step can be done at a later time.",
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_O15_OTHER,
  subHeaderText: 'Additional Contacts (Optional)',
  instructionText: "Adding additional contacts is optional. If contact information changes or you don't have it all right now, this step can be done at a later time.",
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT,
  subHeaderText: 'Add/Edit Additional Contact',
  instructionText: 'Please fill in all required fields',
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT,
  subHeaderText: ' Add/Edit Additional Contact',
  instructionText: 'Please fill in all required fields',
  nextButtonText: 'Continue'
},
{
  name: Views.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT,
  subHeaderText: ' Add/Edit Additional Contact',
  instructionText: '',
  nextButtonText: 'Continue'
}, {
  name: Views.ETHNICITY,
  subHeaderText: 'Ethnicity',
  instructionText: '',
  nextButtonText: 'Continue'
}, {
  name: Views.MEMBERSHIP,
  subHeaderText: 'Membership',
  instructionText: '',
  nextButtonText: 'Continue'
}, {
  name: Views.MEMBERSHIP_HISTORY,
  subHeaderText: 'Member Registration History',
  instructionText: '',
  nextButtonText: ' '
}, {
  name: Views.COMPETITION_CATEGORY,
  subHeaderText: 'Competition Category',
  subSubHeaderText: 'for the purpose of athlete swimming eligibility, competition, selection and records, stated as male or female.',
  instructionText: "Athletes are required to register with the competition category which corresponds to the biological gender assigned to the athlete at birth. Any athlete wishing to compete in a competition category <strong><u>different</u></strong> than the biological gender assigned to the athlete at birth, please reference the process outlined in USA Swimming’s Athlete Inclusion, Competitive Equity and Eligibility Policy",
  nextButtonText: 'Continue'
}, {
  name: Views.GENDER,
  subHeaderText: 'Gender',
  instructionText: '',
  nextButtonText: 'Continue'
}, /* {
  name: Views.DISABILITIES,
  subHeaderText: 'Disability (Optional)',
  instructionText: '',
  nextButtonText: 'Continue'
}, */ {
  name: Views.ACKNOWLEDGEMENTS,
  subHeaderText: 'Legal Ackowledgements',
  instructionText: 'Please indicate that you acknowledge each of the passages below by clicking each of the sliders until they all read "Yes" and virtually sign the form.',
  nextButtonText: 'Continue'
}, {
  name: Views.MEMBER_SUMMARY,
  subHeaderText: 'Member Summary',
  instructionText: '<p>Please review this information.  If you would like to correct any of it, please click the edit icon near the information that you would like to change, or you can click links in the left side navigation box.</p><p>If you have additional people to register, please click the "Add Another Member" button. If not, please click on the "Check Out" button, which will take you to the payment page.</p>',
  mobileInstructionText: '<p>Please review this information.  If you would like to correct any of it, please click the edit icon near the information that you would like to change, or you can click links in the navigation box above.</p><p>If you have additional people to register, please click the "Add Another Member" button. If not, please click on the "Check Out" button, which will take you to the payment page.</p>',
  nextButtonText: 'Check Out'
}, {
  name: Views.ALL_SUMMARY,
  subHeaderText: 'Summary',
  instructionText: '',
  nextButtonText: 'Continue'
}];

export const getViewData = (viewName) => {
  const view = VIEW_DATA.find(x => x.name === viewName);
  return { ...view };
};

export default Views;