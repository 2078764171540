import { useEffect } from 'react';

import useContactRelationshipTypeData from '../../../../common/state/contactRelationshipType/UseContactRelationshipTypesData';

import Constants from '../../../../common/utils/Constants';


const SELF_LABEL = 'Self';
const useNoSelfContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState, } = useContactRelationshipTypeData();

  useEffect(() => {
    if (contactRelationshipTypeState.arrayData.length > 0) {
      const selfIndex = contactRelationshipTypeState.dataAsOptionsWithNames.findIndex(x => x.name === SELF_LABEL);
      if (selfIndex > Constants.DEFAULT_ID) {
        contactRelationshipTypeState.dataAsOptionsWithNames.splice(selfIndex, 1);
      }
    }
  }, [contactRelationshipTypeState]);

  return {
    contactRelationshipTypeState
  };
};

export default useNoSelfContactRelationshipTypeDropdown;