import React from 'react';

import useCompetitonCategoryAthO15 from './UseCompetitonCategoryAthO15';
import GenderForm from './GenderForm';
import Views from '../../Views';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

const CompetitonCategoryAthO15 = () => {
  const {
    formState,
    errorState,
    state,
    setState,
    handleSubmit,
    onNextButtonClicked,
    onFormValueChanged,
    isCompetitionCategoryLocked
  } = useCompetitonCategoryAthO15();

  return (
    <ViewTemplate
      viewName={Views.COMPETITION_CATEGORY}
      onNextButtonClicked={onNextButtonClicked}>
      <GenderForm
        handleSubmit={handleSubmit}
        state={state}
        setState={setState}
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        isCompetitionCategoryLocked={isCompetitionCategoryLocked} />
    </ViewTemplate>
  );
};

export default CompetitonCategoryAthO15;