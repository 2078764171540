import usePhoneTypeData from '../../../state/phoneType/UsePhoneTypeData';

const usePhoneTypeDropdown = () => {
  const { phoneTypeState } = usePhoneTypeData();

  return {
    phoneTypeState
  };
};

export default usePhoneTypeDropdown;