import React, { createContext, useState } from 'react';

import ChildrenData from './ChildrenData';

export const ChildrenStateContext = createContext();

const ChildrenContextProvider = ({ children }) => {
  const stateHook = useState(ChildrenData.INITIAL_STATE);

  return (
    <ChildrenStateContext.Provider value={stateHook}>
      {children}
    </ChildrenStateContext.Provider>
  );
};

export default ChildrenContextProvider;