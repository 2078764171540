import getInitialState from './GetInitialState';
import NavLinks from '../../NavLinks';
import Views from '../../Views';
import Constants from '../../../common/utils/Constants';

const getMemberContact = (viewName, personState, securityState) => {
  const isCurrent =
    viewName === Views.REGISTRANT_CONTACT_INFO_SELF
    || viewName === Views.REGISTRANT_CONTACT_INFO_OTHER
    || viewName === Views.PRIMARY_CONTACT_O15_SELF
    || viewName === Views.PRIMARY_CONTACT_O15_OTHER
    || viewName === Views.PRIMARY_CONTACT_U16
    || viewName === Views.ADDITIONAL_CONTACTS_U16
    || viewName === Views.ADDITIONAL_CONTACTS_O15_SELF
    || viewName === Views.ADDITIONAL_CONTACTS_O15_OTHER
    || viewName === Views.ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT
    || viewName === Views.ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT
    || viewName === Views.ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT;

  const person = personState?.person || {};

  let path = '';
  if (personState?.isMembershipForLoggedInUser === true) {
    path = NavLinks.REGISTRANT_CONTACT_INFO_SELF;
  } else if (personState?.is16orOlder === true) {
    path = NavLinks.REGISTRANT_CONTACT_INFO_OTHER;
  } else {
    path = NavLinks.PRIMARY_CONTACT_INFO_U16;
  };

  const isIneligible = (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
    personState?.isMembershipForLoggedInUser === false) ? false : true;

  const isComplete = isIneligible ? false : (personState?.is16orOlder
    ? (person?.contactInfo?.emailAddress
      && person?.contactInfo?.phoneNumber
      && person?.contactInfo?.address?.address1
      && person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    )
    : (person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    ));

  const item = getInitialState(Views.MEMBER_CONTACT);

  const arePrerequisitesDone = person?.firstName && person?.lastName
    && person?.birthDate && person?.birthDate !== Constants.BLANK_DATE_STRING
    && (person?.isUsCitizen === true || person?.isUsCitizen === false) && isIneligible === false ? true : false;

  return {
    ...item,
    path,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getMemberContact;