import { useEffect } from 'react';

import useCompetitionGenderTypeData from '../../../common/state/competitionGender/UseCompetitionGenderTypeData';

const useGenderCompetitionCategoryForm = (personState, formState, setFormData) => {
  const { competitionGenderState } = useCompetitionGenderTypeData();

  useEffect(() => {
    if (competitionGenderState.isArrayLoaded === true && personState?.person?.competitionGenderTypeId > 0) {
      const competitionGenderId = personState?.person?.competitionGenderTypeId
      setFormData({
        ...formState,
        competitionGenderTypeId: competitionGenderId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionGenderState.isArrayLoaded]);

  return {
    competitionGenderState
  }
}

export default useGenderCompetitionCategoryForm;