import { DEFAULT_ID } from '../../../common/utils/Constants';

export const localValidate = (formState) => {
  const errors = {};

  const UNKNOWN_COMPETITION_GENDER_TYPE_NAME = 'Unknown';

  // Competition Category
  if (formState.competitionGenderTypeId === DEFAULT_ID || formState.competitionGenderTypeName === UNKNOWN_COMPETITION_GENDER_TYPE_NAME) {
    errors.competitionGenderTypeId = 'A selection is required';
  }

  return errors;
};

const GenderValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default GenderValidation;