import useSuffixData from '../../../state/suffix/UseSuffixData';

const useSuffixDropdown = () => {
  const { suffixState } = useSuffixData();

  return {
    suffixState
  };
};

export default useSuffixDropdown;