import React, { createContext, useState } from 'react';

import RegisteredMembersData from './RegisteredMembersData';

export const RegisteredMembersStateContext = createContext();

const RegisteredMembersContextProvider = ({ children }) => {
  const stateHook = useState(RegisteredMembersData.INITIAL_STATE);

  return (
    <RegisteredMembersStateContext.Provider value={stateHook}>
      {children}
    </RegisteredMembersStateContext.Provider>
  );
};

export default RegisteredMembersContextProvider;