import { Fragment } from 'react';

import Checkbox from '../checkboxes/Checkbox';
import ExclamationIcon from '../icons/ExclamationIcon';
import ActionIntraPageButton from '../buttons/ActionIntraPageButton';

import style from './RenewMemberships.module.css';
import global from '../GlobalStyle.module.css';

import { formatDate } from '../../utils/DateFunctions';

const RenewMembershipsSelectionSmallGrid = ({ omrView, gridData, isLoading, onCheckboxChange, onChangeMemberTypeClicked, onShowRenewalConfirmationModalClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((membership, i) => (
          <div className={global.SmallTableRow} key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId}>
            <div className={global.SmallTableRowHead}>
              {membership.isEditRenewalAvailable === true && membership.clubBulkRenewalOptInt === false && membership.isClubActive === true ?
                <div style={{ maxWidth: '200px' }}> <ActionIntraPageButton onClick={(e) => onChangeMemberTypeClicked(e, membership)}>Change Member Type</ActionIntraPageButton></div> : <span></span>}
              {membership.isClubActive === true ?
                <Fragment>
                  {membership.hasPreviousRegistration === true ?
                    <Fragment>
                      {membership.clubBulkRenewalOptInt === false ?
                        <Fragment>
                          {membership.renewalOfferingInstanceId !== null &&
                            <Fragment>
                              <span>&nbsp;</span>
                              <div className={global.CheckboxMobile}>
                                <Checkbox
                                  className={global.IconButton}
                                  label=""
                                  name="isSelected"
                                  onChange={(e) => { onCheckboxChange(e, membership.personId, membership.offeringInstanceId) }}
                                  checked={membership.isSelected || false} />
                              </div>
                            </Fragment>}
                        </Fragment> :
                        <span>
                          <span>Team is Renewing</span>
                        </span>
                      }
                    </Fragment>
                    :
                    <Fragment>
                      {omrView === true ?
                        <div style={{ maxWidth: '320px' }}>
                          <ActionIntraPageButton onClick={(e) => onShowRenewalConfirmationModalClicked(e, membership)}>Provide Missing Info & Renew</ActionIntraPageButton></div> :
                        <Fragment>
                          <span>Not Eligible for One Button Renew - Contact Team for Registration Link</span>
                        </Fragment>}
                    </Fragment>
                  }
                </Fragment> :
                <Fragment>
                  {omrView === true ?
                    <Fragment>
                      <span>Team is Expired - Not Eligible for One Button Renew</span>
                    </Fragment> :
                    <Fragment>
                      {membership.hasPreviousRegistration === true ?
                        <span>Team is Expired - Contact Team for Instructions</span>
                        :
                        <span>Not Eligible for One Button Renew - Contact Team for Registration Link</span>
                      }
                    </Fragment>
                  }
                </Fragment>}
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={['row', membership.hasPreviousRegistration === false ? style.IsSelected : style.NotSelected].join(' ')}>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Name</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : <span>&nbsp;</span>}</span></div>
                <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>Comp. Category</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.competitionGenderTypeName || <span>&nbsp;</span>}</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.birthDate ? formatDate(membership.birthDate) : <span>&nbsp;</span>}</span></div>
                {omrView === false &&
                  <Fragment>
                    <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Team Name</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.renewalOrgUnitName || <span>&nbsp;</span>} {membership.renewalOrgUnitName === 'Unattached' ? ' - ' + membership.renewalParentOrgUnitName : ''} {membership.renewalParentOrgUnitCode ? '(' + membership.renewalParentOrgUnitCode + ')' : <span>&nbsp;</span>}</div>
                  </Fragment>}
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Prior Mem.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.offeringName || <span>&nbsp;</span>}</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Prior Exp.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.currentExpirationDate ? `${formatDate(membership.currentExpirationDate)}` : <span>&nbsp;</span>}</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Renewal Mem.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.renewalOfferingName || <span>&nbsp;</span>}{membership.hasPreviousRegistration === true && membership.renewalOfferingInstanceId === null && <b>Not Offered at This Time</b>}</span></div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Renewal Exp.</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.renewalExpirationDate ? `${formatDate(membership.renewalExpirationDate)}` : <span>&nbsp;</span>}</span></div>
                {omrView === true &&
                  <Fragment><div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Transfer?</div>
                    <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{membership.orgUnitId !== membership.renewalOrgUnitId ? <span><b><span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='Warning! Your renewal membership team is different than your prior membership team. By renewing with this link you are transferring this membership to a different team.' />&nbsp;Yes</span></b></span> : 'No'}</div>
                  </Fragment>}
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Memberships are Eligible for Renewal</div>
            </div>
          </div>
        )
    }
  </div >
);

export default RenewMembershipsSelectionSmallGrid;