import React from 'react';
import Headings from '../../../common/components/headings/Headings';
import useWelcome from './UseWelcome';

import style from '../Omr.module.css';
import WelcomeMessage from './WelcomeMessage';
import NotLoggedInMessage from './NotLoggedInMessage';
import ExpiredOmrLink from './ExpiredOmrLink';

const Welcome = () => {
  const { isLoggedIn, isRouteCodeValueZero, routeCodeValueState } = useWelcome();

  return (
    <div className='row'>
      <div className="col-xs-12" id="omrHeadingId">
        <Headings.H2 excludeBar={true} className={style.CenterAlign}>USA Swimming Online Member Registration{routeCodeValueState.offeringInstanceCouponId && ' - Outreach'}</Headings.H2>
        <hr className="usas-bar-turmeric" />
        <Headings.H3 excludeBar={true} className={style.CenterAlign}>Welcome</Headings.H3>
        {isLoggedIn === true
          ? isLoggedIn === true && isRouteCodeValueZero === false
            ? <WelcomeMessage />
            : <ExpiredOmrLink />
          : <NotLoggedInMessage />}
      </div>
    </div>
  );
};

export default Welcome;