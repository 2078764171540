const NavLinks = {
  HOME: '/',        //redirects to WELCOME
  WELCOME: '/omr/welcome',  //unsecured
  ENTRY: '/omr/entry',
  NEW_OR_EXISTING_SELF: '/omr/self/neworexistingmember',
  NEW_OR_EXISTING_OTHER: '/omr/other/neworexistingmember',
  SEARCH_SELF: '/omr/self/search',
  SEARCH_OTHER: '/omr/other/search',
  // DISCLAIMER:'/omr/registration/disclaimer',
  ABOUT_MEMBER: '/omr/registration/aboutmember',
  REGISTRANT_CONTACT_INFO_SELF: '/omr/registration/o15/self/registrantinfo',
  REGISTRANT_CONTACT_INFO_OTHER: '/omr/registration/o15/other/registrantinfo',
  PRIMARY_CONTACT_INFO_U16: '/omr/registration/u16/primaryinfo',
  PRIMARY_CONTACT_INFO_O15_SELF: '/omr/registration/o15/self/primaryinfo',
  PRIMARY_CONTACT_INFO_O15_OTHER: '/omr/registration/o15/other/primaryinfo',
  ADDITIONAL_CONTACTS_U16: '/omr/registration/u16/additionalcontacts',
  ADDITIONAL_CONTACTS_O15_SELF: '/omr/registration/o15/self/additionalcontacts',
  ADDITIONAL_CONTACTS_O15_OTHER: '/omr/registration/o15/other/additionalcontacts',
  ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT: '/omr/registration/u16/additionalcontacts/addedit',
  ADDITIONAL_CONTACTS_O15_SELF_ADD_EDIT_CONTACT: '/omr/registration/o15/self/additionalcontacts/addedit',
  ADDITIONAL_CONTACTS_O15_OTHER_ADD_EDIT_CONTACT: '/omr/registration/o15/other/additionalcontacts/addedit',
  ETHNICITY_U16: '/omr/registration/u16/ethnicity',
  ETHNICITY_16_AND_UP: '/omr/registration/o15/ethnicity',
  MEMBERSHIP_U16: '/omr/registration/u16/membershiptype',
  MEMBERSHIP_16_AND_UP: '/omr/registration/o15/membershiptype',
  MEMBERSHIP_REGISTRATION_HISTORY: '/omr/registration/memberregistrationhistory',
  ATHLETE_COMP_GENDER_U16: '/omr/registration/u16/competitioncategory',
  ATHLETE_COMP_GENDER_16_AND_UP: '/omr/registration/o15/competitioncategory',
  GENDER_IDENTITY_16_AND_UP: '/omr/registration/o15/nonathletegender',
  // DISABILITIES_ATHLETE_U16: '/omr/registration/u16/athletedisabilities',
  // DISABILITIES_ATHLETE_16_AND_UP: '/omr/registration/o15/athletedisabilities',
  // DISABILITIES_NON_ATHLETE_16_AND_UP: '/omr/registration/o15/nonathletedisabilities',
  REGISTRATION_ACKNOWLEDGEMENTS: '/omr/registration/acknowledgements',
  MEMBER_SUMMARY: '/omr/registration/summary',
  ALL_SUMMARY: '/omr/summary',
  // CONFIRMATION: '/omr/confirmation'
};

export default NavLinks;