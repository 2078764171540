import { useContext } from 'react';

import { AddressTypeStateContext } from './AddressTypeContextProvider';
import AddressTypeData from './AddressTypeData';

const useAddressTypeData = () => {
  const [addressTypeState, setAddressTypeState] = useContext(AddressTypeStateContext);
  
  const getAddressTypes = () => {
    AddressTypeData.getAddressTypes(addressTypeState, setAddressTypeState);
  };
  return {
    addressTypeState,
    getAddressTypes
  };
};

export default useAddressTypeData;