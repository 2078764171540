import React, { Fragment } from 'react';

import useWelcomeMessage from './UseWelcomeMessage';

import SelectMemberGrid from './SelectMemberGrid';
import OfferingTypesGrid from '../membershipTypes/grids/OfferingTypesGrid';
import ErrorHandle from '../components/ErrorHandle';

import RenewMembershipsOmr from '../../../common/components/renewMemberships/RenewMembershipsOmr';
import CompetitionCategoryRadiobuttonList from '../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import Constants from '../../../common/utils/Constants';

import style from '../Omr.module.css';

const WelcomeMessage = () => {
  const { isLoading, lsc, club, lscContact, registeredMembersState, memberModalState, offeringModalState,
    onAddNewRoleToExistingMemberClicked, onMemberModalCanceled, onOfferingModalCanceled, onContinueClicked, onSelectClicked,
    onAddNewMembershipClicked, showNoMembershipsAvailable, offeringTypeState, onAllMembershipCheck, state, restrictions,
    newRoleConfirmationModalState, onNewRoleConfirmationModalCanceled, onNewRoleConfirmationContinueClicked, routeCodeValueState,
    competitionGenderTypeSelectionModalState, setCompetitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalFormState, competitionGenderTypeSelectionModalErrorState,
    onCompetitionGenderTypeSelectionContinueClicked, onCompetitionGenderTypeSelectionModalCanceled } = useWelcomeMessage();

  if (isLoading === true) {
    return <LoadingModal />;
  }

  return (
    <Fragment>
      <div style={{ marginTop: '20px' }} className={style.SectionBorderOmr}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <div className={style.CenterAlign}>
              <Headings.H4>Create New Memberships</Headings.H4>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className={'col-xs-12 col-sm-6'}>
            <div className={style.SubSectionBorderOmr}>
              <div className={['row usas-extra-top-margin', style.BottomMargin].join('')}>
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  Click 'Register New Member' to register a <i>new member</i> with USA Swimming. This registration is associated with
                </div>
                <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
                {lsc !== null
                  ? <Fragment>
                    <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                      <span className={style.WelcomeHeader}>LSC: </span> &nbsp;
                      <span className={style.WelcomeHeaderData}>{lsc}</span>
                    </div>
                  </Fragment>
                  : <Fragment />}
              </div>
              <div className='row'>
                <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                  <span className={style.WelcomeHeader}>Club: </span> &nbsp;
                  <span className={style.WelcomeHeaderData}>{club}</span>
                </div>
              </div>
              {lscContact &&
                <div className='row'>
                  <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                    <span className={style.WelcomeHeader}>LSC Contact: </span> &nbsp;
                    <span className={style.WelcomeHeaderData}>{lscContact}</span>
                  </div>
                </div>}
              <div className='row'>
                <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  If this is not the registration option that you intended, please get the correct link from
                  your coach before starting your registration.
                </div>
              </div>
              <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
              <div className='row usas-extra-top-margin'>
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  <PrimaryButton onClick={onContinueClicked}>Register New Member</PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-xs-12 col-sm-6'}>
            <div className={style.SubSectionBorderOmr}>
              <div className={['row usas-extra-top-margin', style.BottomMargin].join('')}>
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  Click 'Add New Role to Existing Member' to register an <i>existing member</i> with USA Swimming. This registration is associated with
                </div>
                <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
                {lsc !== null
                  ? <Fragment>
                    <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                      <span className={style.WelcomeHeader}>LSC: </span> &nbsp;
                      <span className={style.WelcomeHeaderData}>{lsc}</span>
                    </div>
                  </Fragment>
                  : <Fragment />}
              </div>
              <div className='row'>
                <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                  <span className={style.WelcomeHeader}>Club: </span> &nbsp;
                  <span className={style.WelcomeHeaderData}>{club}</span>
                </div>
              </div>
              {lscContact &&
                <div className='row'>
                  <div className={['col-xs-12', style.CenterAlign, style.TextPadding].join(' ')}>
                    <span className={style.WelcomeHeader}>LSC Contact: </span> &nbsp;
                    <span className={style.WelcomeHeaderData}>{lscContact}</span>
                  </div>
                </div>}
              <div className='row'>
                <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  If this is not the registration option that you intended, please get the correct link from
                  your coach before starting your registration.
                </div>
              </div>
              <div className='row usas-extra-top-margin usas-extra-bottom-margin' />
              <div className='row usas-extra-top-margin'>
                <div className={[style.CenterAlign, style.TextPadding].join(' ')}>
                  <PrimaryButton onClick={onAddNewRoleToExistingMemberClicked}>Add New Role to Existing Member</PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <RenewMembershipsOmr omrView={true} routeCodeValueState={routeCodeValueState} lsc={lsc} club={club} lscContact={lscContact} />
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={memberModalState.modalTitle}
        displayPopUp={memberModalState.displayPopUp && registeredMembersState?.isArrayLoading === false}
        onModalCanceled={onMemberModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <SelectMemberGrid
              gridData={registeredMembersState.arrayData}
              isLoading={registeredMembersState.isArrayLoading}
              onSelectClicked={onSelectClicked}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onMemberModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={offeringModalState.modalTitle}
        displayPopUp={offeringModalState.displayPopUp && registeredMembersState?.isArrayLoading === false && offeringTypeState.isArrayLoading === false}
        onModalCanceled={onOfferingModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <span><b>Selected Existing Member:</b> {(offeringModalState?.member?.firstName || offeringModalState?.member?.middleName || offeringModalState?.member?.lastName) ? `${offeringModalState?.member?.firstName || ''}  ${offeringModalState?.member?.preferredName !== '' && offeringModalState?.member?.preferredName !== offeringModalState?.member?.firstName ? '"' + offeringModalState?.member?.preferredName + '"' : ''} ${offeringModalState?.member?.middleName || ''} ${offeringModalState?.member?.lastName || ''}` : ''}</span>
          </div>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin" />
          <div className='col-xs-12 usas-extra-top-margin'>
            {showNoMembershipsAvailable === true ?
              <Fragment>
                <div className="col-xs-12">
                  <p className={style.CenterAlign}>There are currently no memberships available for this registrant, please check back later.</p>
                </div>
              </Fragment>
              :
              <Fragment>
                <OfferingTypesGrid
                  state={offeringTypeState}
                  selectedOfferingTypeIds={state.selectedOfferingTypeIds}
                  onChanged={onAllMembershipCheck}
                  restrictions={restrictions} />
                <ErrorHandle error={state.errorMessage} message={state.errorMessage} />
              </Fragment>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onAddNewMembershipClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onOfferingModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={competitionGenderTypeSelectionModalState.modalTitle}
        displayPopUp={competitionGenderTypeSelectionModalState.displayPopUp}
        onModalCanceled={onCompetitionGenderTypeSelectionModalCanceled}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <span><b>Selected Existing Member:</b> {(offeringModalState?.member?.firstName || offeringModalState?.member?.middleName || offeringModalState?.member?.lastName) ? `${offeringModalState?.member?.firstName || ''}  ${offeringModalState?.member?.preferredName !== '' && offeringModalState?.member?.preferredName !== offeringModalState?.member?.firstName ? '"' + offeringModalState?.member?.preferredName + '"' : ''} ${offeringModalState?.member?.middleName || ''} ${offeringModalState?.member?.lastName || ''}` : ''}</span>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <CompetitionCategoryRadiobuttonList
                label={<Headings.H6>Select Competition Category</Headings.H6>}
                isVertical={true}
                name="competitionGenderTypeId"
                value={Number(competitionGenderTypeSelectionModalFormState.competitionGenderTypeId)}
                error={competitionGenderTypeSelectionModalErrorState.competitionGenderTypeId}
                message={competitionGenderTypeSelectionModalErrorState.competitionGenderTypeId}
                onChange={(value) => { setCompetitionGenderTypeSelectionModalFormState({ ...competitionGenderTypeSelectionModalFormState, competitionGenderTypeId: Number(value) }) }} />
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onCompetitionGenderTypeSelectionContinueClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCompetitionGenderTypeSelectionModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={newRoleConfirmationModalState.modalTitle}
        displayPopUp={newRoleConfirmationModalState.displayPopUp && !registeredMembersState.isSaving}
        onModalCanceled={onNewRoleConfirmationModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12">
              <b>Your selected new memberships have been added to the shopping cart!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <span>Please choose between continuing to the shopping cart to complete your new membership purchases, or staying on this page and starting the process to add more new memberships.</span>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onNewRoleConfirmationContinueClicked}>Continue to Shopping Cart</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onNewRoleConfirmationModalCanceled}>Add More New Memberships</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={registeredMembersState?.isArrayLoading || offeringTypeState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={registeredMembersState?.isSaving} />
    </Fragment>
  );
};

export default WelcomeMessage;