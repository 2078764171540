import { useContext } from 'react';

import { RenewalOptionStateContext } from './RenewalOptionContextProvider';

import RenewalOptionData from './RenewalOptionData';

const useRenewalOptionData = () => {
  const [renewalOptionState, setRenewalOptionState] = useContext(RenewalOptionStateContext);

  const getRenewalOptions = (personId, offeringInstanceId, orgUnitId) => {
    RenewalOptionData.getRenewalOptionData(renewalOptionState, setRenewalOptionState, personId, offeringInstanceId, orgUnitId);
  };

  return {
    renewalOptionState,
    getRenewalOptions
  };
};

export default useRenewalOptionData;