import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';


import getMemberRegistrationHistory from './GetMemberRegistrationHistoryData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};


const MembershipRegistrationHistoryData = { INITIAL_STATE, getMemberRegistrationHistory };

export default MembershipRegistrationHistoryData;