import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';
import NavLinks from '../../NavLinks';

import { getViewData } from '../../Views';
import useOmrBackButtonData from '../../state/omrBackButton/UseOmrBackButtonData';

import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  name: '',
  subHeaderText: '',
  instructionText: '',
  nextButtonText: '',
  isAboutMemberView: false,
};

const useViewTemplate = (viewName, onBackButtonClicked) => {
  const navigate = useNavigate();
  const [view, setView] = useState(INITIAL_STATE);
  const { routeCodeValueState } = useRouteCodeValueData();
  const { navRoutes } = useNavRoutes();
  const { omrBackButtonState } = useOmrBackButtonData();

  const handleBackButtonClicked = (e) => {
    document.getElementById('omrHeadingId')?.scrollIntoView();
    if (onBackButtonClicked instanceof Function) {
      onBackButtonClicked(e);
    } else {
      if (e?.preventDefault) {
        e.preventDefault();
      };

      navigate(-1);
    }
  };

  const NavigateToEntryView = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    };
    document.getElementById('omrHeadingId')?.scrollIntoView();
    if (omrBackButtonState.navigateToWelcomePage === false) {
      navigate(NavLinks.ENTRY);
    }
    else {
      if (routeCodeValueState.routeCodeValue) {
        navigate(navRoutes.WELCOME.route + '/' + routeCodeValueState.routeCodeValue);
      }
      else {
        navigate(navRoutes.WELCOME.route);
      }
    }
  };


  useEffect(() => {
    const foundView = getViewData(viewName);
    if (foundView) {
      setView({ ...foundView });
    }
  }, [viewName]);

  return {
    ...view,
    handleBackButtonClicked,
    isAboutMemberView: view.isAboutMemberView,
    NavigateToEntryView,
    routeCodeValueState
  };
};

export default useViewTemplate;