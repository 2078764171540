import React from 'react';

import useGenderIdentityNonAth015 from './UseGenderIdentityNonAth015';
import GenderIdentityForm from './GenderIdentityForm';
import Views from '../../Views';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

const GenderIdentityNonAthO15 = () => {
  const {
    formState,
    errorState,
    state,
    setState,
    handleSubmit,
    onNextButtonClicked,
    onFormValueChanged
  } = useGenderIdentityNonAth015();

  return (
    <ViewTemplate
      viewName={Views.GENDER}
      onNextButtonClicked={onNextButtonClicked}>
      <GenderIdentityForm
        handleSubmit={handleSubmit}
        state={state}
        setState={setState}
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged} />
    </ViewTemplate>
  );
};

export default GenderIdentityNonAthO15;