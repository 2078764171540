import getInitialState from './GetInitialState';
import NavLinks from '../../NavLinks';
import Views from '../../Views';
import Constants from '../../../common/utils/Constants';

const hasAthleteMembership = (personState) => {
  let athleteOfferingTypes = personState
    .offeringInstances
    .filter(x => x.offeringName.toLowerCase().includes('athlete'));

  const athleteOfferingTypeIds = athleteOfferingTypes.map(x => { return x.offeringInstanceId });
  let isAthleteMember = false;
  personState.person.offeringInstanceIds.forEach(id => {
    if (athleteOfferingTypeIds.indexOf(id) >= 0) {
      isAthleteMember = true;
    }
  });

  return isAthleteMember
};

const getGender = (viewName, personState, securityState) => {
  const person = personState?.person || {};

  let path = '';
  if (personState?.is16orOlder === true) {
    path = hasAthleteMembership(personState) === true
      ? NavLinks.ATHLETE_COMP_GENDER_16_AND_UP
      : NavLinks.GENDER_IDENTITY_16_AND_UP;
  } else {
    path = NavLinks.ATHLETE_COMP_GENDER_U16;
  };

  const isCurrent =
    viewName === Views.GENDER || viewName === Views.COMPETITION_CATEGORY;

  const isIneligible = (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
    personState?.isMembershipForLoggedInUser === false) ? false : true;

  let isComplete = isIneligible ? false :
    (person?.hasAthleteOffering === true && person?.competitionGenderTypeId > 0) === true ?
      true : (person?.hasAthleteOffering === false && person?.genderIdentityTypeId > 0) === true ? true : false;

  const item = getInitialState(Views.GENDER);

  const isAboutMemberComplete = person?.firstName && person?.lastName
    && person?.birthDate && person?.birthDate !== Constants.BLANK_DATE_STRING
    && (person?.isUsCitizen === true || person?.isUsCitizen === false) ? true : false;

  const isMemberContactComplete = personState?.is16orOlder
    ? (person?.contactInfo?.emailAddress
      && person?.contactInfo?.phoneNumber
      && person?.contactInfo?.address?.address1
      && person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    )
    : (person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    );

  const isMembershipComplete =
    person?.offeringInstanceIds?.length > 0;

  const isEthnicityComplete = person?.hispanicOrLatinoTypeId > 0;

  const arePrerequisitesDone = isIneligible === false && isAboutMemberComplete === true && isMemberContactComplete === true
    && isMembershipComplete === true && isEthnicityComplete === true;

  return {
    ...item,
    path,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getGender;