import { React } from 'react';

import useRegistrantContactInfoSelf from './UseRegistrantContactInfoSelf';
import RegistrantContactInfoForm from '../RegistrantContactInfoForm';
import Views from '../../../Views';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const RegistrantContactInfoSelf = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    validateState
  } = useRegistrantContactInfoSelf();

  return (
    <ViewTemplate
      viewName={Views.REGISTRANT_CONTACT_INFO_SELF}
      onNextButtonClicked={onNextButtonClicked}>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <RegistrantContactInfoForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        setFormData={setFormData} />
    </ViewTemplate>
  );
};

export default RegistrantContactInfoSelf;