import { useEffect, } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useOmrData from '../../state/omr/UseOmrData';

import useMembershipRegistrationHistoryData from '../../../common/state/offeringTypes/UseMembershipRegistrationHistoryData';

const useMemberRegistrationHistory = () => {
  const navigate = useNavigate();
  const { personState } = useOmrData();
  const { getMemberRegistrationHistory, memberRegistrationHistoryState } = useMembershipRegistrationHistoryData();

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(-1);
  };

  useEffect(() => {
    if (personState?.person?.personId) {
      const membersPersonId = personState?.person?.personId;
      getMemberRegistrationHistory(membersPersonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    personState,
    memberRegistrationHistoryState,
    onBackButtonClicked
  };
}

export default useMemberRegistrationHistory;