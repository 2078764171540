import { Fragment } from 'react';

import useOmrData from '../../state/omr/UseOmrData';
import useSummaryButton from './UseSummaryButton';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import { formatDate } from '../../../common/utils/DateFunctions';

import style from './SummaryButton.module.css';
import omrStyle from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const SummaryButton = ({ onNavigateToFinalSummary, }) => {
  const { omrState } = useOmrData();
  const {
    finalSummaryModalState,
    handleSummaryClicked,
    onModalCanceled,
    onNavigateToSummaryClicked
  } = useSummaryButton(onNavigateToFinalSummary);

  return (
    <Fragment>
      {Array.isArray(omrState?.persons) === true
        && omrState?.persons?.length > 0
        && omrState?.persons?.find(x => x.person.isPersonObjCompleted === true)
        ? <div>
          <button className={style.FinalSummaryButton}
            onClick={onNavigateToSummaryClicked}
            type="button"> Summary
          </button>
        </div>
        : <Fragment />}
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={finalSummaryModalState.modalTitle}
        displayPopUp={finalSummaryModalState?.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className="col-xs-12">
            {finalSummaryModalState.firstName !== '' && finalSummaryModalState.lastName !== ''
              ? <Fragment>
                <p className='text-center'>You are about to navigate to the Summary page and lose any unsaved information pertaining to</p>
                <p className={['text-center', global.Bold].join(' ')}>{finalSummaryModalState.firstName}  {finalSummaryModalState.lastName}, ({formatDate(finalSummaryModalState.birthDate)}) </p>
                <p className='text-center'>If you navigate away you will lose an unfinished registration...Are you sure?</p>
              </Fragment>
              : <Fragment>
                <p className='text-center'>You are about to navigate to the summary page and lose any person in flight's information that is not complete.</p>
                <p className='text-center'>If you navigate away you will lose an unfinished registration...Are you sure?</p>
              </Fragment>
            }
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className={["col-xs-12 ", omrStyle.CenterAlign].join(' ')}>
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
            <PrimaryButton type="button" onClick={(e) => { handleSummaryClicked(e) }}>Yes, Go To Summary</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SummaryButton;