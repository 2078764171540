import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from '../../../common/wrappers/ReactRouterDom';

import competitionGenderTypeSelectionValidate from './RenewalCompetitionGenderTypeFormValidation';

import UseOmrData from '../../state/omr/UseOmrData';
import useOmrCookieData from '../../state/omrCookie/UseOmrCookieData';
import useRegisteredMembersData from '../../state/registeredMembers/UseRegisteredMembersData';
import usePersonData from '../../state/landingPages/person/UsePersonData';
import useChildrenData from '../../state/landingPages/children/UseChildrenData';
import useLandingPageData from '../../state/landingPages/UseLandingPageData';
import useOmrBackButtonData from '../../state/omrBackButton/UseOmrBackButtonData';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import useOfferingTypeData from '../../../common/state/offeringTypes/UseOfferingTypeData';
import useGlobalRoutesData from '../../../common/state/security/UseGlobalRoutesData';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { formatDate, getAge } from '../../../common/utils/DateFunctions';
import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';

import Constants from '../../../common/utils/Constants';

const OMR_OFFERING_CATEGORY = 'Member';

const INITIAL_MEMBER_MODAL_STATE = {
  modalTitle: 'Add New Role to Existing Member - Select Member',
  displayPopUp: false
};

const INITIAL_OFFERING_MODAL_STATE = {
  modalTitle: 'Add New Role to Existing Member - Select Role',
  displayPopUp: false,
  member: {}
};

const INITIAL_STATE = {
  errorMessage: '',
  selectedOfferingCheckArray: [],
  allMembershipArray: [],
  selectedOfferingTypeIds: [],
  offeringRestrictions: []
};

const INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'The Selected New Memberships Have Been Added to the Shopping Cart'
};

const INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Select Competition Category (Required When Adding an Athlete Membership)'
};

const INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE = {
  competitionGenderTypeId: Constants.DEFAULT_ID,
  competitionGenderTypeName: '',
};

const useWelcomeMessage = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { registeredMembersState, getRegisteredMembersByPersonId, postMemberRegistrationNewMembership, resetRegisteredMembersState } = useRegisteredMembersData();
  const { omrCookieState, getOmrCookieState } = useOmrCookieData();
  const { routeCodeValueState } = useRouteCodeValueData();
  const { orgUnitState, getBaseOrgUnitByOrgUnitId } = useOrgUnitData();
  const { saveOmrState, getInitializedStateObject, isSaved } = UseOmrData();
  const { offeringTypeState, getOfferingTypesForRegistrationWithRouteCode, resetOfferingTypeState } = useOfferingTypeData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { securityState } = useSecurityData();
  const { personState, getPersonByMemberId } = usePersonData();
  const { childrenState, getChildren } = useChildrenData();
  const { landingPageState, createLandingPageState, setAnonymous } = useLandingPageData();
  const { resetOmrBackButtonState } = useOmrBackButtonData();
  const { UNKNOWN_COMPETITION_GENDER_ID } = useEnvironmentVariableData();
  const [memberModalState, setMemberModalState] = useState(INITIAL_MEMBER_MODAL_STATE);
  const [offeringModalState, setOfferingModalState] = useState(INITIAL_OFFERING_MODAL_STATE);
  const [newRoleConfirmationModalState, setNewRoleConfirmationModalState] = useState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
  const [competitionGenderTypeSelectionModalState, setCompetitionGenderTypeSelectionModalState] = useState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE);
  const [competitionGenderTypeSelectionModalFormState, setCompetitionGenderTypeSelectionModalFormState] = useState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE);
  const [competitionGenderTypeSelectionModalErrorState, setCompetitionGenderTypeSelectionModalErrorState] = useState({});
  const [state, setState] = useState(INITIAL_STATE);
  const [searchParams,] = useSearchParams();
  const isLoading = orgUnitState?.isObjLoaded !== true;
  const lsc = `${orgUnitState?.objData?.parentOrgUnitName || ''} (${orgUnitState?.objData?.parentOrgUnitCode || ''})`;
  const club = `${orgUnitState?.objData?.orgUnitName || ''} (${orgUnitState?.objData?.orgUnitCode || ''})`;
  const lscContact = `${orgUnitState?.objData?.parentContactFirstName ? `${orgUnitState?.objData?.parentContactFirstName} ` : ''}${orgUnitState?.objData?.parentContactLastName ? `${orgUnitState?.objData?.parentContactLastName} ` : ''}${orgUnitState?.objData?.parentContactEmail ? `(${orgUnitState?.objData?.parentContactEmail})` : ''}`;

  const onAddNewRoleToExistingMemberClicked = (e) => {
    e?.preventDefault();

    onOpenMemberModal();
  };

  const onOpenMemberModal = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getRegisteredMembersByPersonId(0);
    setMemberModalState({ ...memberModalState, displayPopUp: true });
  };

  const onMemberModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetRegisteredMembersState();
    setMemberModalState(INITIAL_MEMBER_MODAL_STATE);
  };

  const onSelectClicked = (e, member) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setOfferingModalState({ ...offeringModalState, member: member, displayPopUp: true });
    setMemberModalState(INITIAL_MEMBER_MODAL_STATE);
  };

  const onOfferingModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onOpenMemberModal();
    setOfferingModalState(INITIAL_OFFERING_MODAL_STATE);
    setState(INITIAL_STATE);
    resetOfferingTypeState();
  };

  const onContinueClicked = (e) => {
    e?.preventDefault();
    //straight to omr left view
    document.getElementById('omrHeadingId')?.scrollIntoView();
    const newOmrState = getInitializedStateObject(0, routeCodeValueState.orgUnitId, omrCookieState?.objData?.vendorToken || '');
    saveOmrState(newOmrState);
  };

  const validate = () => {
    if (state.selectedOfferingTypeIds?.length === 0) {
      return 'At least one membership selection is required';
    }

    return '';
  };

  const onSubmit = () => {
    const errorMessage = validate();
    if (errorMessage) {
      setState({ ...state, errorMessage });
    } else {
      let showCompetitionCategoryPopup = false;
      for (let i = 0; i < state.selectedOfferingTypeIds.length; i++) {
        let selectedOfferingObj = offeringTypeState.arrayData?.find(x => x.offeringInstanceId === state.selectedOfferingTypeIds[i]);
        if (selectedOfferingObj?.isAthleteOffering === true && offeringModalState?.member?.competitionGenderTypeId === UNKNOWN_COMPETITION_GENDER_ID) {
          showCompetitionCategoryPopup = true;
        }
      }

      if (showCompetitionCategoryPopup === true) {
        setCompetitionGenderTypeSelectionModalState({
          ...competitionGenderTypeSelectionModalState,
          displayPopUp: true
        })
      }

      else {
        const postObj = {
          personId: offeringModalState.member?.personId,
          orgUnitId: routeCodeValueState.orgUnitId,
          offeringInstanceIds: state.selectedOfferingTypeIds,
          competitionGenderTypeId: offeringModalState.member?.competitionGenderTypeId
        }
        postMemberRegistrationNewMembership(offeringModalState.member?.personId, postObj);
      }
    }
  };

  const onAllMembershipCheck = (checked, offering, offeringInstanceId, offeringId) => {
    const newArray = [...state?.selectedOfferingTypeIds];
    const offeringsCheckArray = [...state?.selectedOfferingCheckArray];
    const restrictionArray = [...state.offeringRestrictions];
    let offeringObj = {
      offeringId: offeringId,
      offeringInstanceId: offeringInstanceId
    }

    if (checked === true) {
      //add to state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index < 0) {
        newArray.push(offeringInstanceId);
        offeringsCheckArray.push({ ...offeringObj });
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction.length > 0) {
          offering?.offeringRestriction.forEach(restriction => {
            if (restriction.offeringId > 0) {
              restrictionArray.push(restriction);
            }
          });
        }
      }
    } else {
      //remove from state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index >= 0) {
        newArray.splice(index, 1);
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction?.length > 0) {
          const offeringIndex = offeringsCheckArray.findIndex(x => x.offeringInstanceId === offeringInstanceId);
          const parentId = offering?.offeringRestriction.find(x => x.offeringParentId > 0).offeringParentId || 0;
          const restrictionArrayToRemove = restrictionArray?.filter(x => x.offeringParentId === parentId);

          if (offeringIndex >= 0) {
            offeringsCheckArray.splice(offeringIndex, 1)
          }
          if (Array.isArray(restrictionArrayToRemove) === true && restrictionArrayToRemove?.length > 0) {
            restrictionArrayToRemove.forEach(restriction => {
              const restrictionIndex = restrictionArray.findIndex(x => x.offeringParentId === parentId);
              if (restrictionIndex >= 0) {
                restrictionArray.splice(restrictionIndex, 1)
              }
            })
          }
        }
      };
    }
    setState({
      ...state,
      selectedOfferingTypeIds: newArray,
      selectedOfferingCheckArray: offeringsCheckArray,
      offeringRestrictions: restrictionArray
    });
  };

  const onCompetitionGenderTypeSelectionContinueClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const competitionGenderTypeSelectionErrors = await competitionGenderTypeSelectionValidate(competitionGenderTypeSelectionModalFormState) || {};
    if (Object.keys(competitionGenderTypeSelectionErrors).length === 0) {
      //save changes
      const postObj = {
        personId: offeringModalState.member?.personId,
        orgUnitId: routeCodeValueState.orgUnitId,
        offeringInstanceIds: state.selectedOfferingTypeIds,
        competitionGenderTypeId: competitionGenderTypeSelectionModalFormState?.competitionGenderTypeId
      }
      postMemberRegistrationNewMembership(offeringModalState.member?.personId, postObj);
      //close dropdown
      onCompetitionGenderTypeSelectionModalCanceled();
    }
    else {
      setCompetitionGenderTypeSelectionModalErrorState(competitionGenderTypeSelectionErrors);
    }
  };

  const onCompetitionGenderTypeSelectionModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setCompetitionGenderTypeSelectionModalState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_STATE);
    setCompetitionGenderTypeSelectionModalFormState(INITIAL_COMPETITION_GENDER_TYPE_SELECTION_MODAL_FORM_STATE);
    setCompetitionGenderTypeSelectionModalErrorState({});
  };

  const onNewRoleConfirmationContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setNewRoleConfirmationModalState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
    onOfferingModalCanceled();
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onNewRoleConfirmationModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setNewRoleConfirmationModalState(INITIAL_NEW_ROLE_CONFIRMATION_MODAL_STATE);
    onOfferingModalCanceled();
  };

  useEffect(() => {
    if (!personState?.objData?.personId
      && securityState?.isObjLoaded === true) {
      if (!securityState?.userInfo?.memberId || searchParams.get('flowId')) {
        setAnonymous();
      } else if (securityState?.userInfo?.memberId !== 'Unregistered') {
        //remove memberId search param, if there is one
        const memberId = searchParams.get('memberId');
        if (memberId) {
          const url = window.location.origin + window.location.pathname;
          window.location.search = '';
          window.location.href = url;
        }
        getPersonByMemberId(securityState?.userInfo?.memberId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  useEffect(() => {
    resetOmrBackButtonState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personState?.objData?.personId) {
      getChildren(personState?.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, securityState]);

  useEffect(() => {
    if (childrenState.isArrayLoaded === true) {
      createLandingPageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenState.isArrayLoaded, securityState]);

  useEffect(() => {
    if (omrCookieState.isObjLoaded !== true) {
      getOmrCookieState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrCookieState]);

  useEffect(() => {
    if (routeCodeValueState.isObjLoaded === true && orgUnitState.isObjLoaded !== true && routeCodeValueState.orgUnitId) {
      getBaseOrgUnitByOrgUnitId(routeCodeValueState.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeCodeValueState, orgUnitState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      navigate(navRoutes.ENTRY.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  useEffect(() => {
    if (offeringModalState.displayPopUp === true) {
      const orgUnitId = orgUnitState.objData.orgUnitId;
      const memberAge = getAge(formatDate(offeringModalState?.member?.birthDate));
      const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
      const personId = offeringModalState?.member?.personId;
      const personIdForUrl = personId ? encodeURIComponent(personId) : '';

      if (offeringTypeState.isArrayLoading !== true
        && (
          offeringTypeState.orgUnitId !== orgUnitIdForUrl
          || offeringTypeState.age !== memberAge
          || offeringTypeState.category !== OMR_OFFERING_CATEGORY
        )) {

        getOfferingTypesForRegistrationWithRouteCode(orgUnitIdForUrl, memberAge, OMR_OFFERING_CATEGORY, routeCodeValueState.offeringInstanceCouponId, personIdForUrl);

      } else if (offeringTypeState.isArrayLoading !== true && offeringTypeState.errorMessage) {

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringTypeState, offeringModalState]);

  useEffect(() => {
    if (registeredMembersState.isSaved === true) {
      setNewRoleConfirmationModalState({ ...newRoleConfirmationModalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredMembersState.isSaved])

  return {
    isLoading,
    lsc,
    club,
    lscContact,
    registeredMembersState,
    memberModalState,
    offeringModalState,
    state,
    showNoMembershipsAvailable: offeringTypeState?.isArrayLoaded === true && offeringTypeState?.arrayData.length === 0,
    offeringTypeState,
    restrictions: state?.offeringRestrictions,
    routeCodeValueState,
    onAllMembershipCheck,
    onAddNewRoleToExistingMemberClicked,
    onMemberModalCanceled,
    onOfferingModalCanceled,
    onContinueClicked,
    onAddNewMembershipClicked: onSubmit,
    onSelectClicked,
    newRoleConfirmationModalState,
    onNewRoleConfirmationModalCanceled,
    onNewRoleConfirmationContinueClicked,
    stateStatus: landingPageState.stateStatus,
    competitionGenderTypeSelectionModalState,
    setCompetitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalFormState,
    competitionGenderTypeSelectionModalErrorState,
    onCompetitionGenderTypeSelectionContinueClicked,
    onCompetitionGenderTypeSelectionModalCanceled
  };
};

export default useWelcomeMessage;