import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../common/utils/DateFunctions';

const GridRow = ({ member, onSelectClicked }) => {
  return (
    <tr key={'personId' + member.personId + 'offeringInstanceId' + member.offeringInstanceId}>
      <td>{(member.firstName || member.middleName || member.lastName) ? `${member.firstName || ''}  ${member.preferredName !== '' && member.preferredName !== member.firstName ? '"' + member.preferredName + '"' : ''} ${member.middleName || ''} ${member.lastName || ''}` : ''}</td>
      <td>{member.competitionGenderTypeName || ''}</td>
      <td>{member.birthDate ? formatDate(member.birthDate) : ''}</td>
      <td><span className='pull-right'><ActionIntraPageButton onClick={(e) => onSelectClicked(e, member)}>Select</ActionIntraPageButton></span></td>
    </tr>
  );
};

const SelectMemberLargeGrid = ({ gridData, isLoading, onSelectClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        <th>Birth Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"4"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((member, i) =>
            <GridRow
              key={i}
              member={member}
              onSelectClicked={onSelectClicked}
            />)
          :
          <tr>
            <td colSpan={"4"}>No Existing Members are Eligible for New Roles</td>
          </tr>
      }
    </tbody>
  </table>
);

export default SelectMemberLargeGrid;