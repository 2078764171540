import getInitialState from './GetInitialState';
import NavLinks from '../../NavLinks';
import Views from '../../Views';
import Constants from '../../../common/utils/Constants';

const getSummary = (viewName, personState, securityState) => {
  const person = personState?.person || {};

  const path = NavLinks.MEMBER_SUMMARY;

  const isCurrent = viewName === Views.MEMBER_SUMMARY;

  const item = getInitialState(Views.MEMBER_SUMMARY);

  const isIneligible = (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
    personState?.isMembershipForLoggedInUser === false) ? false : true;

  const isAboutMemberComplete = person?.firstName && person?.lastName
    && person?.birthDate && person?.birthDate !== Constants.BLANK_DATE_STRING
    && (person?.isUsCitizen === true || person?.isUsCitizen === false) && isIneligible === false ? true : false;

  const isMemberContactComplete = personState?.is16orOlder
    ? (person?.contactInfo?.emailAddress
      && person?.contactInfo?.phoneNumber
      && person?.contactInfo?.address?.address1
      && person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    )
    : (person?.primaryContactInfo?.emailAddress
      && person?.primaryContactInfo?.phoneNumber
      && person?.primaryContactInfo?.address?.address1
      ? true : false
    );

  const isMembershipComplete =
    person?.offeringInstanceIds?.length > 0;

  const isEthnicityComplete = person?.hispanicOrLatinoTypeId > 0;

  const isGenderComplete =
    (person?.hasAthleteOffering === true && person?.competitionGenderTypeId > 0) === true ?
      true : (person?.hasAthleteOffering === false && person?.genderIdentityTypeId > 0) === true ? true : false;

  // const isDisabilityComplete = person?.personDisabilities?.length > 0;

  const arePrerequisitesDone = isAboutMemberComplete === true && isMemberContactComplete === true
    && isMembershipComplete === true && isEthnicityComplete === true /* && isDisabilityComplete === true */ && isGenderComplete === true && isIneligible === false;

  const isComplete = arePrerequisitesDone === true && isIneligible === false;

  return {
    ...item,
    path,
    isCurrent,
    isComplete,
    arePrerequisitesDone
  };
};

export default getSummary;