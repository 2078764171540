import { useContext, useState } from 'react';
import { ChildrenStateContext } from './ChildrenContextProvider';
import ChildrenData from './ChildrenData';

const useChildrenData = () => {
  const [childrenState, setChildrenState] = useContext(ChildrenStateContext);
  const [adminParentChildState, setAdminParentChildState] = useState(ChildrenData.INITIAL_STATE);

  const getChildren = (personId) => {
    if (personId && childrenState.isArrayLoading !== true && childrenState.isArrayLoaded !== true) {
      ChildrenData.getChildren(personId, childrenState, setChildrenState);
    }
  };

  const getChildrenAfterFamilyMemberChanges = (personId) => {
    ChildrenData.getChildren(personId, childrenState, setChildrenState);
  };

  const getChildrenForParentAdmin = (personId) => {
    if (personId && adminParentChildState.isArrayLoading !== true && adminParentChildState.isArrayLoaded !== true) {
      ChildrenData.getChildren(personId, adminParentChildState, setAdminParentChildState);
    }
  };

  const clearChildrenForParentAdminState = () => {

    setAdminParentChildState({ ...ChildrenData?.INITIAL_STATE });
  }


  return {
    childrenState,
    getChildren,
    getChildrenAfterFamilyMemberChanges,
    getChildrenForParentAdmin,
    adminParentChildState,
    setAdminParentChildState,
    clearChildrenForParentAdminState
  };
};

export default useChildrenData;