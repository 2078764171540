import React from 'react';
import NavLinks from '../../NavLinks';
import useOmrData from '../../state/omr/UseOmrData';
import Ethnicity from './Ethnicity';

const EthnicityO15 = () => {
    const { personState, hasAthleteOffering } = useOmrData();
    let path = ''
    if (hasAthleteOffering(personState)) {
        path = NavLinks.ATHLETE_COMP_GENDER_16_AND_UP;
    } else {
        path = NavLinks.GENDER_IDENTITY_16_AND_UP;
    }
    return (
        <Ethnicity navigateLink={path} />
    )
}
export default EthnicityO15;