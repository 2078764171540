import React from 'react';

import usePrimaryContactO15Self from './UsePrimaryContactO15Self';
import PrimaryContactForm from '../PrimaryContactInfoForm';

import NavLinks from '../../../NavLinks';
import Views from '../../../Views';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';
import AddressBook from '../../../components/AddressBook/AddressBook';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import style from '../../Omr.module.css';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const PrimaryContactO15Self = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    validateState,
    onActionIntraPageButtonClicked
  } = usePrimaryContactO15Self();

  function onActionIntraPageButtonClickedProxy() {
    onActionIntraPageButtonClicked();
    formState.showedAddressSuggestion = true;
  }

  return (
    <ViewTemplate
      viewName={Views.PRIMARY_CONTACT_O15_SELF}
      onNextButtonClicked={onNextButtonClicked}>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <AddressBook navlink={NavLinks.PRIMARY_CONTACT_INFO_O15_SELF} />
      <PrimaryContactForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged} 
        setFormData={setFormData} />
      {/* <div className={style.AddMoreContactsButton}>
        <ActionIntraPageButton onClick={onActionIntraPageButtonClickedProxy}>Add More Contacts</ActionIntraPageButton>
      </div> */}
    </ViewTemplate>
  );
};

export default PrimaryContactO15Self;