import { OmrHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  personId: Constants.DEFAULT_ID,
  offeringInstanceId: Constants.DEFAULT_ID,
  orgUnitId: Constants.DEFAULT_ID
};

const getRenewalOptionData = (state, setState, personId, offeringInstanceId, orgUnitId) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      personId: personId,
      offeringInstanceId: offeringInstanceId,
      orgUnitId: orgUnitId
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const offeringInstanceIdForUrl = offeringInstanceId ? encodeURIComponent(offeringInstanceId) : 'NaN';
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';

    const url = `/Offering/person/${personIdForUrl}/OfferingInstance/${offeringInstanceIdForUrl}/orgUnit/${orgUnitIdForUrl}/RenewalOption`;
    OmrHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const options = data.map((o) => { return { id: o.offeringInstanceId, name: o.offeringName }; });
          options.unshift({ id: Constants.DEFAULT_ID, name: '--' });

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options,
            message: '',
            personId: personId,
            offeringInstanceId: offeringInstanceId,
            orgUnitId: orgUnitId
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const RenewalOptionData = { INITIAL_STATE, getRenewalOptionData };

export default RenewalOptionData;