import { useContext } from 'react';

import { TempPersonStateContext } from './TempPersonContextProvider';
import TempPersonData from './TempPersonData';

const useTempPersonData = () => {
  const [omrTempPersonState, setOmrTempPersonState] = useContext(TempPersonStateContext);

  const getPersonByMemberId = (memberId) => {
    TempPersonData.getPersonByMemberId(memberId, omrTempPersonState, setOmrTempPersonState);
  };


  const resetTempPersonState = () => {
    setOmrTempPersonState(TempPersonData.INITIAL_STATE);
  };

  return {
    getPersonByMemberId,
    resetTempPersonState,
    omrTempPersonState,
    setOmrTempPersonState
  };
};

export default useTempPersonData;