import { Fragment } from 'react';

import useLeftNavigation from './UseLeftNavigation';

import LeftNav from '../../../common/components/leftNav/LeftNav';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';

import style from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const LeftNavigation = ({ viewName, children }) => {
  const { items, personName, onNavigateToPath } = useLeftNavigation(viewName);
  const { leftNavModalState, onLeftNavModalCanceled } = useLeftNavModalData();

  if (Array.isArray(items) && items.length > 0) {
    return (
      <Fragment>
        <LeftNav formattedTitle={(
          <div className={style.RegFor}>
            <Headings.H5>Registration For:</Headings.H5>
            <Headings.H6>{personName}</Headings.H6>
          </div>
        )} state={items}
          validateBeforeRedirect={true}>
          {children}
        </LeftNav>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={leftNavModalState.modalTitle}
          displayPopUp={leftNavModalState.displayPopUp}
          onModalCanceled={onLeftNavModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p className={['text-center', global.Bold].join(' ')}>You are about to navigate away from this page without saving...</p>
              <p className='usas-extra-top-marign usas-extra-bottom-margin'></p>
              <p className='text-center'>Are you sure you want to continue? The information you entered has not been saved!</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onLeftNavModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
              <PrimaryButton type="button" onClick={(e) => onNavigateToPath(e, leftNavModalState.path)}>Continue</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
      </Fragment>
    );
  } else {
    return (
      <LeftNav>
        {children}
      </LeftNav>
    );
  }
};

export default LeftNavigation;