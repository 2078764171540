import { useState } from 'react';
import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  expandedId: Constants.DEFAULT_ID
};

const useMembershipTypes = () => {
  const [state, setState] = useState(INITIAL_STATE);

  const onDetailsToggle = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (state?.expandedId === id) {
      if (state.expandedId !== Constants.DEFAULT_ID) {
        setState({
          ...state,
          expandedId: Constants.DEFAULT_ID
        });
      }
    } else {
      setState({
        ...state,
        expandedId: id
      });
    }
  };

  return {
    expandedId: state.expandedId,
    onDetailsToggle
  };
};

export default useMembershipTypes;