import React, { createContext, useState } from 'react';

import AddressBookData from './AddressBookData';

export const AddressBookStateContext = createContext();

const AddressBookContextProvider = ({ children }) => {
  const stateHook = useState(AddressBookData.INITIAL_STATE);

  return (
    <AddressBookStateContext.Provider value={stateHook}>
      {children}
    </AddressBookStateContext.Provider>
  );
};

export default AddressBookContextProvider;