import React, { Fragment } from 'react';

import style from '../Omr.module.css';

const ExpiredOmrLink = () => {

  return (
    <Fragment>
      <div className={['row usas-extra-top-margin', style.BottomMargin].join('')}>
        <div className={style.CenterAlign}>
          The link that you are using to register for a USA Swimming membership is expired and no longer valid. Please contact your club representative for another link, before starting your registration.
        </div>
      </div>
    </Fragment>
  );
};

export default ExpiredOmrLink;