import React, { createContext, useState } from 'react';

import OmrData from './OmrData';

export const OmrStateContext = createContext();

const OmrContextProvider = ({ children }) => {
  const stateHook = useState(OmrData.INITIAL_STATE);

  return (
    <OmrStateContext.Provider value={stateHook}>
      {children}
    </OmrStateContext.Provider>
  );
};

export default OmrContextProvider;