import Checkbox from '../checkboxes/Checkbox';
import ActionIntraPageButton from '../buttons/ActionIntraPageButton';

import style from './RenewMemberships.module.css';
import global from '../GlobalStyle.module.css';

import { formatDate } from '../../utils/DateFunctions';
import ExclamationIcon from '../icons/ExclamationIcon';
import { Fragment } from 'react';

const GridRow = ({ omrView, membership, onCheckboxChange, onChangeMemberTypeClicked, onShowRenewalConfirmationModalClicked }) => {
  return (
    <tr key={'personId' + membership.personId + 'offeringInstanceId' + membership.offeringInstanceId} className={membership.hasPreviousRegistration === false ? style.IsSelected : style.NotSelected}>
      <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{(membership.firstName || membership.middleName || membership.lastName) ? `${membership.firstName || ''}  ${membership.preferredName !== '' && membership.preferredName !== membership.firstName ? '"' + membership.preferredName + '"' : ''} ${membership.middleName || ''} ${membership.lastName || ''}` : ''}</span></td>
      <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.competitionGenderTypeName || ''}</span></td>
      <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.birthDate ? formatDate(membership.birthDate) : ''}</span></td>
      {omrView === false && <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.renewalOrgUnitName || ''} {membership.renewalOrgUnitName === 'Unattached' ? ' - ' + membership.renewalParentOrgUnitName : ''} {membership.renewalParentOrgUnitCode ? '(' + membership.renewalParentOrgUnitCode + ')' : ''}</span></td>}
      <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.offeringName || ''} {membership.currentExpirationDate ? `(${formatDate(membership.currentExpirationDate)})` : ''}</span></td>
      <td><span className={membership.orgUnitId !== membership.renewalOrgUnitId ? style.WarningMsg : ""}>{membership.renewalOfferingName || ''} {membership.renewalExpirationDate ? `(${formatDate(membership.renewalExpirationDate)})` : ''} {membership.hasPreviousRegistration === true && membership.renewalOfferingInstanceId === null && <b>Not Offered at This Time</b>}</span></td>
      {omrView === true && <td>{membership.orgUnitId !== membership.renewalOrgUnitId ? <span><b><span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='Warning! Your renewal membership team is different than your prior membership team. By renewing with this link you are transferring this membership to a different team.' />&nbsp;Yes</span></b></span> : 'No'}</td>}
      <td>
        <div className='row'>
          {membership.isClubActive === true ?
            <Fragment>
              {membership.hasPreviousRegistration === true ?
                <Fragment>
                  {membership.clubBulkRenewalOptInt === false ?
                    <Fragment>
                      {membership.renewalOfferingInstanceId !== null ?
                        <div className='col-xs-12 col-md-2'>
                          <Checkbox
                            label=""
                            name="isSelected"
                            onChange={(e) => { onCheckboxChange(e, membership.personId, membership.offeringInstanceId) }}
                            checked={membership.isSelected || false} />
                        </div> :
                        <div className='col-xs-12 col-md-2'></div>}
                    </Fragment > :
                    <div className='col-xs-12'>
                      <label>Team is Renewing</label>
                    </div>
                  }
                </Fragment>
                :
                <Fragment>
                  {omrView === true ?
                    <div className='col-xs-12' style={{ marginTop: '5px', minWidth: '320px' }}>
                      <ActionIntraPageButton onClick={(e) => onShowRenewalConfirmationModalClicked(e, membership)}>Provide Missing Info & Renew</ActionIntraPageButton>
                    </div> :
                    <div className='col-xs-12'>
                      <label>Not Eligible for One Button Renew - Contact Team for Registration Link</label>
                    </div>}
                </Fragment>}
            </Fragment> :
            <Fragment >
              {omrView === true ?
                <div className='col-xs-12'>
                  <label>Team is Expired - Not Eligible for One Button Renew</label>
                </div> :
                <Fragment>
                  {membership.hasPreviousRegistration === true ?
                    <div className='col-xs-12'>
                      <label>Team is Expired - Contact Team for Instructions</label>
                    </div> :
                    <div className='col-xs-12'>
                      <label>Not Eligible for One Button Renew - Contact Team for Registration Link</label>
                    </div>}
                </Fragment>
              }
            </Fragment>}
          {membership.isEditRenewalAvailable === true && membership.clubBulkRenewalOptInt === false && membership.isClubActive === true &&
            <div className='col-xs-12 col-md-10' style={{ marginTop: '5px', minWidth: '200px' }}><ActionIntraPageButton onClick={(e) => onChangeMemberTypeClicked(e, membership)}>Change Member Type</ActionIntraPageButton>    </div>}
        </div>
      </td>
    </tr>
  );
};

const RenewMembershipsSelectionLargeGrid = ({ omrView, gridData, isLoading, onCheckboxChange, onYesNoSwitchChange,
  allowRelayOnly, onChangeMemberTypeClicked, onShowRenewalConfirmationModalClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th style={{ minWidth: '120px' }}>Comp. Category</th>
        <th>Birth Date</th>
        {omrView === false && <th>Team Name</th>}
        <th>Prior Membership (Expires)</th>
        <th>Renewal Membership (Expires)</th>
        {omrView === true && <th>Transfer?</th>}
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"7"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((membership, i) =>
            <GridRow
              key={i}
              omrView={omrView}
              membership={membership}
              onCheckboxChange={onCheckboxChange}
              onYesNoSwitchChange={onYesNoSwitchChange}
              allowRelayOnly={allowRelayOnly}
              onChangeMemberTypeClicked={onChangeMemberTypeClicked}
              onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked} />)
          :
          <tr>
            <td colSpan={"7"}>No Memberships are Eligible for Renewal</td>
          </tr>
      }
    </tbody>
  </table>
);

export default RenewMembershipsSelectionLargeGrid;