import InitialState from './InitialState';
import postNewMemberRegistration from './PostMemberRegistrationData';
import postMemberRegistrationSummary from './PostMemberRegistrationSummaryData';

const OmrData = {
  INITIAL_STATE: InitialState.INITIAL_STATE,
  PERSON_INITIAL_STATE: InitialState.PERSON_INITIAL_STATE,
  postNewMemberRegistration,
  postMemberRegistrationSummary
};

export default OmrData;