import React, { Fragment } from 'react';

import useAdditionalContactsGrid from './UseAdditionalContactsGrid';
import LargeAdditionalContactsGrid from './LargeAdditionalContactsGrid';
import SmallAdditionalContactsGrid from './SmallAdditionalContactsGrid';

const AdditionalContactsGrid = ({ onEdit, onDeleteClicked, onNavigateToEmergencyContact }) => {
  const { personState } = useAdditionalContactsGrid();
  return (
    <Fragment>
      <LargeAdditionalContactsGrid
        state={personState?.person?.personContacts || []}
        onEdit={onEdit}
        onDeleteClicked={onDeleteClicked}
        onNavigateToEmergencyContact={onNavigateToEmergencyContact} />
      <SmallAdditionalContactsGrid
        state={personState?.person?.personContacts || []}
        onEdit={onEdit}
        onDeleteClicked={onDeleteClicked}
        onNavigateToEmergencyContact={onNavigateToEmergencyContact} />
    </Fragment>
  );
}
export default AdditionalContactsGrid;