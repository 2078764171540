import { useState } from 'react';

import MemberRegistrationRenewalData from './MemberRegistrationRenewalData';

const useMemberRegistrationRenewalData = () => {
  const [memberRegistrationRenewalState, setMemberRegistrationRenewalState] = useState(MemberRegistrationRenewalData.INITIAL_STATE);

  const getMemberRegistrationRenewalOptions = (personId, orgUnitId, offeringInstanceCouponId, isRenewalAvailable) => {
    MemberRegistrationRenewalData.getMemberRegistrationRenewalOptionsData(personId, orgUnitId, offeringInstanceCouponId, isRenewalAvailable, memberRegistrationRenewalState, setMemberRegistrationRenewalState);
  };

  const postMemberRegistrationRenewal = (personId, arrayData) => {
    MemberRegistrationRenewalData.postMemberRegistrationRenewalData(personId, arrayData, memberRegistrationRenewalState, setMemberRegistrationRenewalState);
  };

  const resetMemberRegistrationRenewalState = () => {
    setMemberRegistrationRenewalState(MemberRegistrationRenewalData.INITIAL_STATE);
  }

  return {
    memberRegistrationRenewalState,
    setMemberRegistrationRenewalState,
    getMemberRegistrationRenewalOptions,
    postMemberRegistrationRenewal,
    resetMemberRegistrationRenewalState
  };
};

export default useMemberRegistrationRenewalData;