import React, { Fragment } from 'react';

import { convertNumberToCurrencyString } from '../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowContent = ({ memberHistory }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Membership Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {memberHistory?.offeringName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        LSC Code/Club Name
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {memberHistory.lscCode} | {memberHistory.clubName}-({memberHistory.clubCode})
      </div>
      <div className={['col-xs-6 col-sm-3 ', global.SmallTableRowLabels].join(' ')}>
        Registration Date
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(memberHistory.effectiveDate)}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Price
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {convertNumberToCurrencyString(memberHistory.price)}
      </div>
    </div>
  </div>
);

const GridRow = ({ memberHistory }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{memberHistory?.offeringName}</span>&nbsp;
        </div>
        <GridRowContent memberHistory={memberHistory} />
      </div>
    </Fragment>
  );
}
const SmallMemberRegistrationHistoryGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true
            ? <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No Results</div>}
        </div>
      </div>)
      : Array.isArray(state?.arrayData) === true
        && state?.arrayData?.length > 0
        ? state?.arrayData?.map((memberHistory, i) =>
          <GridRow
            key={i}
            memberHistory={memberHistory}
          />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallMemberRegistrationHistoryGrid;