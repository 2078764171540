import React, { Fragment } from 'react';

import LargeExistingMemberGrid from './LargeExistingMemberGrid';
import SmallExistingMemberGrid from './SmallExistingMemberGrid';

import global from '../../../common/components/GlobalStyle.module.css';

const ExistingMemberSearchGrid = ({ state, onSelectMember, onFiltersToggle, showFilters, showSearchResultCount = true }) => {
  return (
    <Fragment>
      {state.isArrayLoaded !== false
        ? (
          <div className="row">
            {showSearchResultCount === true && (
              <div className="col-xs-12 col-sm-8">
                {state.arrayData.length > 0
                  ? <p>{state.arrayData.length === 1
                    ? `${state.arrayData.length} Search Result`
                    : `${state.arrayData.length} Search Results`}</p>
                  : <Fragment />}
              </div>
            )}
            {onFiltersToggle
              ? <div className="col-xs-12 col-sm-4">
                <button
                  className={global.ToggleButton}
                  type="button"
                  onClick={onFiltersToggle}>{showFilters === true ? 'Hide Filters' : 'Show Filters'}
                </button>
              </div>
              : <Fragment />}
          </div>)
        : <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          </div>
        </Fragment >}
      <LargeExistingMemberGrid
        state={state}
        onSelectMember={onSelectMember}
        onFiltersToggle={onFiltersToggle}
        showFilters={showFilters} />
      <SmallExistingMemberGrid
        state={state}
        onSelectMember={onSelectMember}
        onFiltersToggle={onFiltersToggle}
        showFilters={showFilters} />
    </Fragment>
  )
};

export default ExistingMemberSearchGrid;
