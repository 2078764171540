import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  isSaved: false,
  action: '',
  loggedInPersonId: '',
  orgUnitId: '',
  currentIndex: Constants.DEFAULT_ID,
  persons: []
};

const PERSON_INITIAL_STATE = {
  isMembershipForLoggedInUser: null,
  isExistingPerson: null,
  is16orOlder: null,
  offeringInstances : [],
  searchFilters: {
    firstName: '',
    lastName: '',
    birthDate: Constants.BLANK_DATE_STRING
  },
  person: {
    personId: '',
    memberId: 'new',
    firstName: '',
    lastName: '',
    birthDate: Constants.BLANK_DATE_STRING,
    middleName: '',
    suffix: '',
    preferredName: '',
    isUsCitizen: null,
    contactInfo: {
      emailAddress: '',
      phoneNumber: '',
      phoneTypeId: Constants.DEFAULT_ID,
      address: {
        address1: '',
        address2: '',
        city: '',
        stateCode: '',
        stateName: '',
        postalCode: '',
        countryCode: ''
      }
    },
    primaryContactInfo: {
      firstName: '',
      lastName: '',
      contactRelationshipTypeId: Constants.DEFAULT_ID,
      contactRelationshipName: '',
      emailAddress: '',
      phoneNumber: '',
      phoneTypeId: Constants.DEFAULT_ID,
      address: {
        address1: '',
        address2: '',
        city: '',
        stateCode: Constants.DEFAULT_ID,
        stateName: '',
        postalCode: '',
        countryCode: ''
      }
    },
    personContacts: [],
    hispanicOrLatinoTypeId: Constants.DEFAULT_ID,
    personEthnicity: [],
    offeringInstanceIds: [],
    offeringInstanceCouponId: Constants.DEFAULT_ID,
    //personDisabilities: [],
    competitionGenderTypeId: Constants.DEFAULT_ID,
    competitionGenderTypeName: '',
    genderIdentityTypeId: Constants.DEFAULT_ID,
    isPersonObjCompleted: null,
    personAcknowledgement: null,
    esignature: null
  }
};

const InitialState = { INITIAL_STATE, PERSON_INITIAL_STATE };

export default InitialState;