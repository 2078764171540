import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useOmrData from '../../state/omr/UseOmrData';
import NavLinks from '../../NavLinks';

import { getAge } from '../../../common/utils/DateFunctions';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';
import useOfferingTypeData from '../../../common/state/offeringTypes/UseOfferingTypeData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';

const OMR_OFFERING_CATEGORY = 'Member';
const INITIAL_STATE = {
  errorMessage: '',
  selectedOfferingCheckArray: [],
  allMembershipArray: [],
  selectedOfferingTypeIds: [],
  offeringRestrictions: []
};

const useMembershipTypesO15 = () => {
  const navigate = useNavigate();
  const { orgUnitState } = useOrgUnitData();
  const { offeringTypeState, getOfferingTypesForRegistrationWithRouteCode } = useOfferingTypeData();
  const { personState, savePersonState, isSaved, hasAthleteOffering } = useOmrData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { routeCodeValueState } = useRouteCodeValueData();
  const [state, setState] = useState(INITIAL_STATE);

  const validate = () => {
    if (state.selectedOfferingTypeIds.length === 0) {
      return 'At least one membership selection is required';
    }

    return '';
  };

  const onSubmit = () => {
    const errorMessage = validate();
    if (errorMessage) {
      setState({ ...state, errorMessage });
    } else {
      const offeringInstanceIds = [...state.selectedOfferingTypeIds];

      const personStateCopy = JSON.parse(JSON.stringify(personState));
      //const originalOfferingState = personStateCopy.person.hasAthleteOffering;
      const newPersonStateAthleteOfferingCheck = {
        ...personStateCopy,
        person: {
          ...personStateCopy?.person,
          offeringInstanceIds
        }
      }

      const newPerson = {
        ...personState,
        person: {
          ...personState?.person,
          offeringInstanceIds,
          hasAthleteOffering: hasAthleteOffering(newPersonStateAthleteOfferingCheck),
        }
      };

      savePersonState(newPerson);
    }
  };


  const onBackToWelcomeButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.HOME);
  };

  const onShowMembershipHistory = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(NavLinks.MEMBERSHIP_REGISTRATION_HISTORY);
  };


  const onAllMembershipCheck = (checked, offering, offeringInstanceId, offeringId) => {
    const newArray = [...state?.selectedOfferingTypeIds];
    const offeringsCheckArray = [...state?.selectedOfferingCheckArray];
    const restrictionArray = [...state.offeringRestrictions];
    let offeringObj = {
      offeringId: offeringId,
      offeringInstanceId: offeringInstanceId
    }

    if (checked === true) {
      //add to state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index < 0) {
        newArray.push(offeringInstanceId);
        offeringsCheckArray.push({ ...offeringObj });
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction.length > 0) {
          offering?.offeringRestriction.forEach(restriction => {
            if (restriction.offeringId > 0) {
              restrictionArray.push(restriction);
            }
          });
        }
      }
    } else {
      //remove from state array
      const index = newArray.indexOf(offeringInstanceId);
      if (index >= 0) {
        newArray.splice(index, 1);
        if (Array.isArray(offering?.offeringRestriction) === true && offering?.offeringRestriction?.length > 0) {
          const offeringIndex = offeringsCheckArray.findIndex(x => x.offeringInstanceId === offeringInstanceId);
          const parentId = offering?.offeringRestriction.find(x => x.offeringParentId > 0).offeringParentId || 0;
          const restrictionArrayToRemove = restrictionArray?.filter(x => x.offeringParentId === parentId);

          if (offeringIndex >= 0) {
            offeringsCheckArray.splice(offeringIndex, 1)
          }
          if (Array.isArray(restrictionArrayToRemove) === true && restrictionArrayToRemove?.length > 0) {
            restrictionArrayToRemove.forEach(restriction => {
              const restrictionIndex = restrictionArray.findIndex(x => x.offeringParentId === parentId);
              if (restrictionIndex >= 0) {
                restrictionArray.splice(restrictionIndex, 1)
              }
            })
          }
        }
      };
    }
    setState({
      ...state,
      selectedOfferingTypeIds: newArray,
      selectedOfferingCheckArray: offeringsCheckArray,
      offeringRestrictions: restrictionArray
    });
  };


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (Array.isArray(personState.person?.offeringInstanceIds) === true && personState.person.offeringInstanceIds.length > 0) {
        if (isSaved === false && state.selectedOfferingTypeIds.length > 0) {

          if (Array.isArray(state.selectedOfferingTypeIds) === true && state.selectedOfferingTypeIds.length > 0) {
            state.selectedOfferingTypeIds.forEach(id => {
              if (!personState.person.offeringInstanceIds.includes(id)) {

                setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
              }
            })
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, personState, isSaved]);


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (Array.isArray(personState.person?.offeringInstanceIds) === true
        && personState.person.offeringInstanceIds.length === 0) {
        if (isSaved === false
          && (state.selectedOfferingTypeIds.length === 0)) {
          setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, personState, isSaved]);


  useEffect(() => {
    const orgUnitId = orgUnitState.objData.orgUnitId;
    const memberAge = getAge(personState.person?.birthDate);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const personId = personState?.person?.personId;
    const personIdForUrl = personId ? encodeURIComponent(personId) : '';

    if (offeringTypeState.isArrayLoading !== true
      && (
        offeringTypeState.orgUnitId !== orgUnitIdForUrl
        || offeringTypeState.age !== memberAge
        || offeringTypeState.category !== OMR_OFFERING_CATEGORY
      )) {

      getOfferingTypesForRegistrationWithRouteCode(orgUnitIdForUrl, memberAge, OMR_OFFERING_CATEGORY, routeCodeValueState.offeringInstanceCouponId, personIdForUrl);

    } else if (offeringTypeState.isArrayLoading !== true && offeringTypeState.errorMessage) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringTypeState]);;

  useEffect(() => {
    if (offeringTypeState.isArrayLoaded === true &&
      Array.isArray(offeringTypeState?.arrayData) === true && offeringTypeState?.arrayData?.length > 0) {

      savePersonState({
        ...personState,
        offeringInstances: offeringTypeState?.arrayData || [],
      }, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringTypeState]);


  useEffect(() => {
    if (Array.isArray(personState?.person?.offeringInstanceIds) === true && personState?.person?.offeringInstanceIds?.length > 0) {
      const selectedOfferingTypeIds = [];
      const offeringRestrictions = [];
      personState.person.offeringInstanceIds.forEach(offeringInstanceId => {
        const selectedOfferings = offeringTypeState?.arrayData.find(x => x.offeringInstanceId === offeringInstanceId);
        if (selectedOfferings) {
          selectedOfferingTypeIds.push(selectedOfferings.offeringInstanceId);
        }
      });

      selectedOfferingTypeIds.forEach(offering => {
        const offeringInstanceAndRestrictions = personState?.offeringInstances?.find(x => x.offeringInstanceId === offering)
        if (Object?.keys?.(offeringInstanceAndRestrictions).length > 0) {
          if (Array.isArray(offeringInstanceAndRestrictions?.offeringRestriction) === true && offeringInstanceAndRestrictions?.offeringRestriction?.length > 0) {
            offeringInstanceAndRestrictions?.offeringRestriction?.forEach(restriction => {
              if (restriction.offeringId > 0) {
                offeringRestrictions.push(restriction);
              }
            })
          }
        }
      })

      setState({ ...state, selectedOfferingTypeIds, offeringRestrictions });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
      navigate(NavLinks.ETHNICITY_16_AND_UP);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    displayPopUp: offeringTypeState?.isArrayLoading === true,
    showNoMembershipsAvailable: offeringTypeState?.isArrayLoaded === true && offeringTypeState?.arrayData.length === 0,
    personState,
    state,
    offeringTypeState,
    onBackToWelcomeButtonClicked,
    onShowMembershipHistory,
    onNextButtonClicked: onSubmit,
    disabledAthleteId: state?.disabledAthleteId,
    disabledOtherId: state.disabledOtherId,
    onAllMembershipCheck,
    restrictions: state?.offeringRestrictions,

  };
};

export default useMembershipTypesO15;