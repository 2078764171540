import { useEffect, useState } from 'react';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useOmrCookieData from '../../state/omrCookie/UseOmrCookieData';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';

const INITIAL_STATE = {
  isRouteCodeValueZero: false
};

const useWelcome = () => {
  const { securityState } = useSecurityData();
  const { omrCookieState, getOmrCookieState } = useOmrCookieData();
  const { routeCodeValueState, getRouteCodeValue } = useRouteCodeValueData();
  const [state, setState] = useState(INITIAL_STATE);
  const isLoggedIn = securityState?.userInfo?.isLoggedIn === true;

  useEffect(() => {
    if (omrCookieState.isObjLoaded !== true) {
      getOmrCookieState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrCookieState]);

  useEffect(() => {
    if (isLoggedIn === true && omrCookieState.objData.routeCode && routeCodeValueState.isObjLoaded !== true) {
      getRouteCodeValue(omrCookieState.objData.routeCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrCookieState, routeCodeValueState]);

  useEffect(() => {
    if (Object?.keys(routeCodeValueState?.objData).length > 0 && routeCodeValueState?.isObjLoaded === true) {
      if (routeCodeValueState?.objData?.routeCodeId === 0 && state?.isRouteCodeValueZero === false) {

        setState({ ...state, isRouteCodeValueZero: true });
      }
    }
  }, [routeCodeValueState, state]);

  return {
    isLoggedIn,
    isRouteCodeValueZero: state?.isRouteCodeValueZero,
    routeCodeValueState
  };
};

export default useWelcome;