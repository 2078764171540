import { useEffect, useState } from 'react';
import {
  //  useLocation,
  useNavigate
} from '../../../common/wrappers/ReactRouterDom';

import validate from './AboutMemberValidation';

import NavLinks from '../../NavLinks';

import useOmrData from '../../state/omr/UseOmrData';
import useOmrPersonDupCheckData from '../../state/omrPersonDupCheck/UseOmrPersonDupCheckData';

import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useSecurityData from '../../../common/state/security/UseSecurityData';

import { formatDate, getAge } from '../../../common/utils/DateFunctions';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import Constants from '../../../common/utils/Constants';
import useForm from '../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  middleName: '',
  preferredName: '',
  suffix: '',
  suffixId: Constants.DEFAULT_ID,
  birthDate: Constants.BLANK_DATE_STRING,
  isUsCitizen: null,
  hasMiddleName: ''
};

const INITIAL_INELIGIBLE_MEMBER_MODAL_STATE = {
  displayPopup: false,
  modalTitle: 'Your membership cannot be processed at this time',
  selectedPerson: {}
};

const useAboutMember = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { getOmrPersonDupes, omrPersonDupCheckState } = useOmrPersonDupCheckData();
  const { securityState } = useSecurityData();
  const [showModal, setShowModal] = useState(false);
  const [ineligibleMemberModalState, setIneligibleMemberModalState] = useState(INITIAL_INELIGIBLE_MEMBER_MODAL_STATE);
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    handleSubmit,
    setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {
    if (personState.person.personId) {
      continueAsIs();
    } else {
      getOmrPersonDupes(formState.firstName, formState.lastName, formatDate(formState.birthDate), '');
    }
  };

  const onNoneOfTheseClicked = () => {
    const newPerson = {
      ...personState,
      is16orOlder: getAge(formState.birthDate) > 15,
      person: {
        ...personState.person,
        firstName: formState.firstName,
        lastName: formState.lastName,
        middleName: formState.hasMiddleName === true ? ' ' : formState.middleName,
        preferredName: formState.preferredName,
        suffix: formState.suffix,
        suffixId: formState.suffixId,
        birthDate: formatDate(formState.birthDate),
        isUsCitizen: formState.isUsCitizen,
        hasMiddleName: formState.hasMiddleName || '',
      }
    };

    savePersonState(newPerson);
  };

  const continueAsIs = () => {
    if (((securityState.userInfo?.isIneligibleAthlete === false || securityState.userInfo?.isIneligibleNonAthlete === false) && personState?.isMembershipForLoggedInUser === true) ||
      personState?.isMembershipForLoggedInUser === false) {
      const newPerson = {
        ...personState,
        is16orOlder: getAge(formState.birthDate) > 15,
        person: {
          ...personState.person,
          firstName: formState.firstName,
          lastName: formState.lastName,
          middleName: formState.hasMiddleName === true ? ' ' : formState.middleName,
          preferredName: formState.preferredName,
          suffix: formState.suffix,
          suffixId: formState.suffixId,
          birthDate: formatDate(formState.birthDate),
          isUsCitizen: formState.isUsCitizen,
          hasMiddleName: formState.hasMiddleName || '',
        }
      };

      savePersonState(newPerson);
    }
    else {
      setIneligibleMemberModalState({ ...ineligibleMemberModalState, displayPopup: true });
    }
  };

  const onSelectMember = (e, selectedPerson) => {
    e?.preventDefault();
    if (selectedPerson.isIneligibleAthlete === false || selectedPerson.isIneligibleNonAthlete === false) {
      const newPerson = {
        ...personState,
        is16orOlder: getAge(formState.birthDate) > 15,
        isExistingPerson: true,
        person: {
          ...personState.person,
          personId: selectedPerson.personId,
          memberId: selectedPerson.memberId,
          firstName: formState.firstName,
          lastName: formState.lastName,
          middleName: formState.hasMiddleName === true ? ' ' : formState.middleName,
          preferredName: formState.preferredName,
          suffix: formState.suffix,
          suffixId: formState.suffixId,
          birthDate: formatDate(formState.birthDate),
          isUsCitizen: formState.isUsCitizen,
          hasMiddleName: formState.hasMiddleName || '',
          competitionGenderTypeId: selectedPerson.competitionGenderTypeId,
          competitionGenderTypeName: selectedPerson.competitionGender,
          genderIdentityTypeId: selectedPerson.genderIdentityTypeId
        }
      };

      savePersonState(newPerson);
    }
    else {
      setIneligibleMemberModalState({ ...ineligibleMemberModalState, displayPopup: true, selectedPerson: selectedPerson });
    }
  };

  const onModalCanceled = () => {
    setShowModal(false);
  };

  const onIneligibleMemberModalCanceled = () => {
    setIsDirty(true);
    setIneligibleMemberModalState(INITIAL_INELIGIBLE_MEMBER_MODAL_STATE);
  };

  useEffect(() => {
    if (omrPersonDupCheckState.isArrayLoaded === true) {
      if (omrPersonDupCheckState?.arrayData?.length > 0) {
        setShowModal(true);
      } else {
        continueAsIs();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrPersonDupCheckState]);

  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.firstName && personState.person.lastName !== '' && personState.person.birthDate !== undefined) {
        let formStateCopy = [];
        if (formState.firstName !== '' && isSaved === false) {
          formStateCopy = JSON.parse(JSON.stringify(formState));
          if ((formStateCopy.firstName !== personState.person.firstName) ||
            (formStateCopy.lastName !== personState.person.lastName) ||
            (formStateCopy.middleName !== personState.person.middleName) ||
            (formStateCopy.preferredName !== personState.person.preferredName) ||
            (formStateCopy.suffix !== personState.person.suffix) ||
            (formStateCopy.suffixId !== personState.person.suffixId) ||
            (formStateCopy.birthDate !== personState.person.birthDate) ||
            (formStateCopy.isUsCitizen !== personState.person.isUsCitizen) ||
            (formStateCopy.hasMiddleName !== undefined &&
              formStateCopy.hasMiddleName !== personState.person.hasMiddleName)) {
            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);

  useEffect(() => {
    if (!formState.firstName && personState?.person) {
      const newFormState = {
        ...formState,
        firstName: personState.person.firstName,
        lastName: personState.person.lastName,
        middleName: personState.person.middleName,
        preferredName: personState.person.preferredName,
        suffix: personState.person.suffix,
        suffixId: personState.person.suffixId,
        birthDate: formatDate(personState.person.birthDate) || Constants.BLANK_DATE_STRING,
        isUsCitizen: personState.person.isUsCitizen,
        isRegistrantMembershipForLoggedInUser: personState?.isMembershipForLoggedInUser,
        hasMiddleName: personState?.person?.hasMiddleName
      };

      setFormData(newFormState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      if (personState?.isMembershipForLoggedInUser === true) {
        //navigate to option 3
        navigate(NavLinks.REGISTRANT_CONTACT_INFO_SELF);
      } else if (personState?.is16orOlder === true) {
        //navigate to option 2
        navigate(NavLinks.REGISTRANT_CONTACT_INFO_OTHER);
      } else {
        //navigate to option 1
        navigate(NavLinks.PRIMARY_CONTACT_INFO_U16);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    isExistingUser: personState?.isExistingPerson,
    formState,
    errorState,
    omrPersonDupCheckState,
    showModal,
    onModalCanceled,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked: handleSubmit,
    onSelectMember,
    ineligibleMemberModalState,
    onIneligibleMemberModalCanceled,
    onNoneOfTheseClicked
  };
};

export default useAboutMember;