import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from './GenderValidation';

import NavLinks from '../../NavLinks';
import useOmrData from '../../state/omr/UseOmrData';
import useCompetitionGenderTypeData from '../../../common/state/competitionGender/UseCompetitionGenderTypeData';

import useLeftNavModalData from '../../../common/state/leftNavModal/UseLeftNavModalData';
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import Constants from '../../../common/utils/Constants';
import useForm from '../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  competitionGenderTypeId: Constants.DEFAULT_ID,
  competitionGenderTypeName: ''
}

const INITIAL_VIEW_STATE = {
  isCompetitionCategoryLocked: false,
}

const useCompetitonCategoryAthU16 = () => {
  const navigate = useNavigate();
  const { personState, savePersonState, isSaved } = useOmrData();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const {
    formState,
    errorState,
    setFormData,
    handleSubmit,
    onFormValueChanged } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {

    const newPerson = {
      ...personState,
      person: {
        ...personState.person,
        competitionGenderTypeId: formState.competitionGenderTypeId
      }
    };

    savePersonState(newPerson);
  };

  useEffect(() => {
    if (personState?.person?.personId !== '' && personState?.person?.competitionGenderTypeId > 0
      && personState?.person?.competitionGenderTypeName !== '') {

      setState({ ...state, isCompetitionCategoryLocked: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.person.personId, personState.person.competitionGenderTypeName]);


  useEffect(() => {
    if (state?.isCompetitionCategoryLocked === true) {
      const newFormState = {
        ...formState,
        competitionGenderTypeName: personState?.person?.competitionGenderTypeName,
        competitionGenderTypeId: personState?.person?.competitionGenderTypeId
      };

      setFormData(newFormState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCompetitionCategoryLocked]);

  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.competitionGenderTypeId > 0) {
        if (formState.competitionGenderTypeId !== Constants.DEFAULT_ID && isSaved === false) {
          if (formState.competitionGenderTypeId !== personState.person.competitionGenderTypeId) {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });

          } else {

            setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    if (personState && Object.keys(personState).length > 0) {
      if (personState.person.competitionGenderTypeId === Constants.DEFAULT_ID) {
        if (formState.competitionGenderTypeId === Constants.DEFAULT_ID && isSaved === false) {

          setLeftNavModalState({ ...leftNavModalState, formStateSaved: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, personState, isSaved]);


  useEffect(() => {
    if (formState.competitionGenderTypeId && personState.person && competitionGenderState.isArrayLoaded === true) {

      const competitionGenderId = personState?.person?.competitionGenderTypeId > 0
        ? personState?.person?.competitionGenderTypeId
        : Constants.DEFAULT_ID

      const newFormState = {
        ...formState,
        competitionGenderTypeId: competitionGenderId,
      };

      setFormData(newFormState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, competitionGenderState.isArrayLoaded]);

  useDidMountEffect(() => {
    if (isSaved === true) {

      setLeftNavModalState({ ...leftNavModalState, formStateSaved: true });
      //navigate(NavLinks.DISABILITIES_ATHLETE_U16);
      document.getElementById('omrHeadingId')?.scrollIntoView();
      navigate(NavLinks.REGISTRATION_ACKNOWLEDGEMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    personState,
    formState,
    errorState,
    setFormData,
    handleSubmit,
    onNextButtonClicked: handleSubmit,
    onFormValueChanged,
    isCompetitionCategoryLocked: state?.isCompetitionCategoryLocked
  };
};

export default useCompetitonCategoryAthU16;