import React, { createContext, useState } from 'react';

import TempPersonData from './TempPersonData';

export const TempPersonStateContext = createContext();

const TempPersonContextProvider = ({ children }) => {
  const stateHook = useState(TempPersonData.INITIAL_STATE);

  return (
    <TempPersonStateContext.Provider value={stateHook}>
      {children}
    </TempPersonStateContext.Provider>
  );
};

export default TempPersonContextProvider;