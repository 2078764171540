import { useEffect, useState } from 'react';

import useCompetitionGenderTypeData from '../../../common/state/competitionGender/UseCompetitionGenderTypeData';
import useGenderIdentityTypeData from '../../../common/state/genderIdentity/UseGenderIdentityTypeData';

const useGenderForm = (formState, state, setState) => {
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const { genderIdentityState, getGenderIdentityTypes } = useGenderIdentityTypeData();
  const [uiState, setUiState] = useState({ showGenderIdentity: false });

  const onShowHideOptionalGenderIdentityToggle = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (uiState.showGenderIdentity === false) {
      setUiState({
        ...uiState,
        showGenderIdentity: true
      });
    }
    else if (uiState.showGenderIdentity === true) {
      setUiState({
        ...uiState,
        showGenderIdentity: false
      });
    }
  };

  function getGenderIdentityArray() {
    const createGenderIdentityArray = genderIdentityState?.arrayData?.map((gender) => {
      return {
        label: gender.typeName,
        value: gender.genderIdentityTypeId,
        id: gender.genderIdentityTypeId
      };
    });

    setState({
      ...state,
      radiobuttonlistGenderIdentity: createGenderIdentityArray
    });
    return createGenderIdentityArray;
  };

  useEffect(() => {
    if (state.radiobuttonlistGenderIdentity?.length === 0) {
      getGenderIdentityTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (genderIdentityState?.arrayData?.length > 0) {
      getGenderIdentityArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderIdentityState.arrayData]);

  useEffect(() => {
    if (formState.genderIdentityTypeId > 0 && uiState.showGenderIdentity === false) {
      setUiState({ ...uiState, showGenderIdentity: true });
    }
  }, [formState.genderIdentityTypeId, uiState]);

  return {
    competitionGenderState,
    uiState,
    onShowHideOptionalGenderIdentityToggle
  }
}

export default useGenderForm;