import usePhoneTypeDropdown from './UsePhoneTypeDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const PhoneTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { phoneTypeState } = usePhoneTypeDropdown();

  return phoneTypeState.message
    ? <span className={global.LoadingMsg}>{phoneTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={phoneTypeState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={phoneTypeState.isArrayLoading}
      />
    );
};

export default PhoneTypeDropdown;