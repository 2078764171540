import React from 'react';
import Acknowledgement from '../../../common/components/acknowledgements/Acknowledgement';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Input from '../../../common/components/inputs/Input';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';
import Views from '../../Views';
import useAcknowledgements from './UseAcknowledgements';

import global from '../../../common/components/GlobalStyle.module.css';

const Acknowledgements = () => {
  const {
    isLoading,
    arrayData,
    message,
    showModal,
    signatureErrorMessage,
    signature,
    onNextButtonClicked,
    onItemClick,
    onModalCanceled,
    onSignatureChanged,
    onSigned } = useAcknowledgements();

  return (
    <ViewTemplate
      viewName={Views.ACKNOWLEDGEMENTS}
      onNextButtonClicked={onNextButtonClicked}>
      <div className="row">
        <div className="col-xs-12">
          {isLoading !== false && <LoadingModal />}
          {isLoading === false && arrayData.length > 0
            ? arrayData.map((acknowledgement, i) => <Acknowledgement key={i} acknowledgement={acknowledgement} onItemClick={onItemClick} />)
            : <div>All of this person's acknowledgements have already been signed</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {message && <p className={global.ErrorMessage}>{message}</p>}&nbsp;
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title="Signature"
        displayPopUp={showModal === true}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>Please type your name below to indicate your signature.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Input 
              error={signatureErrorMessage}
              label="Signature"
              message={signatureErrorMessage}
              name="signature"
              value={signature}
              onChange={onSignatureChanged} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSigned}>Sign the documents</PrimaryButton>&nbsp;&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </ViewTemplate>
  );
};

export default Acknowledgements;