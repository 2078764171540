import React, { Fragment } from 'react';

import Views from '../../Views';
import useMembershipTypesU16 from './UseMembershipTypesU16';
import OfferingTypesGrid from './grids/OfferingTypesGrid';
import ErrorHandle from '../components/ErrorHandle';
import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import Constants from '../../../common/utils/Constants';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import style from '../Omr.module.css';

const MembershipTypesU16 = () => {
  const {
    displayPopUp,
    showNoMembershipsAvailable,
    personState,
    state,
    offeringTypeState,
    onBackToWelcomeButtonClicked,
    onShowMembershipHistory,
    onNextButtonClicked,
    onAllMembershipCheck,
    restrictions
  } = useMembershipTypesU16();

  if (showNoMembershipsAvailable === true) {
    return (
      <Fragment>
        <div className="col-xs-12">
          <p className={style.CenterAlign}>There are currently no memberships available for this registrant, please check back later.</p>
        </div>
        <div className={['row usas-extra-top-margin', style.CenterAlign].join(' ')}>
          <PrimaryButton onClick={onBackToWelcomeButtonClicked}>Back to Welcome</PrimaryButton>
        </div>
      </Fragment>
    );
  } else {
    return (
      <ViewTemplate
        viewName={Views.MEMBERSHIP}
        onNextButtonClicked={onNextButtonClicked}>
        {
          personState?.person?.personId
            ? (
              <div className='row'>
                <div className='col-xs-12 usas-extra-bottom-margin'>
                  <ActionIntraPageButton
                    className='pull-right'
                    onClick={onShowMembershipHistory}>Show Membership History
                  </ActionIntraPageButton>
                </div>
              </div>
            )
            : <Fragment />
        }
        <OfferingTypesGrid
          state={offeringTypeState}
          selectedOfferingTypeIds={state.selectedOfferingTypeIds}
          onChanged={onAllMembershipCheck}
          restrictions={restrictions} />
        <ErrorHandle error={state.errorMessage} message={state.errorMessage} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={displayPopUp} />
      </ViewTemplate >
    );
  }
};

export default MembershipTypesU16;