import { Fragment } from 'react';

import useAddressBook from './UseAddressBook';
import AddressBookGrid from './AddressBookGrid';
import ErrorHandle from '../../views/components/ErrorHandle';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import style from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const AddressBook = ({ onOpenAddressBook, navlink }) => {
  const {
    addressBookState,
    onAddressBookClicked,
    addressBookModalState,
    onModalCanceled,
    // personName,
    onSetContactOnRadioButtonClicked,
    onSendPersonObjInfoToForm,
    errorState
  } = useAddressBook(onOpenAddressBook);

  return (
    <Fragment>
      {Array.isArray(addressBookState?.contacts) === true
        && addressBookState?.contacts?.length > 0
        ? <div>
          <ActionIntraPageButton onClick={onAddressBookClicked}> Address Book </ActionIntraPageButton>
        </div>
        : <Fragment />}
      <PopUpModal
        widthPct={90}
        maxWidth={900}
        title={addressBookModalState.modalTitle}
        displayPopUp={addressBookModalState?.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className='row'>
          <div className='col-xs-12'>
            <p className={[global.Bold, style.CenterAlign].join(' ')}>NOTICE:</p>
            <p className={['usas-extra-bottom-margin', style.CenterAlign].join(' ')}>
              This is a list of saved registration contacts. Selecting and adding a saved contact will populate the form with the selected contacts information. After selecting a contact, please choose the relationship of the contact to the member being registered.
            </p>
          </div>
          <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
            <AddressBookGrid state={addressBookState.contacts} onChanged={onSetContactOnRadioButtonClicked} />
          </div>
        </div>
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          <ErrorHandle error={errorState} message={errorState} />
        </div>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12">
            <span className={style.RightFloatLargeView}>
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;&nbsp;
              <PrimaryButton type="button" onClick={(e) => onSendPersonObjInfoToForm(e, navlink)}>Yes, Add Contact</PrimaryButton>
            </span>
          </div>
        </div>

      </PopUpModal>
    </Fragment>
  );
};

export default AddressBook;