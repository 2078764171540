import Constants from "../../../common/utils/Constants"
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";
import getPersonByMemberId from "./GetPersonByMemberIdData";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  firstName: '',
  lastName: '',
  birthDate: Constants.DEFAULT_ID
};

const TempPersonData = { INITIAL_STATE, getPersonByMemberId };
export default TempPersonData;