import React, { Fragment } from 'react';

import RenewMembershipsSelectionLargeGrid from './RenewMembershipsSelectionLargeGrid';
import RenewMembershipsSelectionSmallGrid from './RenewMembershipsSelectionSmallGrid';

const RenewMembershipsSelectionGrid = ({ omrView, gridData, isLoading, onCheckboxChange, onChangeMemberTypeClicked, onShowRenewalConfirmationModalClicked }) => {
  return (
    <Fragment>
      <RenewMembershipsSelectionLargeGrid
        omrView={omrView}
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        onChangeMemberTypeClicked={onChangeMemberTypeClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
      <RenewMembershipsSelectionSmallGrid
        omrView={omrView}
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        onChangeMemberTypeClicked={onChangeMemberTypeClicked}
        onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
      />
    </Fragment>
  )
};

export default RenewMembershipsSelectionGrid;