import { useState } from 'react';
import { useCookies } from 'react-cookie';
import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { useParams, useSearchParams } from '../../../common/wrappers/ReactRouterDom';

const COOKIE_NAME = 'omr';

const useOmrCookieData = () => {
  const { routeCode } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ cookies, setCookie ] = useCookies([COOKIE_NAME]);
  const [ omrCookieState, setOmrCookieState ] = useState({ ...BASIC_INITIAL_STATE, isObjLoading: true });

  const getOmrCookieState = () => {
    if (omrCookieState.isObjLoaded !== true && !omrCookieState.message) {
      let vendorToken = '';
      const newCookieObj = {...cookies[COOKIE_NAME]} || { vendorToken, routeCode };
      if (!newCookieObj.routeCode || newCookieObj.routeCode !== routeCode) {
        newCookieObj.vendorToken = '';
        newCookieObj.routeCode = routeCode;
      }
      
      // use the following if we ever choose to support preserving a full QS
      // const newSearchParams = {};

      let i = 0;
      searchParams.forEach((val, name) => {
        if (i === 0 && name && !val) {
          vendorToken = name;
        } else {
          // use the following if we ever choose to support preserving a full QS
          // newSearchParams[name] = val;
        }
        i++;
      });

      // use the following if we ever choose to support preserving a full QS
      // setSearchParams(newSearchParams);
      setSearchParams({});
      if (newCookieObj.vendorToken !== vendorToken) {
        newCookieObj.vendorToken = vendorToken;
      }
      
      setCookie(COOKIE_NAME, newCookieObj, { ...Constants.COOKIE_OPTIONS, maxAge: 900 });
      setOmrCookieState({
        ...omrCookieState,
        isObjLoading: false,
        isObjLoaded: true,
        objData: newCookieObj,
        message: ''
      });
    }
  };

  return {
    omrCookieState,
    getOmrCookieState
  };
};

export default useOmrCookieData;