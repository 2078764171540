import useSecurityData from '../../../common/state/security/UseSecurityData';

const useNotLoggedInMessage = () => {
  const { login } = useSecurityData();

  const onLoginClicked = (e) => {
    e?.preventDefault();

    login();
  };
  return {
    onLoginClicked
  };
};

export default useNotLoggedInMessage;