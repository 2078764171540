import hasAthleteOffering from './HasAthleteOffering';
import Constants from '../../../common/utils/Constants';
import ToBoolIfBool from '../../../common/utils/ToBoolIfBool';
import { es } from 'date-fns/locale';

const objectShape = {
  loggedInPersonId: '',
  orgUnitId: '',
  persons: [],
  vendorToken: ''
};

const personObjectShape = {
  personId: null,
  firstName: '',
  lastName: '',
  birthDate: Constants.BLANK_DATE_STRING,
  middleName: '',
  suffix: '',
  preferredName: '',
  isUsCitizen: true,
  contactInfo: null,
  primaryContactInfo: {},
  personContacts: [],
  hispanicOrLatinoTypeId: null,
  personEthnicity: [],
  offeringInstanceIds: [],
  //personDisabilities: [],
  competitionGenderTypeId: null,
  genderIdentityTypeId: null
};

const convertOmrStateToApiPostableObj = (omrState) => {
  const newObj = JSON.parse(JSON.stringify(objectShape));
  const newPersonsArray = omrState.persons.map(convertPersonStateToPersonObj);

  newObj.loggedInPersonId = omrState.loggedInPersonId;
  newObj.orgUnitId = omrState.orgUnitId;
  newObj.persons = newPersonsArray;
  newObj.vendorToken = omrState.vendorToken;
  return newObj;
};

function convertPersonStateToPersonObj(person) {
  const personState = JSON.parse(JSON.stringify(person));
  const newPersonObj = JSON.parse(JSON.stringify(personObjectShape));

  newPersonObj.personId = personState.person.personId ? personState.person.personId : null;
  newPersonObj.firstName = personState.person.firstName;
  newPersonObj.lastName = personState.person.lastName;
  newPersonObj.birthDate = personState.person.birthDate;
  newPersonObj.middleName = personState.person.middleName;
  newPersonObj.suffix = personState.person.suffix;
  newPersonObj.preferredName = personState.person.preferredName;
  newPersonObj.isUsCitizen = ToBoolIfBool(personState.person.isUsCitizen) === true;
  newPersonObj.hasAthleteOfferingInstance = hasAthleteOffering(personState);
  newPersonObj.contactInfo = getPersonalContactInfo(personState.person.contactInfo, personState.is16orOlder);
  newPersonObj.primaryContactInfo = getContactInfo(personState.person.primaryContactInfo);
  newPersonObj.personContacts = personState.person.personContacts.map(personContact => getContactInfo(personContact));
  newPersonObj.hispanicOrLatinoTypeId = personState.person.hispanicOrLatinoTypeId > 0 ? personState.person.hispanicOrLatinoTypeId : null;
  newPersonObj.personEthnicity = personState.person.personEthnicity;
  newPersonObj.offeringInstanceIds = personState.person.offeringInstanceIds;
  newPersonObj.offeringInstanceCouponId = personState.person.offeringInstanceCouponId;
  //newPersonObj.personDisabilities = personState.person.personDisabilities;
  newPersonObj.competitionGenderTypeId = personState.person.competitionGenderTypeId > 0 ? personState.person.competitionGenderTypeId : null;
  newPersonObj.genderIdentityTypeId = personState.person.genderIdentityTypeId > 0 ? personState.person.genderIdentityTypeId : null;
  const esignature = personState.person?.esignature;
  const acknowledgementFormIds = personState.person.personAcknowledgement
    .filter(x => x.isAcknowledged === true)?.map(x => x.acknowledgementFormId);
  if (esignature && acknowledgementFormIds?.length > 0) {
    newPersonObj.personAcknowledgements = {
      acknowledgementFormIds,
      esignature,
      signatureDate: (new Date()).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    };
  }

  return newPersonObj;
};

export default convertOmrStateToApiPostableObj;

const contactInfoObjectShape = {
  emailAddress: '',
  phoneNumber: '',
  phoneTypeId: null,
  address: {
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: ''
  }
};

function getPersonalContactInfo(contactInfo, is16orOlder) {
  if (is16orOlder === true) {
    const newContactInfo = JSON.parse(JSON.stringify(contactInfoObjectShape));
    newContactInfo.emailAddress = contactInfo.emailAddress;
    newContactInfo.phoneNumber = contactInfo.phoneNumber;
    newContactInfo.phoneTypeId = contactInfo.phoneTypeId > 0 ? contactInfo.phoneTypeId : null;
    newContactInfo.address.address1 = contactInfo.address.address1;
    newContactInfo.address.address2 = contactInfo.address.address2 || '';
    newContactInfo.address.city = contactInfo.address.city;
    newContactInfo.address.stateCode = contactInfo.address.stateCode;
    newContactInfo.address.postalCode = contactInfo.address.postalCode;
    newContactInfo.address.countryCode = contactInfo.address.countryCode || 'USA';
    return newContactInfo;
  } else {
    return null;
  }
};

function getContactInfo(contactInfo) {
  const newContactInfo = getPersonalContactInfo(contactInfo, true);
  newContactInfo.firstName = contactInfo.firstName;
  newContactInfo.lastName = contactInfo.lastName;
  newContactInfo.contactRelationshipTypeId = contactInfo.contactRelationshipTypeId > 0 ? contactInfo.contactRelationshipTypeId : null;
  return newContactInfo;
};