import { useEffect } from 'react';

import useGenderIdentityTypeData from '../../../common/state/genderIdentity/UseGenderIdentityTypeData';

const useGenderIdentityForm = (state, setState) => {
  const { genderIdentityState, getGenderIdentityTypes } = useGenderIdentityTypeData();

  function getGenderIdentityArray() {
    const createGenderIdentityArray = genderIdentityState?.arrayData?.map((gender) => {
      return {
        label: gender.typeName,
        value: gender.genderIdentityTypeId,
        id: gender.genderIdentityTypeId
      };
    });

    setState({
      ...state,
      radiobuttonlistGenderIdentity: createGenderIdentityArray
    });
    return createGenderIdentityArray;
  }

  useEffect(() => {
    if (genderIdentityState.arrayData?.length > 0) {
      getGenderIdentityArray();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderIdentityState.arrayData]);

  useEffect(() => {
    if (state.radiobuttonlistGenderIdentity === undefined || state?.radiobuttonlistGenderIdentity?.length === 0) {
      getGenderIdentityTypes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    genderIdentityState
  }
}

export default useGenderIdentityForm;