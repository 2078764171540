import {BASIC_INITIAL_STATE} from '../../../common/utils/HttpHelper';

const DEFAULT_SUFFIX_ARRAY = [
  { id: -1, name: " " },
  { id: 1, name: "Sr." },
  { id: 2, name: "Jr." },
  { id: 3, name: "II" },
  { id: 4, name: "III" },
  { id: 5, name: "IV" },
  { id: 6, name: "V" }
];

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  arrayData: DEFAULT_SUFFIX_ARRAY, 
  isArrayLoaded: true
};

const SuffixData = {INITIAL_STATE};

export default SuffixData;
