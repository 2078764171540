import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from './ExistingMemberSearchValidation';
import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

import useOmrData from '../../state/omr/UseOmrData';
import useOmrPersonDupCheckData from '../../state/omrPersonDupCheck/UseOmrPersonDupCheckData';
import usePersonData from '../../../common/state/person/UsePersonData';
import useForm from '../../../common/utils/UseForm'
import useDidMountEffect from '../../../common/utils/UseDidMountEffect';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useRouteCodeValueData from '../../../common/state/routeCode/UseRouteCodeValueData';

const INITIAL_VIEW_STATE = {
  showFilters: true,
  showResults: false,
  selectedPerson: {
    isMembershipForLoggedInUser: false,
    is16orOlder: null,
    person: {}
  }
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  birthDate: Constants.BLANK_DATE_STRING,
  memberId: ''
};

const INITIAL_MODAL_STATE = {
  displayPopup: false,
  modalTitle: 'Your membership cannot be processed at this time',
  selectedPerson: {}
};

const breakUpOmrHookObj = (omrHookObj) => {
  return {
    currentPersonState: omrHookObj.personState,
    savePersonState: omrHookObj.savePersonState,
    isSaved: omrHookObj.isSaved
  };
}

const useExistingMemberSearch = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { resetPersonState } = usePersonData();
  const { getOmrPersonDupes, omrPersonDupCheckState } = useOmrPersonDupCheckData();
  const { currentPersonState, savePersonState, isSaved } = breakUpOmrHookObj(useOmrData());
  const { routeCodeValueState } = useRouteCodeValueData();
  const {
    formState,
    setFormData,
    errorState,
    onFormValueChanged,
    handleSubmit,
    setErrors
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {
    if (omrPersonDupCheckState?.isArrayLoading === false) {
      const birthdate = formState?.birthDate !== Constants.BLANK_DATE_STRING ? formState?.birthDate : ''
      getOmrPersonDupes(formState.firstName.trim(), formState.lastName.trim(), birthdate, formState.memberId.trim());
    }
  };

  const clearForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormData({ ...INITIAL_FORM_STATE }, true);
    resetPersonState();
    setState({ ...INITIAL_VIEW_STATE });
    setErrors({});
  };

  const onSelectMember = (e, selectedPerson) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    if (selectedPerson.isIneligibleAthlete === false || selectedPerson.isIneligibleNonAthlete === false) {
      const newPersonState = JSON.parse(JSON.stringify(currentPersonState));
      newPersonState.searchFilters.firstName = formState.firstName;
      newPersonState.searchFilters.lastName = formState.lastName;
      newPersonState.searchFilters.birthDate = formatDate(formState.birthDate) || Constants.BLANK_DATE_STRING;
      newPersonState.person.personId = selectedPerson.personId || '';
      newPersonState.person.memberId = selectedPerson.memberId || '';
      newPersonState.person.firstName = selectedPerson.firstName || '';
      newPersonState.person.lastName = selectedPerson.lastName || '';
      newPersonState.person.middleName = selectedPerson.middleName || '';
      newPersonState.person.preferredName = selectedPerson.preferredName || '';
      newPersonState.person.suffix = selectedPerson.suffix || '';
      newPersonState.person.isUsCitizen = selectedPerson.isUsCitizen || null;
      newPersonState.person.birthDate = formatDate(selectedPerson.birthDate) || Constants.BLANK_DATE_STRING;
      newPersonState.person.competitionGenderTypeName = selectedPerson.competitionGender || '';
      newPersonState.person.competitionGenderTypeId = selectedPerson.competitionGenderTypeId || Constants.DEFAULT_ID;
      newPersonState.person.genderIdentityTypeId = selectedPerson.genderIdentityTypeId;
      newPersonState.person.offeringInstanceCouponId = routeCodeValueState?.offeringInstanceCouponId || Constants.DEFAULT_ID

      savePersonState(newPersonState);
    }
    else {
      setModalState({
        ...modalState,
        displayPopup: true,
        selectedPerson: selectedPerson
      });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    savePersonState({
      ...currentPersonState,
      isMembershipForLoggedInUser: null
    });
    document.getElementById('omrHeadingId')?.scrollIntoView();
    navigate(-1);
  };

  const onFiltersToggle = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showFilters: state.showFilters === false });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  }

  useEffect(() => {
    if (omrPersonDupCheckState.isArrayLoaded === true) {
      setState({ ...state, showResults: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omrPersonDupCheckState]);

  useEffect(() => {
    if (currentPersonState.searchFilters?.firstName !== formState.firstName
      || currentPersonState.searchFilters?.lastName !== formState.lastName
      || currentPersonState.searchFilters?.birthDate !== formState.birthDate) {

      setFormData({
        firstName: currentPersonState.searchFilters?.firstName,
        lastName: currentPersonState.searchFilters?.lastName,
        birthDate: currentPersonState.searchFilters?.birthDate || Constants.BLANK_DATE_STRING
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPersonState.searchFilters]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true && currentPersonState.isMembershipForLoggedInUser !== null) {
      navigate(navRoutes.ABOUT_MEMBER.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    showFilters: state.showFilters,
    showResults: state.showResults,
    formState,
    errorState,
    onFormValueChanged,
    clearForm,
    omrPersonDupCheckState,
    onSelectMember,
    onBackButtonClicked,
    onFiltersToggle,
    handleSubmit,
    modalState,
    onModalCanceled
  };
};

export default useExistingMemberSearch;