import React, { Fragment } from 'react';

import { formatPhoneNumber } from '../../../common/utils/validation';

import style from '../../views/Omr.module.css';
import global from '../../../common/components/GlobalStyle.module.css';

const LargeAddressBookGrid = ({ state, onChanged }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Contact Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th>Address</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {(Array.isArray(state) === false
          || state.length === 0)
          ? (
            <tr>
              <td colSpan="6">No Contacts</td>
            </tr>)
          : state.map((contact, i) => (
            <tr key={i}>
              <td>{contact?.firstName || ''} {contact?.lastName || ''}</td>
              <td>{contact?.email || ''}</td>
              <td>{formatPhoneNumber(contact?.phoneNumber) || ''}</td>
              <td>{contact?.address1} {contact?.address2} {contact?.city}, {contact?.stateCode} {contact?.postalCode}</td>
              <td className={style.ColumnWidth} >
                <input
                  className={[style.RadioButton, global.BaseInputBox].join(' ')}
                  type='radio'
                  id={`addressId-${contact.index}`}
                  name="addressId"
                  value={contact.index}
                  onChange={(e) => { onChanged(e, contact.index, contact) }}>
                </input>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </Fragment>
);

export default LargeAddressBookGrid;