import { useContext } from 'react';

import { RegisteredMembersStateContext } from './RegisteredMembersContextProvider';
import RegisteredMembersData from './RegisteredMembersData';

const useRegisteredMembersData = () => {
  const [registeredMembersState, setRegisteredMembersState] = useContext(RegisteredMembersStateContext);

  const getRegisteredMembersByPersonId = (personId) => {
    RegisteredMembersData.getRegisteredMembersByPersonIdData(personId, registeredMembersState, setRegisteredMembersState);
  };

  const postMemberRegistrationNewMembership = (personId, memberRegistrationNewMembershipObj) => {
    RegisteredMembersData.postMemberRegistrationNewMembershipData(personId, memberRegistrationNewMembershipObj, registeredMembersState, setRegisteredMembersState);
  };

  const resetRegisteredMembersState = () => {
    setRegisteredMembersState(RegisteredMembersData.INITIAL_STATE);
  };

  return {
    getRegisteredMembersByPersonId,
    postMemberRegistrationNewMembership,
    resetRegisteredMembersState,
    registeredMembersState,
    setRegisteredMembersState
  };
};

export default useRegisteredMembersData;