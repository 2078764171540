import React from 'react';

import ExistingMemberSearchFilterContextProvider from './state/existingMemberSearch/ExistingMemberSearchFilterContextProvider';
import OmrContextProvider from './state/omr/OmrContextProvider';
import AddressBookContextProvider from './state/omr/AddressBookContextProvider';
//TODO: Remove temp person context provider when we have security and person logged in info solidified
import TempPersonContextProvider from './state/tempPersonData/TempPersonContextProvider';
import PersonContextProvider from './state/landingPages/person/PersonContextProvider';
import RegisteredMembersContextProvider from './state/registeredMembers/RegisteredMembersContextProvider';
import ChildrenContextProvider from './state/landingPages/children/ChildrenContextProvider';
import LandingPageContextProvider from './state/landingPages/LandingPageContextProvider';
import PersonParentChildContextProvider from './state/landingPages/personParentChild/PersonParentChildContextProvider';
import OmrBackButtonContextProvider from './state/omrBackButton/OmrBackButtonContextProvider';

import OrgUnitContextProvider from '../common/state/orgUnit/OrgUnitContextProvider';
import ContactRelationshipTypesContextProvider from '../common/state/contactRelationshipType/ContactRelationshipTypesContextProvider';
import AddressTypeContextProvider from '../common/state/addressType/AddressTypeContextProvider';
import PhoneTypeContextProvider from '../common/state/phoneType/PhoneTypeContextProvider';
import OfferingTypesContextProvider from '../common/state/offeringTypes/OfferingTypesContextProvider';
import HispanicOrLatinoTypeContextProvider from '../common/state/hispanicOrLatinoType/HispanicOrLatinoTypeContextProvider';
import EthnicityTypeContextProvider from '../common/state/ethnicityType/EthnicityTypeContextProvider';
//import DisabilityTypeContextProvider from '../common/state/disabilityType/DisabilityTypeContextProvider';
import MemberRegistrationHistoryContextProvider from '../common/state/offeringTypes/MemberRegistrationHistoryContextProvider';
import CompetitonGenderTypeContextProvider from '../common/state/competitionGender/CompetitionGenderTypeContextProvider';
import GenderIdentityTypeContextProvider from '../common/state/genderIdentity/GenderIdentityTypeContextProvider';
import RouteCodeValueContextProvider from '../common/state/routeCode/RouteCodeValueContextProvider';
import LeftNavModalContextProvider from '../common/state/leftNavModal/LeftNavModalContextProvider';
import PersonGeneralContextProvider from '../common/state/personGeneral/PersonGeneralContextProvider';
import RenewalOptionContextProvider from '../common/state/renewalOption/RenewalOptionContextProvider';
import BasePersonContextProvider from '../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../common/state/personBase/BasePersonFiltersContextProvider';
import PersonAcknowledgementInfoContextProvider from '../common/state/personAcknowledgementInfo/PersonAcknowledgementInfoContextProvider';
import PersonAcknowledgementInfoPageViewContextProvider from '../common/state/personAcknowledgementInfo/PersonAcknowledgementInfoPageViewContextProvider';
import PersonAcknowledgementAddAFamilyMemberContextProvider from '../common/state/personAcknowledgementAddAFamilyMember/PersonAcknowledgementAddAFamilyMemberContextProvider';

const AppStateContextProviders = ({ children }) => (
  <OmrBackButtonContextProvider>
    <PersonAcknowledgementAddAFamilyMemberContextProvider>
      <BasePersonContextProvider>
        <BasePersonFiltersContextProvider>
          <PersonAcknowledgementInfoContextProvider>
            <PersonAcknowledgementInfoPageViewContextProvider>
              <RenewalOptionContextProvider>
                <LeftNavModalContextProvider>
                  <TempPersonContextProvider>
                    <OrgUnitContextProvider>
                      <OmrContextProvider>
                        <ContactRelationshipTypesContextProvider>
                          <PhoneTypeContextProvider>
                            <AddressTypeContextProvider>
                              <RouteCodeValueContextProvider>
                                <ExistingMemberSearchFilterContextProvider>
                                  <OfferingTypesContextProvider>
                                    <HispanicOrLatinoTypeContextProvider>
                                      <EthnicityTypeContextProvider>
                                        <CompetitonGenderTypeContextProvider>
                                          <GenderIdentityTypeContextProvider>
                                            {/* <DisabilityTypeContextProvider> */}
                                            <MemberRegistrationHistoryContextProvider>
                                              <PersonGeneralContextProvider>
                                                <AddressBookContextProvider>
                                                  <LandingPageContextProvider>
                                                    <PersonParentChildContextProvider>
                                                      <ChildrenContextProvider>
                                                        <PersonContextProvider>
                                                          <RegisteredMembersContextProvider>
                                                            {children}
                                                          </RegisteredMembersContextProvider>
                                                        </PersonContextProvider>
                                                      </ChildrenContextProvider>
                                                    </PersonParentChildContextProvider>
                                                  </LandingPageContextProvider>
                                                </AddressBookContextProvider>
                                              </PersonGeneralContextProvider>
                                            </MemberRegistrationHistoryContextProvider>
                                            {/* </DisabilityTypeContextProvider> */}
                                          </GenderIdentityTypeContextProvider>
                                        </CompetitonGenderTypeContextProvider>
                                      </EthnicityTypeContextProvider>
                                    </HispanicOrLatinoTypeContextProvider>
                                  </OfferingTypesContextProvider>
                                </ExistingMemberSearchFilterContextProvider>
                              </RouteCodeValueContextProvider>
                            </AddressTypeContextProvider>
                          </PhoneTypeContextProvider>
                        </ContactRelationshipTypesContextProvider>
                      </OmrContextProvider>
                    </OrgUnitContextProvider>
                  </TempPersonContextProvider>
                </LeftNavModalContextProvider>
              </RenewalOptionContextProvider>
            </PersonAcknowledgementInfoPageViewContextProvider>
          </PersonAcknowledgementInfoContextProvider>
        </BasePersonFiltersContextProvider>
      </BasePersonContextProvider>
    </PersonAcknowledgementAddAFamilyMemberContextProvider>
  </OmrBackButtonContextProvider>
);

export default AppStateContextProviders;