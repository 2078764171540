import { useEffect } from 'react';

import useOmrData from '../../state/omr/UseOmrData';
import useTempPersonData from '../../state/tempPersonData/UseTempPersonData';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

const useExistingMemberSearchSelf = () => {
  const { securityState } = useSecurityData();
  const { personState, savePersonState } = useOmrData();
  const { omrTempPersonState } = useTempPersonData();

  useEffect(() => {
    //TODO: will firstName, lastName, and birthDate be available in userInfo?
    //TODO: Update method to set info after security and user info settting solidified
    if (securityState?.userInfo?.isLoggedIn) {
      // if (securityState?.userInfo?.firstName) {
      if (!personState.searchFilters?.firstName
        && !personState.searchFilters?.lastName) {
        const newPerson = {
          ...personState,
          searchFilters: {
            ...personState.searchFilters,
            firstName: omrTempPersonState?.objData?.firstName || '',
            lastName: omrTempPersonState?.objData?.lastName || '',
            birthDate: formatDate(omrTempPersonState?.objData?.birthDate) || Constants.BLANK_DATE_STRING,
            preferredName: omrTempPersonState?.objData?.preferredName || '',
            middleName: omrTempPersonState?.objData?.middleName || ''
          }
        };

        savePersonState(newPerson, false);
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  return {};
};

export default useExistingMemberSearchSelf;