import { useEffect, useState } from 'react';
import useOmrData from '../../state/omr/UseOmrData';
import ToIntIfInt from '../../../common/utils/ToIntIfInt';
import NavLinks from '../../NavLinks';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';
import usePersonAcknowledgementData from '../../state/personAcknowledgement/UsePersonAcknowledgementData';

const useAcknowledgements = () => {
  const navigate = useNavigate();
  const { personState, savePersonState } = useOmrData();
  const { personAcknowledgementState, getPersonAcknowledgements } = usePersonAcknowledgementData();
  const [ state1, setState ] = useState({ message: '', signatureErrorMessage: '', showModal: false });

  const onItemClick = (id, value) => {
    let changed = false;
    const newArray = personState.person.personAcknowledgement.map(x => {
      const isAcknowledged = x.acknowledgementFormId === ToIntIfInt(id) ? value : x.isAcknowledged;
      changed = changed === true || isAcknowledged !== x.isAcknowledged;
      
      return {
        ...x,
        isAcknowledged: x.acknowledgementFormId === ToIntIfInt(id) ? value : x.isAcknowledged
      };
    });

    if (changed === true) {
      const newPersonState = JSON.parse(JSON.stringify(personState));
      newPersonState.person.personAcknowledgement = JSON.parse(JSON.stringify(newArray));
      savePersonState(newPersonState, false);
    }
  };
  
  const onNextButtonClicked = (e) => {
    e?.preventDefault();
    document.getElementById('omrHeadingId')?.scrollIntoView();
    if (!personState.person.personAcknowledgement) {
      setState({...state1, message: 'not loaded'});
    } else if (personState.person.personAcknowledgement.length === 0) {
      navigate(NavLinks.MEMBER_SUMMARY);
    } else {
      const found = personState.person.personAcknowledgement.find(x => x.isAcknowledged !== true);
      if (found) {
        setState({...state1, message: 'You must acknowledge all of the items in order to continue.'});
      } else {
        if ((personState.person.esignature?.length || 0) > 3) {
          navigate(NavLinks.MEMBER_SUMMARY);
        } else {
          setState({...state1, message: '', showModal: true});
        }
      }
    }
  };

  const onSignatureChanged = (value) => {
    const newPersonState = JSON.parse(JSON.stringify(personState));
    newPersonState.person.esignature = value;
    savePersonState(newPersonState, false);
  };

  const onSigned = (e) => {
    e?.preventDefault();
    if ((personState.person.esignature?.length || 0) > 3) {
      navigate(NavLinks.MEMBER_SUMMARY);
    } else {
      setState({ ...state1, signatureErrorMessage: 'Please type your full name to sign.' });
    }
  };

  const onModalCanceled = (e) => {
    e?.preventDefault();
    
    setState({...state1, showModal: false});
  };

  useEffect(() => {
    if (personState?.person) {
      if (!personState.person.personAcknowledgement) {
        if (personAcknowledgementState.isArrayLoaded !== true) {
          const memberId = personState?.person?.memberId || 'new';
          getPersonAcknowledgements(memberId);
        } else if (personAcknowledgementState.isArrayLoaded === true) {
          const arrayData = (personAcknowledgementState.arrayData || [])
            .map(x => { return {...x, isAcknowledged: false}; });

          const newPersonState = JSON.parse(JSON.stringify(personState));
          newPersonState.person.personAcknowledgement = JSON.parse(JSON.stringify(arrayData));
          savePersonState(newPersonState, false);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState, personAcknowledgementState]);

  return {
    isLoading: personState.person.personAcknowledgement ? false : true,
    arrayData: personState.person?.personAcknowledgement || [],
    message: state1.message,
    showModal: state1.showModal,
    signatureErrorMessage: state1.signatureErrorMessage,
    signature: personState.person.esignature,
    onNextButtonClicked,
    onItemClick,
    onModalCanceled,
    onSignatureChanged,
    onSigned
  };
};

export default useAcknowledgements;
