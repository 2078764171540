import { useEffect, useState } from "react";
import { useNavigate } from "../../wrappers/ReactRouterDom";

import usePersonData from "../../../app/state/landingPages/person/UsePersonData";
import useMemberRegistrationRenewalData from "../../state/memberRegistrationRenewal/UseMemberRegistrationRenewalData";
import useNavRoutes from "../../state/security/UseNavRoutes";
import useGlobalRoutesData from "../../state/security/UseGlobalRoutesData";
import useRenewalOptionData from "../../state/renewalOption/UseRenewalOptionData";

import { navigateToCrossUiRoute } from "../../utils/FormatCrossUiRoute";
import Constants from "../../utils/Constants";

import memberTypeSelectionValidate from './RenewalMemberTypeFormValidation';

const INITIAL_VIEW_STATE = {
  showHelperText: false
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Select Memberships to Renew'
};

const INITIAL_SUCCESS_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Renewal Memberships Added to Shopping Cart'
};

const INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Change Renewal Member Type',
  membership: {},
  personId: Constants.DEFAULT_ID,
  offeringInstanceId: Constants.DEFAULT_ID,
  orgUnitId: Constants.DEFAULT_ID
};

const INITIAL_MEMBER_TYPE_SELECTION_MODAL_FORM_STATE = {
  renewalOfferingInstanceId: Constants.DEFAULT_ID,
  renewalOfferingName: '',
};

const INITIAL_RENEW_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Are You Sure You Would Like to Provide Missing Info & Renew the Selected Member?',
  membership: {},
};

const useRenewMemberships = (omrView, routeCodeValueState) => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { personState } = usePersonData();
  const { memberRegistrationRenewalState, getMemberRegistrationRenewalOptions, postMemberRegistrationRenewal } = useMemberRegistrationRenewalData();
  const { renewalOptionState } = useRenewalOptionData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [successModalState, setSuccessModalState] = useState(INITIAL_SUCCESS_MODAL_STATE);
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const [memberTypeSelectionModalState, setMemberTypeSelectionModalState] = useState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE);
  const [memberTypeSelectionModalFormState, setMemberTypeSelectionModalFormState] = useState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_FORM_STATE);
  const [memberTypeSelectionModalErrorState, setMemberTypeSelectionModalErrorState] = useState({});
  const [renewModalState, setRenewModalState] = useState(INITIAL_RENEW_CONFIRMATION_MODAL_STATE);
  const [errors, setErrorState] = useState({});
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onHelperTextToggle = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showHelperText: !state.showHelperText });
  };

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true
    });

    getMemberRegistrationRenewalOptions(0, routeCodeValueState?.orgUnitId, routeCodeValueState?.offeringInstanceCouponId, null);
  };

  const onRenewClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (selectionChangesState.arrayData?.filter(x => x.isSelected === true)?.length > 0) {
      setModalState(INITIAL_MODAL_STATE);
      setErrorState({});
      postMemberRegistrationRenewal(0, selectionChangesState.arrayData?.filter(x => x.isSelected === true));
      setSuccessModalState({ ...successModalState, displayPopUp: true });
    }
    else {
      setErrorState({ general: 'Please select at least one membership to renew' });
    }
  };

  const onContinueClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setSuccessModalState(INITIAL_SUCCESS_MODAL_STATE);
    setSelectionChangesState({ arrayData: [] });
    setErrorState({});

    if (omrView === false) {
      navigate(navRoutes.PAYMENTS_SHOPPING_CART?.route);
    }
    else if (omrView === true) {
      const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

      if (paymentsRoute && paymentsRoute.route) {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
      }
    }
  };

  const onShowRenewalConfirmationModalClicked = (e, membership) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setRenewModalState({ ...renewModalState, displayPopUp: true, membership: membership });
  };

  const onRenewConfirmationClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    onGoToOmrWithPersonId(e, renewModalState.membership);
    setRenewModalState(INITIAL_RENEW_CONFIRMATION_MODAL_STATE);
    setErrorState({});
  };

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
    setSelectionChangesState({ arrayData: [] });
    setErrorState({});
  };

  const onSuccessModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setSuccessModalState(INITIAL_SUCCESS_MODAL_STATE);
    setSelectionChangesState({ arrayData: [] });
    setErrorState({});
    if (omrView === false) {
      //clear out old person object to get updated membershipRenewalAvailable field and show cart icon
      window?.location?.reload();
    }
    else if (omrView === true) {
      getMemberRegistrationRenewalOptions(0, routeCodeValueState?.orgUnitId, routeCodeValueState?.offeringInstanceCouponId, null);
    }
  };

  const onRenewConfirmationModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setRenewModalState(INITIAL_RENEW_CONFIRMATION_MODAL_STATE);
    setErrorState({});
  };


  const onCheckboxChange = (e, personId, offeringInstanceId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId && x.offeringInstanceId === offeringInstanceId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId && x.offeringInstanceId === offeringInstanceId);

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isSelected = !updatedArrayData[selectedAthleteIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].clubBulkRenewalOptInt === false && updatedGridData[i].hasPreviousRegistration === true && updatedGridData[i].isClubActive === true && updatedGridData[i].renewalOfferingInstanceId !== null) {
        updatedGridData[i].isSelected = true;
        const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId && x.offeringInstanceId === updatedGridData[i].offeringInstanceId);
        //Edit
        if (selectedMembershipIndexArrayData > -1) {
          updatedArrayData[selectedMembershipIndexArrayData].isSelected = true;
        }
        else {
          updatedArrayData.push(updatedGridData[i]);
        }
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].clubBulkRenewalOptInt === false && updatedGridData[i].hasPreviousRegistration === true && updatedGridData[i].isClubActive === true && updatedGridData[i].renewalOfferingInstanceId !== null) {
        updatedGridData[i].isSelected = false;
        const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId && x.offeringInstanceId === updatedGridData[i].offeringInstanceId);
        //Edit
        if (selectedMembershipIndexArrayData > -1) {
          updatedArrayData[selectedMembershipIndexArrayData].isSelected = false;
        }
        else {
          updatedArrayData.push(updatedGridData[i]);
        }
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onChangeMemberTypeClicked = (e, membership) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setMemberTypeSelectionModalState({
      ...memberTypeSelectionModalState, displayPopUp: true,
      membership: membership,
      personId: membership.personId || Constants.DEFAULT_ID,
      offeringInstanceId: membership.offeringInstanceId || Constants.DEFAULT_ID,
      orgUnitId: membership.renewalOrgUnitId || Constants.DEFAULT_ID
    });
  };

  const onMemberTypeSelectionContinueClicked = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const memberTypeSelectionErrors = await memberTypeSelectionValidate(memberTypeSelectionModalFormState) || {};
    if (Object.keys(memberTypeSelectionErrors).length === 0) {

      const renewalOfferingInstanceIdSelectedIndex = renewalOptionState.arrayData?.findIndex(x => x.offeringInstanceId === memberTypeSelectionModalFormState.renewalOfferingInstanceId);

      //save selected membership to memberRegistrationRenewalState
      const memberRegistrationRenewalStateSelectedIndex = memberRegistrationRenewalState.arrayData?.findIndex(x => x.personId === memberTypeSelectionModalState.personId
        && x.offeringInstanceId === memberTypeSelectionModalState.offeringInstanceId && x.renewalOrgUnitId === memberTypeSelectionModalState.orgUnitId);

      memberRegistrationRenewalState.arrayData[memberRegistrationRenewalStateSelectedIndex] = {
        ...memberRegistrationRenewalState.arrayData[memberRegistrationRenewalStateSelectedIndex],
        renewalExpirationDate: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.expirationDate,
        renewalOfferingDescription: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringDescription,
        renewalOfferingId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringId,
        renewalOfferingInstanceId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceId,
        renewalOfferingInstanceOrgUnitId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceOrgUnitId,
        renewalOfferingName: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringName,
        renewalOfferingInstanceCouponId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceCouponId
      }

      //update grid data
      const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
      const gridDataSelectedIndex = updatedGridData?.findIndex(x => x.personId === memberTypeSelectionModalState.personId
        && x.offeringInstanceId === memberTypeSelectionModalState.offeringInstanceId && x.renewalOrgUnitId === memberTypeSelectionModalState.orgUnitId);

      updatedGridData[gridDataSelectedIndex] = {
        ...updatedGridData[gridDataSelectedIndex],
        renewalExpirationDate: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.expirationDate,
        renewalOfferingDescription: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringDescription,
        renewalOfferingId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringId,
        renewalOfferingInstanceId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceId,
        renewalOfferingInstanceOrgUnitId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceOrgUnitId,
        renewalOfferingName: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringName,
        renewalOfferingInstanceCouponId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceCouponId
      }

      setGridState({ ...gridState, gridData: updatedGridData });

      //update selection changes state
      const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
      const arrayDataSelectedIndex = updatedArrayData?.findIndex(x => x.personId === memberTypeSelectionModalState.personId
        && x.offeringInstanceId === memberTypeSelectionModalState.offeringInstanceId && x.renewalOrgUnitId === memberTypeSelectionModalState.orgUnitId);

      if (arrayDataSelectedIndex > -1) {
        updatedArrayData[arrayDataSelectedIndex] = {
          ...updatedArrayData[arrayDataSelectedIndex],
          renewalExpirationDate: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.expirationDate,
          renewalOfferingDescription: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringDescription,
          renewalOfferingId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringId,
          renewalOfferingInstanceId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceId,
          renewalOfferingInstanceOrgUnitId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceOrgUnitId,
          renewalOfferingName: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringName,
          renewalOfferingInstanceCouponId: renewalOptionState.arrayData[renewalOfferingInstanceIdSelectedIndex]?.offeringInstanceCouponId
        }
        setSelectionChangesState({ ...selectionChangesState, arrayData: updatedArrayData });
      }

      //close dropdown
      onMemberTypeSelectionModalCanceled();
    }
    else {
      setMemberTypeSelectionModalErrorState(memberTypeSelectionErrors);
    }
  };

  const onMemberTypeSelectionModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setMemberTypeSelectionModalState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_STATE);
    setMemberTypeSelectionModalFormState(INITIAL_MEMBER_TYPE_SELECTION_MODAL_FORM_STATE);
    setMemberTypeSelectionModalErrorState({});
  };

  const onGoToOmrWithPersonId = (e, membership) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  
    navigate(navRoutes.ENTRY?.route, { state: { membership } });
  };

  useEffect(() => {
    if (omrView === true) {
      getMemberRegistrationRenewalOptions(0, routeCodeValueState?.orgUnitId, routeCodeValueState?.offeringInstanceCouponId, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (memberRegistrationRenewalState.isArrayLoaded === true && memberRegistrationRenewalState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(memberRegistrationRenewalState.arrayData));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId && x.offeringInstanceId === selectionChangesState.arrayData[i].offeringInstanceId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRegistrationRenewalState.isArrayLoaded, memberRegistrationRenewalState.isArrayLoading])

  return {
    personState,
    onClick,
    modalState,
    onModalCanceled,
    memberRegistrationRenewalState,
    gridState,
    onCheckboxChange,
    onSelectAll,
    onUnselectAll,
    onRenewClicked,
    successModalState,
    onSuccessModalCanceled,
    selectionChangesState,
    onContinueClicked,
    errors,
    onChangeMemberTypeClicked,
    memberTypeSelectionModalState,
    setMemberTypeSelectionModalFormState,
    memberTypeSelectionModalFormState,
    memberTypeSelectionModalErrorState,
    onMemberTypeSelectionContinueClicked,
    onMemberTypeSelectionModalCanceled,
    renewalOptionState,
    onShowRenewalConfirmationModalClicked,
    onGoToOmrWithPersonId,
    onHelperTextToggle,
    state,
    renewModalState,
    onRenewConfirmationClicked,
    onRenewConfirmationModalCanceled,
  };
};

export default useRenewMemberships;