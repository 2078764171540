import { DEFAULT_ID } from '../../../common/utils/Constants';

export const localValidate = (formState) => {
  const errors = {};

  // Gender Identity
  if (formState.genderIdentityTypeId === DEFAULT_ID) {
    errors.genderIdentityTypeId = 'Gender Identity is required';
  }

  return errors;
};

const GenderNonAthValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};
export default GenderNonAthValidation;