import React, { Fragment } from 'react';

import RenewMembershipsSuccessLargeGrid from './RenewMembershipsSuccessLargeGrid';
import RenewMembershipsSuccessSmallGrid from './RenewMembershipsSuccessSmallGrid';

const RenewMembershipsSuccessGrid = ({ gridData, isLoading }) => {
  return (
    <Fragment>
      <RenewMembershipsSuccessLargeGrid
        gridData={gridData}
        isLoading={isLoading}
      />
      <RenewMembershipsSuccessSmallGrid
        gridData={gridData}
        isLoading={isLoading}
      />
    </Fragment>
  )
};

export default RenewMembershipsSuccessGrid;